export const commonConfig = {

    routerName: {
      login: '/login',
    },
    httpCodes: {
      unauthorize: 401,
      badReqestError: 400,
      notFound: 404,
      forbidden: 403,
      badRequestError: 400,
      ok: 200,
      internalServerError: 500,
      status: 'ok',
      code: 422,
      badGateway: 502
    },
    apiName: {
      login: '/sign_in',
      logout: '/sign_out'
    },
    baseUrl: {
      user: 'users'
    },
    appMessages: {
      registerSuccess: 'Your registration was successful',
      resetInteractionSuccess: 'Email interaction successfully reset',
      loginSuccess: 'You are successfully logged in',
      logoutSuccess: 'Signout successful',
      loginFailed: 'Incorrect email or password',
      sessionExpire: "User session has expired. Please login again",
      error:'Internal Server Error',
      addImage: 'Image has been added successfully',
      removeImage: 'Image has been removed successfully',
      errImageUpload: 'Image has not been uploaded successfully',
      removeSliderImage: 'Image has been remove successfully',
      paytestSuccess: 'Successful payment test',
      paymentSuccess: 'Your payment was successful',
      RegisterSuccess: 'Your requested module has been successfully set up. Please refresh the page if you are not seeing the module',
      contactSuccess: 'Thank You! Your message has been sent',
      updateProfile: 'Your profile has been successfully updated', 
      profileError: 'There was an error updating your profile. Please try again.'
    },
    setting :{
      searchPage : 1
    }
  };
