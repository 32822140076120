<template>
  <div>
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title">
            <img src="../assets/images/icon-yellownote.svg" alt="" />Note
          </p>
          <p v-html="notes">{{ notes }}</p>
        </div>
      </div>
    </div>
    <p class="learn-info" v-html="card_data.description"></p>

    <div class="learn-object">
      <div class="learn-object-forward">
        <prism-editor
          class="my-editor height-200"
          v-model="code"
          :highlight="highlighter"
          line-numbers
          :tabSize="4"
          @input="autosaveCode()"
        ></prism-editor>
      </div>
    </div>
    <div class="learn-object" v-if="outputWindow">
      <div v-if="runtimeErr">
        <h5>Error</h5>
        <div class="mt-3 alert alert-danger">
          <code>{{ output }}</code> found on line number {{lineNumErr}}
        </div>
      </div>

      <div v-else class="learn-object-forward" v-html="output"></div>
    </div>
    <!-- Run / Submit buttons -->
    <div class="learn-btn float-left coding-buttons">
      <button
        class="btn btn-ametros mt-3"
        @click="showIterationFeedback()"
        v-if="completed"
        aria-label="See Feedback"
      >
        See Feedback
      </button>
      <button
        class="btn btn-ametros mt-3"
        @click="showIterationFeedback()"
        v-if="!completed && !evaluationInProgress && lastIterationId"
        aria-autocomplete="See Interim Feedback"
      >
        See Interim Feedback
      </button>
      <button
        @click="resetCodingInteraction()"
        class="btn btn-ametros mt-3"
        v-if="user_roles.includes('testing-internal')"
      >
        Reset Interaction
      </button>
    </div>
    <div class="learn-btn mt-3" v-if="!completed && !evaluationInProgress">
      <button 
        type="button" 
        class="btn secondary code-btn" 
        @click="runCode"
        aria-label="Run Code"
      >
        <em class="play-icon"></em>
        Run Code
      </button>
      <button
        type="button"
        class="btn btn-ametros"
        v-if="!lastIterationId"
        @click="$modal.show('submitShow')"
        :disabled="evaluationInProgress"
        aria-label="Submit"
      >
        Submit
      </button>
      <button
        type="button"
        class="btn small primary"
        v-else
        @click="$modal.show('submitShow')"
        :disabled="evaluationInProgress"
        aria-label="Re-submit"
      >
        Re-submit
      </button>
    </div>

    <div class="learn-object yellow-note" v-if="evaluationInProgress">
      <span
        >Please wait while we are evaluating your code. You can see the
        evaluation progress by clicking the button below. Thanks for your
        patience!</span
      >
      <button
        type="button"
        class="btn small secondary mt-3"
        @click="$modal.show('awaitCodeResults')"
        aria-label="See Evaluation Progress here"
      >
        See Evaluation Progress here
      </button>
    </div>

    <div class="introduction" v-if="completed">
      <lo-buttons
        :nextStatus="nextStatus"
        text="See Final Feedback"
        :nextMethod="next"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
    </div>
    <modal
      class="modal-delete"
      name="submitShow"
      height="auto"
      :width="350"
      :scrollable="true"
    >
      <div class="modal-content modal-delete-content">
        <div class="modal-body modal-delete-body">
          Are you sure you want to submit the code?
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            @click="$modal.hide('submitShow')"
            class="btn medium default"
            aria-label="No"
          >
            No
          </button>
          <button
            type="button"
            @click="handleFormSubmit"
            class="btn medium primary"
            aria-label="Yes"
          >
            Yes
          </button>
        </div>
      </div>
    </modal>
    <modal
      name="codingIterationFeedback"
      :adaptive="true"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
    >
      <div
        class="
          modal-content
          modal-feedback-content
          modal-dialogic-content
          modal-score-content
        "
      >
        <button
          type="button"
          class="modal-close"
          @click="$modal.hide('codingIterationFeedback')"
          aria-label="Close Modal"
        >
          <em class="icon-cross"></em>
        </button>
        <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
          <div class="modal-body">
            <div class="modal-title">
              <h1>Feedback</h1>
              <div
                class="modal-score-strip mb-4"
                v-if="
                  overall_assmnt_item && overall_assmnt_item.display_feedback
                "
              >
                <span class="modal-score-icon" v-if="!hideAssessmentLabel">
                  <img
                    v-if="overall_assmnt_item.assessment_icon_url"
                    :src="overall_assmnt_item.assessment_icon_url"
                    :alt="overall_assmnt_item.assessment_label"
                  />
                  {{ overall_assmnt_item.assessment_label }}
                </span>
                <p v-if="!completed">
                  You have {{ iterations_available }} more retries available to
                  improve your code
                </p>
                <p v-else v-html="overall_assmnt_item.feedback"></p>
              </div>
               <div class="mt-4">
                <h6>Number of tests: {{ totalTests }}</h6>
                <h6>Tests Passed: {{ testsPassed }}</h6>
              </div>
            </div>
          <!-- carousel container repeat -->
            <div v-if="concept_tests">
              <div
                class="dialogic-container"
                v-for="(conceptTests ,key) in concept_tests"
                :key="key"
              >
                <div class="dialogic-heading">{{conceptNames[key] | capitalize}}</div>
                  <div v-if="conceptTests['tests_failed'].length > 0"  class="feedback-items missed-items">
                    <div class="feedback-content">
                      <div class="feedback-heads">
                        <div class="feedback-icon"></div>
                        <div class="feedback-infos">
                          <div class="feedback-head">Tests Failed</div>
                            <div v-for="conceptTest in conceptTests['tests_failed']" :key="conceptTest.id" class="test">
                              <p class="test-name">{{conceptTest.attributes.coding_test_name | capitalize}}</p>
                              <p class="test-explanation">{{conceptTest.attributes.coding_test_explanation | capitalize}}</p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div v-if="conceptTests['tests_passed'].length > 0" class="feedback-items mastery-items">
                    <div class="feedback-content">
                      <div class="feedback-heads">
                        <div class="pass-icon"></div>
                        <div class="feedback-infos">
                          <div class="feedback-head">Tests Passed</div>
                          <div v-for="conceptTest in conceptTests['tests_passed']" :key="conceptTest.id" class="test">
                            <p class="test-name">{{conceptTest.attributes.coding_test_name | capitalize}}</p>
                            <p class="test-explanation">{{conceptTest.attributes.coding_test_explanation | capitalize}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </modal>
    <modal name="awaitCodeResults" :width="400" height="auto">
      <div class="eval-note">
        Evaluated Test {{ testsEvaluated }} of {{ totalTests }} ...
      </div>
      <k-progress
        :percent="codingEvalProgress"
        color="#3dbc9e"
        :line-height="12"
      ></k-progress>
    </modal>
  </div>
</template>
<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles
import Prism from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-python";
import "prismjs/themes/prism-solarizedlight.css";
import { utilFunctionService } from "@/utils/utils.service";
import _ from "lodash";
import api from "../services/api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import loButtons from "../components/loButtons.vue";

export default {
  name: "coding-component",
  props: ["active_data", "nextStatus"],
  components: { 
    VuePerfectScrollbar, 
    PrismEditor,
    loButtons
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      loId: "",
      card_data: {},
      sticky_note: [],
      learning_id: "",
      evaluation_id: "",
      code: "",
      completed: false,
      output: "",
      outputWindow: false,
      runtimeErr: false,
      syntaxErr: false,
      lineNumErr: "",
      lastIterationId: "",
      concept_tests: {},
      testsPassed: "",
      conceptNames: {},
      hideAssessmentLabel: false,
      overall_assmnt_item: {},
      iterations_available: "",
      user_roles: utilFunctionService.getUserRoles(),
      codingEvalProgress: 0,
      testsEvaluated: 0,
      totalTests: 0,
      evaluationInProgress: false,
    };
  },
  channels: {
    CodingEvaluationNotificationChannel: {
      received(data) {
        if (data.invoked_user_id == utilFunctionService.currentUserId()) {
          this.codingEvalProgress = data.progress;
          this.testsEvaluated = data.evaluated_count;
          if (data.done && data.progress === 100) {
            this.testsEvaluated = 0;
            this.codingEvalProgress = 0;
            this.evaluationInProgress = false;
            this.hide("awaitCodeResults");
            this.$cable.unsubscribe("CodingEvaluationNotificationChannel");
            this.learnerCode().then(() => {
              this.showIterationFeedback();
            });
          }
        }
      },
    },
  },
  created() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.loId = this.active_data.attributes.learning_object.id;
    let attr = this.active_data.attributes;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    let sectLo = attr.user_section.attributes.learning_object_sections;
    this.learning_id = attr.learning_object_id;
    this.evaluation_id = this.active_data.attributes.evaluation_id;
    this.getConcepts();
    this.learnerCode();
    this.totalTests = this.card_data.card_detail.attributes.total_tests;
    for (let i = 0; i < sectLo.length; i++) {
      if (sectLo[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(sectLo[i].attributes.sticky_note);
      }
    }
  },
  methods: {
    next() {
      this.$parent.next();
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    loadResults() {
      this.$cable.subscribe({
        channel: "CodingEvaluationNotificationChannel",
        room: "public",
      });
      this.evaluationInProgress = true;
      this.show("awaitCodeResults");
    },
    getConcepts() {
      api.getConcepts(this.evaluation_id).then((res) => {
        if (res.data) {
          res.data.concepts.forEach((ele) => {
            this.conceptNames[ele.id] = ele.attributes.name;
          });
        }
      });
    },
    learnerCode() {
      return api
        .getLearnerCode(this.evaluation_id)
        .then((res) => {
          let evalAttr = res.data.data.attributes;
          if (evalAttr.last_updated_code != null) {
            this.code = evalAttr.last_updated_code;
            this.updateData(evalAttr);
          } else {
            this.code = this.card_data.card_detail.attributes.provided_code;
          }
        })
        .catch();
    },
    updateData(evalAttr) {
      this.completed = evalAttr.complete;
      this.iterations_available = evalAttr.iterations_available;
      if (evalAttr.last_completed_iteration) {
        this.lastIterationId = evalAttr.last_completed_iteration.id;
      }
      if (this.lastIterationId) {
        this.overall_assmnt_item =
          evalAttr &&
          evalAttr.last_completed_iteration.attributes.overall_assmnt_item
            ? evalAttr.last_completed_iteration.attributes.overall_assmnt_item
                .attributes
            : "";
      }
    },
    autosaveCode: _.debounce(function () {
      this.saveDraft();
    }, 2000),
    saveDraft() {
      const fb = new FormData();
      fb.append("user_coding_evaluation_id", this.evaluation_id);
      fb.append("user_coding_iteration[submitted_code]", this.code);
      fb.append("user_coding_iteration[is_draft]", true);
      if (this.code) {
        api.submitCode(fb);
      }
    },
    highlighter(code) {
      return Prism.highlight(code, Prism.languages.python, "py");
    },
    runCode() {
      utilFunctionService.showLoader();
      this.clearErrors();
      const _fd = new FormData();
      _fd.append("user_coding_iteration[submitted_code]", this.code);
      api
        .executeCode(_fd)
        .then((res) => {
          utilFunctionService.hideLoader();
          this.output = res.data.output.output;
          this.output = this.output.replace(/(?:\r\n|\r|\n)/g, "<br />");
          this.runtimeErr = res.data.output.runtime_error == "true";
          this.lineNumErr = parseInt(res.data.output.line_number) + 1;
          if (this.runtimeErr) {
            let lineHighlight = document.querySelector(`.prism-editor__line-number:nth-child(${this.lineNumErr + 1})`);
            lineHighlight.classList.add('highlight-line');
          }
          this.outputWindow = true;
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    handleFormSubmit() {
      this.submitCode();
      this.$modal.hide("submitShow");
    },
    submitCode() {
      const _fd = new FormData();
      _fd.append("user_coding_evaluation_id", this.evaluation_id);
      _fd.append("user_coding_iteration[submitted_code]", this.code);
      _fd.append("user_coding_iteration[is_draft]", false);
      api.submitCode(_fd).then(() => {
        this.loadResults();
      });
    },
    clearErrors() {
      this.runtimeErr = false;
      if (typeof this.lineNumErr === 'number') {
        let lineHighlight = document.querySelector(`.prism-editor__line-number:nth-child(${this.lineNumErr + 1})`);
        lineHighlight.classList.remove('highlight-line');
      }
      this.lineNumErr = "";
    },
    resetCodingInteraction() {
      api
        .resetCodingInteraction(this.evaluation_id)
        .then(() => {
          this.$router.push({path: `/learning/${this.module_id}/${this.active_data.id}`})
          this.$router.go();
        })
        .catch(() => {});
    },
    showIterationFeedback() {
      utilFunctionService.showLoader();
      api.codingIterationEvaluations(this.lastIterationId).then((res) => {
        this.show("codingIterationFeedback");
        let iterationEval = res.data.data.attributes;
        this.totalTests = iterationEval.total_tests;
        this.testsPassed = iterationEval.tests_passed;
        for (var i in iterationEval.concept_tests) {
          let tests = iterationEval.concept_tests[i];
          this.concept_tests[i] = { tests_passed: [], tests_failed: [] };
          tests.forEach((test) => {
            if (test.attributes.test_passed) {
              this.concept_tests[i]["tests_passed"].push(test);
            } else {
              this.concept_tests[i]["tests_failed"].push(test);
            }
          });
        }
        utilFunctionService.hideLoader();
      });
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
<style lang="scss">
  /* required class */
  .my-editor {
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    background: black;
    color: white;
  }
 
  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
  .eval-note {
    color: white;
  }
  .test {
    margin-bottom: 16px;
  }
  .test-name {
    font-weight: bold;
    margin-bottom: 8px !important;
  }
  .test-explanation {
    margin-left: 8px !important;
  }
  .code-btn {
    margin-right: 16px;
    em {
      margin-right: 16px !important;
    }
  }
  .prism-editor__line-number.highlight-line {
    border-left: 5px solid #f87a61;
    background: #f8d7d0;
    position: relative;
  }
  .prism-editor__line-number.highlight-line:after {
    content: "";
    height: 21px;
    background: #f8d7d047;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    max-width: 920px;
    width: calc(50vw + 58px) !important;
  }
  .prism-editor__line-number.highlight-line:after {
    width: calc(50vw + 64px) !important;
  }
  .prism-editor__line-numbers {
    overflow: initial !important;
  }
  .coding-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
</style> 

