<template>
  <div
    class="learn-steps-component dialogic-component"
    ref="main-steps-container"
  >
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
         <p class="note-title">
            <img src="../assets/images/icon-yellownote.svg" alt="" />Note
          </p>
          <p v-html="notes"></p>
        </div>
      </div>
    </div>
    <p
      class="learn-info"
      v-if="card_data.description"
      v-html="card_data.description"
    ></p>

    <!-- When chat im view is enable -->
    <div
      class="learn-object"
      v-if="card_data.card_detail.attributes.enable_chat_im_view"
      ref="learnObjectArea"
      id="learn-object-ref"
    >
      <div class="learn-object-preview" v-if="submitquestions.length">
        <div
          class="learn-object-info"
          v-for="(que, k) in submitquestions"
          :key="k"
        >
          <div v-if="k === 0">
            <div class="row">
              <div class="col-1">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class="learn-object-user image-cropper">
                      <img
                        class="img-left"
                        :src="character.attributes.char_photo_url.thumbnail"
                        v-if="character.attributes.char_photo_url.thumbnail"
                        v-bind:alt="
                          character.attributes.char_first_name +
                          ' ' +
                          character.attributes.char_last_name +
                          ', ' +
                          character.attributes.world_role
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="container2 lighter">
                  <div
                    class="character-chat-reply"
                    v-if="k === 0"
                    v-html="introductionText"
                  ></div>
                </div>
              </div>
              <div class="col-3"></div>
            </div>
          </div>

          <div class="simple-dialogic-chat" v-if="que.attributes.attempt === 1">
            <div div class="row">
              <div class="col-1">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class=""></div>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="container2 lighter">
                  <div
                    class="character-chat-reply"
                    v-html="que.attributes.question"
                  ></div>
                </div>
              </div>
              <div class="col-3"></div>
            </div>
          </div>

          <div
            class="simple-dialogic-chat learner-answer-container"
            v-if="que.attributes.answer"
          >
            <div class="row">
              <div class="col-3"></div>
              <div class="col-8">
                <div class="container2">
                  <p
                    class="learner-chat-answer"
                    v-html="que.attributes.answer"
                  ></p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="simple-dialogic-chat"
            v-if="que.attributes.character_response"
          >
            <div class="row">
              <div class="col-1">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class="learn-object-user image-cropper">
                      <img
                        class="img-left"
                        :src="character.attributes.char_photo_url.thumbnail"
                        v-if="character.attributes.char_photo_url.thumbnail"
                        v-bind:alt="
                          character.attributes.char_first_name +
                          ' ' +
                          character.attributes.char_last_name +
                          ', ' +
                          character.attributes.world_role
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-8 char-response"
                v-if="que.attributes.character_response"
              >
                <div class="container2 lighter">
                  <div class="character-chat-reply">
                    <code-slot
                      :content="que.attributes.character_response"
                    ></code-slot>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="simple-dialogic-chat"
            v-if="que.attributes.follow_up_question"
          >
            <div class="row">
              <div class="col-1">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class=""></div>
                  </div>
                </div>
              </div>

              <div
                class="col-8 follow-up"
                v-if="que.attributes.follow_up_question"
              >
                <div class="container2 lighter">
                  <div
                    class="character-chat-reply"
                    v-if="que.attributes.follow_up_question"
                    v-html="que.attributes.follow_up_question"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- learn object repeat end -->
      </div>

      <!-- learn object-editor start-->
      <div id="editor-container" ref="editorArea">
        <div
          id="editor-area"
          class="learn-object-editor chat-view-editor"
          v-if="
            questions_datas.length > 0 ||
            (submitquestions[submitquestions.length - 1] &&
              submitquestions[submitquestions.length - 1].attributes
                .follow_up_question)
          "
        >
          <div class="learn-object-info-learning">
            <div class="row" v-if="submitquestions.length === 0">
              <div class="col-1">
                <div class="learn-object-head">
                  <div class="learn-object-col">
                    <div class="learn-object-user image-cropper">
                      <img
                        :src="character.attributes.char_photo_url.thumbnail"
                        v-if="character.attributes.char_photo_url.thumbnail"
                        v-bind:alt="
                          character.attributes.char_first_name +
                          ' ' +
                          character.attributes.char_last_name +
                          ', ' +
                          character.attributes.world_role
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="container2 lighter">
                  <div
                    class="character-chat-reply"
                    v-if="submitquestions.length === 0"
                    v-html="introductionText"
                  ></div>
                </div>
              </div>
            </div>

            <div
              class="row"
              v-if="
                !(
                  submitquestions.length > 0 &&
                  submitquestions[submitquestions.length - 1].attributes
                    .follow_up_question
                )
              "
            >
              <div class="col-1">
                <!-- <div class="learn-object-user image-cropper">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    alt="image"
                  />
                </div> -->
              </div>
              <div class="col-8">
                <div class="container2 lighter">
                  <div
                    class="character-chat-reply"
                    v-if="
                      submitquestions.length > 0 &&
                      submitquestions[submitquestions.length - 1].attributes
                        .follow_up_question
                    "
                  ></div>
                  <div
                    class="character-chat-reply"
                    v-else
                    v-html="questions_datas[0].attributes.question"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="learn-object-form"
            v-if="
              questions_datas.length > 0 ||
              (submitquestions[submitquestions.length - 1] &&
                submitquestions[submitquestions.length - 1].attributes
                  .follow_up_question)
            "
          >
            <vue-editor
              :editorToolbar="simpleToolbar"
              v-model="ans"
              @input="
                Answer();
                autosaveAnswer();
              "
            >
            </vue-editor>
          </div>
        </div>
      </div>

      <!-- Conclusion, when no question remains to answer and answer don't trigger a follow up qstn -->
      <div class="learn-object-info-learning">
        <div class="row" v-if="conversationFinished">
          <div class="col-1">
            <div class="learn-object-head">
              <div class="learn-object-col">
                <div class="learn-object-user image-cropper">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    v-bind:alt="
                      character.attributes.char_first_name +
                      ' ' +
                      character.attributes.char_last_name +
                      ', ' +
                      character.attributes.world_role
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="container2 lighter">
              <h2 v-if="conversationFinished">
                {{ conclusionText }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div id="end-of-dialogic-area"></div>
      <div
        class="learn-object-send"
        v-if="
          questions_datas.length > 0 ||
          (submitquestions[submitquestions.length - 1] &&
            submitquestions[submitquestions.length - 1].attributes &&
            submitquestions[submitquestions.length - 1].attributes
              .follow_up_question)
        "
      >
        <button
          class="btn btn-ametros ml-auto"
          v-if="
            submitquestions.length > 0 &&
            submitquestions[submitquestions.length - 1] &&
            submitquestions[submitquestions.length - 1].attributes &&
            submitquestions[submitquestions.length - 1].attributes
              .follow_up_question
          "
          :disabled="disableNextBtn"
          @click="
            nextQuestion(
              submitquestions[submitquestions.length - 1].attributes
                .dialogic_question_id,
              ans
            )
          "
        >
          Send
        </button>
        <button
          v-else
          class="btn btn-ametros ml-auto"
          :disabled="disableNextBtn"
          @click="
            nextQuestion(
              questions_datas[0].attributes.dialogic_question_id,
              ans
            )
          "
        >
          Send
        </button>
      </div>
    </div>

    <!-- When chat im view is not enabled -->
    <div
      class="learn-object"
      v-if="!card_data.card_detail.attributes.enable_chat_im_view"
      ref="learnObjectArea"
      id="learn-object-ref"
    >
      <div class="learn-object-head">
        <div class="learn-object-col">
          <div class="learn-object-user">
            <img
              :src="character.attributes.char_photo_url.thumbnail"
              v-if="character.attributes.char_photo_url.thumbnail"
              v-bind:alt="
                character.attributes.char_first_name +
                ' ' +
                character.attributes.char_last_name +
                ', ' +
                character.attributes.world_role
              "
            />
          </div>
          <div class="learn-object-titles">
            <h2>{{ character.attributes.char_full_name }}</h2>
            <p>
              {{ character.attributes.world_role
              }}<!--({{character.attributes.org_name}})-->
            </p>
          </div>
        </div>
        <!-- learn object-editor end-->
      </div>
      <div class="learn-object-preview" v-if="submitquestions.length">
        <!-- learn object-editor start-->
        <div
          class="learn-object-info"
          v-for="(que, k) in submitquestions"
          :key="k"
        >
          <p
            v-if="k === 0"
            v-html="card_data.card_detail.attributes.introduction"
          ></p>
          <div v-if="que.attributes.attempt === 1">
            <p v-html="que.attributes.question"></p>
          </div>
          <div class="dialogic-answer">
            <p v-html="que.attributes.answer"></p>
          </div>
          <div v-if="que.attributes.character_response">
            <code-slot :content="que.attributes.character_response"></code-slot>
          </div>
          <p v-if="que.attributes.follow_up_question">
            {{ que.attributes.follow_up_question }}
          </p>
        </div>
        <!-- learn object repeat end -->
      </div>

      <!-- learn object-editor start-->
      <div id="editor-container" ref="editorArea">
        <div
          id="editor-area"
          class="learn-object-editor"
          v-if="
            questions_datas.length > 0 ||
            (submitquestions[submitquestions.length - 1] &&
              submitquestions[submitquestions.length - 1].attributes
                .follow_up_question)
          "
        >
          <div class="learn-object-info-learning">
            <p
              v-if="submitquestions.length === 0"
              v-html="introductionText"
            ></p>
            <div
              v-if="
                submitquestions.length > 0 &&
                submitquestions[submitquestions.length - 1].attributes
                  .follow_up_question
              "
            ></div>
            <p v-else v-html="questions_datas[0].attributes.question"></p>
          </div>
          <div
            class="learn-object-form"
            v-if="
              questions_datas.length > 0 ||
              (submitquestions[submitquestions.length - 1] &&
                submitquestions[submitquestions.length - 1].attributes
                  .follow_up_question)
            "
          >
            <vue-editor
              :editorToolbar="simpleToolbar"
              v-model="ans"
              @input="
                Answer();
                autosaveAnswer();
              "
            >
            </vue-editor>
          </div>
        </div>
      </div>

      <!-- Conclusion, when no question remains to answer and answer doesn't trigger a follow up question -->
      <div class="learn-object-info-learning">
        <p v-if="conversationFinished">
          {{ conclusionText }}
        </p>
      </div>
      <div id="end-of-dialogic-area"></div>
      <div
        class="learn-object-send"
        v-if="
          questions_datas.length > 0 ||
          (submitquestions[submitquestions.length - 1] &&
            submitquestions[submitquestions.length - 1].attributes &&
            submitquestions[submitquestions.length - 1].attributes
              .follow_up_question)
        "
      >
        <button
          class="btn btn-ametros ml-auto"
          aria-label="Send"
          v-if="
            submitquestions.length > 0 &&
            submitquestions[submitquestions.length - 1] &&
            submitquestions[submitquestions.length - 1].attributes &&
            submitquestions[submitquestions.length - 1].attributes
              .follow_up_question
          "
          :disabled="disableNextBtn"
          @click="
            nextQuestion(
              submitquestions[submitquestions.length - 1].attributes
                .dialogic_question_id,
              ans
            )
          "
        >
          Send
        </button>
        <button
          v-else
          class="btn btn-ametros ml-auto"
          aria-label="Send"
          :disabled="disableNextBtn"
          @click="
            nextQuestion(
              questions_datas[0].attributes.dialogic_question_id,
              ans
            )
          "
        >
          Send
        </button>
      </div>
    </div>
    
    <div class="finished-area">
      <div class="learn-btn left-right" v-if="conversationFinished">
        <button
          @click="resetDialogicInteraction()"
          class="btn secondary"
          v-if="user_roles.includes('testing-internal')"
        >
          Reset Interaction
        </button>
        <button
          class="btn secondary"
          @click="dialogicEvaluations()"
          aria-label="See Feedback"
        >
          See Feedback
          <em class="icon-arrow-right"></em>
        </button>
      </div>

      <div class="learn-btn" v-if="conversationFinished">
        <lo-buttons
          :nextMethod="next"
          :nextStatus="nextStatus"
          text="See Final Feedback"
          :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
        ></lo-buttons>
      </div>
    </div>

    <modal
      name="dialogicLearningData"
      :adaptive="true"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div
          class="
            modal-content
            modal-feedback-content
            modal-dialogic-content
            modal-score-content
          "
        >
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('dialogicLearningData')"
          >
            <em class="icon-cross"></em>
          </button>
          <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
            <div class="modal-body">
              <div class="modal-title modal-flex-title">
                <h1>{{ card_data.name }} Feedback</h1>
              </div>
              
              <div
                class="modal-score-strip mb-4"
                v-if="
                  overall_assmnt_item &&
                  overall_assmnt_item.display_feedback &&
                  card_data.card_detail.attributes.display_overall_assessment
                "
              >
                <span class="modal-score-icon" v-if="!hideAssessmentLabel">
                  <img
                    v-if="overall_assmnt_item.assessment_icon_url"
                    :src="overall_assmnt_item.assessment_icon_url"
                    :alt="overall_assmnt_item.assessment_label"
                  />
                  {{ overall_assmnt_item.assessment_label }}
                </span>
                <p v-html="overall_assmnt_item.feedback"></p>
              </div>
              <!-- carousel container repeat -->
              <div v-if="dialogic_debrief_evaluation">
                <div
                  class="dialogic-container"
                  v-for="(dialogicdata, key) in dialogic_debrief_evaluation"
                  :key="key"
                >
                  <div v-if="dialogic_debrief_evaluation[key].length > 0">
                    <div class="dialogic-heading">
                      {{
                        dialogic_debrief_evaluation[key][0].attributes &&
                        dialogic_debrief_evaluation[key][0].attributes
                          .question_concept
                          ? dialogic_debrief_evaluation[key][0].attributes
                              .question_concept
                          : ""
                      }}
                      <button
                        v-if="
                          dialogic_debrief_evaluation[key][0].attributes &&
                          dialogic_debrief_evaluation[key][0].attributes
                            .concept_info
                        "
                        :content="
                          dialogic_debrief_evaluation[key][0].attributes
                            .concept_info
                        "
                        v-tippy="{ trigger: 'click', arrow: true }"
                        class="display-inline feedback-info no-style-btn"
                      >
                        i
                        <span class="visually-hidden">Show concept info</span>
                      </button>
                    </div>
                    <!-- feedback items start -->
                    <div
                      v-for="dialogic in dialogic_debrief_evaluation[key]"
                      :key="dialogic.id"
                    >
                      <!-- feedback items end -->
                      <!-- feedback items start -->
                      <div
                        class="feedback-items missed-items"
                        v-if="dialogic.attributes.key_topic_missed"
                      >
                        <div
                          class="feedback-icon"
                          v-if="!hideAssessmentLabel"
                        ></div>
                        <div class="feedback-content">
                          <div class="feedback-heads">
                            <div class="feedback-infos">
                              <div
                                class="feedback-head"
                                v-if="!hideAssessmentLabel"
                              >
                                Missed
                              </div>
                              <div
                                class="emailBody"
                                v-html="dialogic.attributes.debrief_received"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="feedback-items mastery-items" v-else>
                        <div class="feedback-content">
                          <div class="feedback-heads">
                            <div
                              class="feedback-icon"
                              v-if="!hideAssessmentLabel"
                            >
                              <img
                                :src="dialogic.attributes.assessment_icon_url"
                                :alt="dialogic.attributes.assessment_label"
                              />
                            </div>
                            <div class="feedback-infos">
                              <div
                                v-if="!hideAssessmentLabel"
                                class="feedback-head"
                              >
                                {{ dialogic.attributes.assessment_label }}
                              </div>
                              <div
                                class="emailBody"
                                v-html="dialogic.attributes.debrief_received"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="dialogic.attributes.suggested_contents">
                        <div
                          class="col-12 px-0"
                          v-for="item in dialogic.attributes.suggested_contents"
                          :key="item.id"
                        >
                          <!-- suggestion container slide  -->
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'slide_learn_obj'
                            "
                          >
                            <h5>Suggested Content</h5>
                            <div class="suggestion-row">
                              <div class="suggestion-slide">
                                <a
                                  @click="
                                    $modal.show('adaptiveModal1', {
                                      data: item.attributes.content_details
                                        .attributes.slider_images,
                                      title: item.attributes,
                                      type: 'slide',
                                    })
                                  "
                                >
                                  <img
                                    :src="
                                      item.attributes.content_details.attributes
                                        .slider_images[0].attributes
                                        .resource_url.thumbnail
                                    "
                                    :alt="
                                      item.attributes.content_details.attributes
                                        .slider_images[0].attributes
                                        .global_resource.attributes.tag_list
                                    "
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'text_learn_obj'
                            "
                          >
                            <h5>Suggested Content</h5>
                            <div class="suggestion-row">
                              <div
                                class="suggestion-slide"
                                v-html="
                                  item.attributes.content_details.attributes
                                    .title
                                "
                              ></div>
                            </div>
                          </div>
                          <!-- suggestion container pdf  -->
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'file_learn_obj'
                            "
                          >
                            <h5>Suggested Content(file)</h5>
                            <div class="suggestion-row">
                              <div class="suggestion-pdf">
                                <a
                                  @click="
                                    $modal.show('pdfModal', {
                                      pdfUrl:
                                        item.attributes.content_details
                                          .attributes.resource_url,
                                      label:
                                        item.attributes.content_details.attributes
                                          .resource_file_name
                                    })
                                  "
                                >
                                  <span class="truncate">{{
                                    item.attributes.content_details
                                          .attributes.resource_file_name
                                  }}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <!-- suggestion container video  -->
                          <div
                            class="suggestion-container"
                            v-if="
                              item.attributes.content_details.type ==
                              'video_learn_obj'
                            "
                          >
                            <h5>Suggested Content(video)</h5>
                            <div class="suggestion-row">
                              <div class="suggestion-video">
                                <a
                                  @click="
                                    $modal.show('adaptiveModal1', {
                                      data: item.attributes.content_details
                                        .attributes.global_video.attributes
                                        .wistia_code,
                                      title: item.attributes,
                                      type: 'video',
                                    })
                                  "
                                >
                                  <img
                                    :src="
                                      item.attributes.content_details.attributes
                                        .global_video.attributes
                                        .wistia_thumbnail_url
                                    "
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- feedback items end -->
                    </div>
                    <!-- feedback items end -->
                  </div>
                </div>
              </div>

              <div class="reattempts-wrapper">
                <div class="reattempts-info" v-if="!course_lo_reattempts_disabled && lo_reattempts_enabled && (lo_reattempts_max - lo_reattempt_number) > 0  && user_section_data.complete_percentage < 100">
                  <p>Do you want to try this interaction again? You can reset this interaction <strong>{{ lo_reattempts_max - lo_reattempt_number }}</strong> more time<span v-if="lo_reattempts_max - lo_reattempt_number > 1">s</span>. Click "Try again" now if you'd like to apply the feedback you received or see what happens when you submit a different message.</p>

                  <div class="two-buttons">
                    <button class="btn secondary" @click="retryInteraction()">Try again</button>
  
                    <button class="btn secondary" @click="next">
                      {{ !nextStatus ? "Next" : "Go To Dashboard" }}
                      <em class="icon-arrow-right"></em>
                    </button>
                  </div>

                </div>
                <!--<div class="retries" v-if="!course_lo_reattempts_disabled && lo_reattempts_enabled && (lo_reattempts_max - lo_reattempt_number) === 0">
                  <p>You have used <strong>{{ lo_reattempt_number }}</strong> retry attempts.</p>
                  <p>You have used all of your retry attempts.</p>
                </div>-->
              </div>

              <div class="modal-btn" v-if="(!course_lo_reattempts_disabled && lo_reattempts_enabled && (lo_reattempts_max - lo_reattempt_number == 0)) || !lo_reattempts_enabled || user_section_data.complete_percentage >= 100">
                <div class="learn-btn">
                  <button class="btn secondary" @click="next">
                    {{ !nextStatus ? "Next" : "Go To Dashboard" }}
                    <em class="icon-arrow-right"></em>
                  </button>
                </div>
              </div>

            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
    <modal
      name="adaptiveModal1"
      :width="815"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-gathering-content">
          <div class="modal-body">
            <div class="gather-content-item" v-if="type == 'video'">
              <h3>{{ title }}</h3>
              <p class="mb-4">{{ desc }}</p>
              <div class="wistia_responsive_wrapper">
                <iframe
                  :src="preIframUrl + videoCode + postIframUrl"
                  title="Video.mp4"
                  class="wistia_embed"
                  name="wistia_embed"
                  allowtransparency="true"
                  scrolling="no"
                  allowfullscreen
                  mozallowfullscreen
                  webkitallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>

            <div class="gather-content-item" v-if="type == 'slide'">
              <h3>{{ title }}</h3>
              <p class="mb-2">{{ desc }}</p>
              <carousel
                :margin="0"
                :nav="true"
                :navText="[]"
                :autoplay="false"
                :autoHeight="false"
                :items="1"
                :dots="false"
                :loop="true"
              >
                <div v-for="img in sliderImages" :key="img.id">
                  <div class="introduction-video">
                    <div class="introduction-intro-title">
                      {{ img.attributes.caption }}
                    </div>
                    <img
                      v-if="img.attributes.resource_url.large_version"
                      :src="img.attributes.resource_url.large_version"
                      :alt="img.attributes.global_resource.attributes.tag_list"
                      class="intro-slide-video"
                    />
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </FocusLoop>
    </modal>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import { utilFunctionService } from "@/utils/utils.service";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { VueEditor } from "vue2-editor";
import api from "../services/api";
import codeSlot from "../components/codeSlot";
import loButtons from "../components/loButtons.vue";
import _ from "lodash";

export default {
  name: "dialogic-component",
  components: {
    VueEditor,
    carousel,
    VuePerfectScrollbar,
    codeSlot,
    loButtons
  },
  props: ["active_data", "nextStatus", "module_id"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 30,
      },
      characterImgAlt: "",
      loId: "",
      card_type: "",
      card_data: {},
      url: utilFunctionService.getImageUrl(),
      character: {},
      evaluation_id: "",
      fullToolbar: [],
      simpleToolbar: [["bold", "italic", "underline"]],
      transcript: "",
      questions: [],
      submitquestions: [],
      disableNextBtn: true,
      ans: "",
      overall_assmnt_item: {},
      dialogic_debrief_evaluation: [],
      questions_data: [],
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      questions_datas: [],
      videoCode: "",
      type: "",
      sliderImages: [],
      title: "",
      desc: "",
      user_roles: utilFunctionService.getUserRoles(),
      sticky_note: [],
      learning_id: "",
      hideAssessmentLabel: false,
      conversationFinished: false,
      currentQuestionId: null,
      disabledMissed: false,
      hasReviewedFeedback: false,
      isOpen: false,
      introductionText: "",
      conclusionText: "",
      course_lo_reattempts_disabled: '',
      lo_reattempts_enabled: '',
      lo_reattempts_max: '',
      lo_reattempt_number: '', 
      user_section_data: {}, 
      learner_first_name: ''
    };
  },
  async created() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.introductionText =
      this.card_data.card_detail.attributes.introduction.replace(
        "[learner-name]",
        this.active_data.attributes.user_section.attributes.user.attributes
          .first_name
      );
    this.conclusionText =
      this.card_data.card_detail.attributes.conclusion.replace(
        "[learner-name]",
        this.active_data.attributes.user_section.attributes.user.attributes
          .first_name
      );
    this.learner_first_name = this.active_data.attributes.user_section.attributes.user.attributes.first_name;
    this.character = this.card_data.card_detail.attributes.character;
    this.characterImgAlt =
      this.character.attributes.char_first_name +
      " " +
      this.character.attributes.char_last_name +
      ", " +
      this.character.attributes.world_role;
    this.loId = this.active_data.attributes.learning_object.id;
    this.evaluation_id = this.active_data.attributes.current_evaluation_id;
    this.conversationFinished = this.active_data.attributes.complete;
    let attr = this.active_data.attributes;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    this.learning_id = attr.learning_object_id;
    let loSection = attr.user_section.attributes.learning_object_sections;
    this.hasReviewedFeedback =
      this.active_data.attributes.has_reviewed_feedback;
    this.course_lo_reattempts_disabled = this.active_data.attributes.user_section.attributes.course_disable_reattempts;
    this.lo_reattempts_enabled = this.active_data.attributes.learning_object.attributes.card_detail.attributes.enable_reattempt;
    this.lo_reattempts_max = this.active_data.attributes.learning_object.attributes.card_detail.attributes.max_reattempts;
    this.lo_reattempt_number = this.active_data.attributes.reattempt_number;
    this.user_section_data = this.active_data.attributes.user_section.attributes;
    for (let i = 0; i < loSection.length; i++) {
      if (loSection[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(loSection[i].attributes.sticky_note);
      }
    }

    if (this.evaluation_id) {
      api
        .getdialogicQuestions(
          this.card_data.card_detail.id,
          this.active_data.id,
          false
        )
        .then((res) => {
          this.questions = [...res.data.variations.data];
          this.questions_data = [...this.questions];
          this.getDialogicAnswers(this.evaluation_id).then(() => {
            this.setCurrentQuestion();
          });
        });
    }
  },
  methods: {
    setCurrentQuestion() {
      if (
        this.submitquestions.length > 0 &&
        this.submitquestions[this.submitquestions.length - 1] &&
        this.submitquestions[this.submitquestions.length - 1].attributes &&
        this.submitquestions[this.submitquestions.length - 1].attributes
          .follow_up_question
      ) {
        this.currentQuestionId =
          this.submitquestions[
            this.submitquestions.length - 1
          ].attributes.dialogic_question_id;
        this.getDialogicDraftAnswer();
      } else if (this.questions_datas.length > 0) {
        this.currentQuestionId =
          this.questions_datas[0].attributes.dialogic_question_id;
        this.getDialogicDraftAnswer();
      }
    },
    beforeOpen(event) {
      //console.log("*** hit beforeOpen");
      //console.log(event, "*** event in beforeOpen");
      this.isOpen = true;
      this.type = event.params.type;
      this.title = event.params.title.title;
      this.desc = event.params.title.description;
      if (event.params.type === "video") {
        this.videoCode = event.params.data;
      } else {
        this.sliderImages = event.params.data;
      }
    },
    show(modal) {
      this.$modal.show(modal);
    },
    next(type) {
      if (this.hasReviewedFeedback) {
        this.$modal.hide("dialogicLearningData");
        this.$parent.next(type);
      } else {
        utilFunctionService.showerr(
          "Please review your feedback before proceeding to the next task"
        );
      }
    },
    alertShow() {
      this.$modal.show("alertShow");
    },
    Answer() {
      if (this.ans.length === 0) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }
    },
    autosaveAnswer: _.debounce(function () {
      this.saveDraft();
    }, 2000),
    saveDraft() {
      const _fb = new FormData();
      _fb.append(
        "dialogic_answer[dialogic_question_id]",
        this.currentQuestionId
      );
      _fb.append("dialogic_answer[answer]", this.ans);
      _fb.append("dialogic_answer[is_draft]", true);
      if (this.ans) {
        api.saveDialogicAnswers(this.evaluation_id, _fb);
      }
    },
    isTheEndOfConversation() {
      let check_end =
        this.questions_datas.length === 0 &&
        this.submitquestions[this.submitquestions.length - 1] &&
        !this.submitquestions[this.submitquestions.length - 1].attributes
          .follow_up_question;
      if (check_end == true) {
        if (this.active_data.attributes.complete == false) {
          this.conversationFinished = true;
          this.$emit("dialogicFinished");
        }
      } else {
        this.conversationFinished = false;
      }
    },
    getDialogicAnswers(dialogicEvaluationId) {
      this.submitquestions = [];
      return api.getDialogicAnswers(dialogicEvaluationId).then((res) => {
        let ansData = res.data.data;
        if (ansData && ansData.length > 0) {
          ansData.forEach((element, i) => {
            let index = this.questions.findIndex((elem) => {
              if (
                parseInt(elem.attributes.dialogic_question_id) ===
                parseInt(element.attributes.dialogic_question_id)
              ) {
                return elem.id;
              }
            });
            let indexs = this.questions_data.findIndex((elema) => {
              if (
                parseInt(elema.attributes.dialogic_question_id) ===
                parseInt(element.attributes.dialogic_question_id)
              ) {
                return elema.id;
              }
            });
            if (indexs !== -1) {
              this.questions_data[indexs].status = true;
            }
            if (index !== -1) {
              element.attributes.question =
                this.questions[index].attributes.question;
              if (
                element.attributes.attempt > 4 ||
                (element.attributes.follow_up_question &&
                  element.attributes.follow_up_question.length < 4)
              ) {
                element.attributes.follow_up_question = "";
              }

              if (element.attributes.follow_up_question) {
                element.attributes.follow_up_question =
                  element.attributes.follow_up_question.replace(
                    "[learner-name]",
                    this.active_data.attributes.user_section.attributes.user.attributes
                      .first_name
                  );
              }
              element.attributes.question =
                element.attributes.question.replace(
                  "[learner-name]",
                  this.active_data.attributes.user_section.attributes.user.attributes
                    .first_name
                );
              if (element.attributes.character_response) {
                element.attributes.character_response =
                  element.attributes.character_response.replace(
                    "[learner-name]",
                    this.active_data.attributes.user_section.attributes.user.attributes
                      .first_name
                  );
              }
              
              this.submitquestions.push(element);
            }
            if (i === ansData.length - 1) {
              this.questionsDataVal();
            }
          });
        } else {
          this.questionsDataVal();
        }
      });
    },
    questionsDataVal() {
      this.questions_datas = [];
      if (this.questions_data.length > 0) {
        this.questions_data.forEach((que) => {
          if (!que.status) {
            que.attributes.question = que.attributes.question.replace("[learner-name]", this.learner_first_name);
            //console.log(que, "*** que in questionsDataVal");
            this.questions_datas.push(que);
          }
        });
      }
      this.isTheEndOfConversation();
    },
    nextQuestion(id, answer) {
      utilFunctionService.showLoader();
      answer = this.ans;
      this.ans = "";
      this.disableNextBtn = true;
      const _fb = new FormData();
      _fb.append("dialogic_answer[dialogic_question_id]", id);
      _fb.append("dialogic_answer[answer]", answer);
      _fb.append("dialogic_answer[is_draft]", false);
      api.saveDialogicAnswers(this.evaluation_id, _fb).then(() => {
        this.getDialogicAnswers(this.evaluation_id).then(() => {
          //utilFunctionService.showSuccess("Answer saved")
          this.setCurrentQuestion();
        });
        utilFunctionService.hideLoader();
      });
    },
    dialogicEvaluations() {
      this.show("dialogicLearningData");
      utilFunctionService.showLoader();
      api.dialogicEvaluations(this.evaluation_id).then((res) => {
        utilFunctionService.hideLoader();
        console.log(res, "*** res in dialogicEvaluations");
        if (!this.hasReviewedFeedback) {
          api
            .setHasReviewedFeedback(this.module_id, this.active_data.id)
            .then(() => {
              this.hasReviewedFeedback = true;
            })
            .catch(() => {
              console.log("");
            });
        }
        let complete =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.complete
            ? res.data.data.attributes.complete
            : false;
        if (complete) {
          this.overall_assmnt_item =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.overall_assmnt_item
              ? res.data.data.attributes.overall_assmnt_item.attributes
              : "";
          this.dialogic_debrief_evaluation =
            res && res.data && res.data.data && res.data.data.attributes
              ? res.data.data.attributes.dialogic_debrief_evaluation
              : "";
          this.hideAssessmentLabel =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.hide_assessment_label;
        } else this.createDialogicEvaluations();
      });
    },
    createDialogicEvaluations() {
      api.createEvaluateDialogic(this.evaluation_id).then(() => {
        api.dialogicEvaluations(this.evaluation_id).then((res) => {
          this.overall_assmnt_item =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.overall_assmnt_item
              ? res.data.data.attributes.overall_assmnt_item.attributes
              : "";
          this.dialogic_debrief_evaluation =
            res && res.data && res.data.data && res.data.data.attributes
              ? res.data.data.attributes.dialogic_debrief_evaluation
              : "";
          this.hideAssessmentLabel =
            res &&
            res.data &&
            res.data.data &&
            res.data.data.attributes &&
            res.data.data.attributes.hide_assessment_label;
        });
      });
    },
    resetDialogicInteraction() {
      api
        .resetDialogicInteraction(this.evaluation_id)
        .then(() => {
          this.$router.push({path: `/learning/${this.module_id}/${this.active_data.id}`})
          this.$router.go();
        })
        .catch(() => {});
    },
    getDialogicDraftAnswer() {
      api
        .getDialogicDraftAnswer(this.evaluation_id, this.currentQuestionId)
        .then((res) => {
          if (res.data && res.data.draft_state) {
            this.ans = res.data.answer;
          } else {
            this.ans = "";
          }
        });
    },
    retryInteraction() {
      this.$modal.show("dialog", {
        title: "Are you sure you would like to try again? Please note that any work submitted in your previous attempt at this interaction will not be saved. Your assessment on previous attempts will still be available in your final feedback.",
        text: "",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Yes I'm sure",
            handler: () => {
              utilFunctionService.showLoader();
              api.retryInteraction(this.module_id, this.active_data.id).then(() => {
                utilFunctionService.hideLoader();
                location.reload();
              }).catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showerr("An error has occurred. Please contact Ametros support for assistance.")
              })
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.display-inline {
  display: inline !important;
}

.dialogic-answer {
  margin-bottom: 2.4em;
  margin-top: -1em;

  p {
    //background: #f5f5f5;
    padding: 1em;
    margin-top: 0px;
  }
}

.learn-content {
  .learn-object-info {
    margin: 0px;
    h4 {
      margin-bottom: 1em;
    }
    .simple-dialogic-chat {
      margin-bottom: 0;
    }
    .learner-answer-container {
      margin: 2em 0;
    }
  }
}

#editor-container {
  .learn-object-editor.chat-view-editor {
    padding: 0 !important;
    margin-top: -2em;
      .learn-object-info-learning {
        margin-left: 1em;
        h4 {
          padding-bottom: 1em;
        }
      }
    }
  }

  // Styles for Chat view
  .container {
    float: right;
    border: 1px solid #dedede;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
  }
  .container2 {
    background-color: #f1f1f1;
    border: 1px solid #dedede;
    float: right;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
  }

  .lighter {
    float: left;
    border-color: #1a7061;
    background-color: #1a7061;
    color: white;
  }

  /* Clear floats */
  .container::after {
    content: "";
    clear: both;
    display: table;
  }

  /* Style images */
  .container img {
    float: left;
    max-width: 60px;
    width: 100%;
    margin-right: 20px;
    border-radius: 50%;
  }

  /* Style the right image */
  .container img.right {
    float: right;
    margin-left: 20px;
    margin-right: 0;
  }

  /* Style time text */
  .time-right {
    float: right;
    color: #aaa;
  }

  /* Style time text */
  .time-left {
    float: left;
    color: #999;
  }
  .img-left {
    float: left;
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
  .img-right {
    float: right;
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
  .image-cropper {
    width: 50px !important;
    height: 50px !important;
    margin: 10px 10px 0 0 !important;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
  }
  .emailBody {
    /deep/ p {
      margin: 0 !important;
    }
  }


  .retries {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 45px 0;
    p {
      margin-bottom: 8px;
    }
    button {
      margin-top: 16px;
    }
  }
</style>