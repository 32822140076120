var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn-steps"},[_c('h1',{staticClass:"learn-title"},[_vm._v(_vm._s(_vm.intros.attributes.name))]),(_vm.intros.attributes.description != 'null')?_c('p',{staticClass:"learn-info"},[_vm._v(" "+_vm._s(_vm.intros.attributes.description)+" ")]):_vm._e(),(_vm.intros.attributes.intro_documents)?_c('div',{staticClass:"suggestion-container",staticStyle:{"display":"flex"}},_vm._l((_vm.intros.attributes.intro_documents),function(item){return _c('div',{key:item.id,staticClass:"suggestion-row"},[_c('div',{staticClass:"suggestion-pdf"},[_c('button',{staticClass:"btn pdf-download",attrs:{"aria-label":"download PDF"},on:{"click":function($event){$event.preventDefault();return _vm.downloadPdf(item.attributes.resource_url, 'Introductory_Document')}}},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(item.attributes.file_name))])])])])}),0):_vm._e(),(_vm.intros.attributes.intro_video != null)?_c('div',{staticClass:"introduction"},[_c('div',{staticClass:"wistia_responsive_wrapper"},[_c('iframe',{staticClass:"wistia_embed",attrs:{"src":_vm.preIframUrl +
          _vm.intros.attributes.intro_video.attributes.wistia_code +
          _vm.postIframUrl,"title":"Video.mp4","name":"wistia_embed","allowtransparency":"true","scrolling":"no","allowfullscreen":"","mozallowfullscreen":"","webkitallowfullscreen":"","oallowfullscreen":"","msallowfullscreen":"","width":"100%","height":"100%"}})]),(
        _vm.intros.attributes &&
        _vm.intros.attributes.intro_video.attributes.transcript
      )?_c('div',{staticClass:"intro-transcript"},[_c('p',{staticClass:"transcript-title"},[_vm._v("Transcript:")]),_c('readmore',{attrs:{"more-str":"Read more","text":_vm.intros.attributes.intro_video.attributes.transcript,"link":"#","less-str":"Read less","max-chars":100}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"learn-btn"},[_c('button',{staticClass:"btn secondary",attrs:{"tabindex":"0","aria-label":_vm.menus[_vm.menus.length - 1].attributes.complete
          ? 'View Module'
          : 'Start Module'},on:{"click":function($event){return _vm.getActive(0)}}},[_vm._v(" "+_vm._s(_vm.menus[_vm.menus.length - 1].attributes.complete ? "View Module" : "Start Module")+" "),_c('em',{staticClass:"icon-arrow-right"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }