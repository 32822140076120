<template>
  <div>
    <p v-html="formattedString"></p>
    <span>
      <a
        :href="link"
        v-if="!isReadMore"
        @click="triggerReadMore($event, true)"
        >{{ moreStr }}</a
      >
      <a
        :href="link"
        v-if="isReadMore"
        @click="triggerReadMore($event, false)"
        >{{ lessStr }}</a
      >
    </span>
  </div>
</template>

<script>
export default {
  name: "read-more",
  props: {
    moreStr: {
      type: String,
      default: "read more",
    },
    lessStr: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: "#",
    },
    maxChars: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isReadMore: false,
    };
  },
  computed: {
    formattedString() {
      let val_container = this.text;
      if (!this.isReadMore && this.text.length > this.maxChars) {
        val_container = val_container.substring(0, this.maxChars) + "...";
      }
      return val_container;
    },
  },
  methods: {
    triggerReadMore(e, b) {
      if (this.link == "#") {
        e.preventDefault();
      }
      if (this.lessStr !== null || this.lessStr !== "") this.isReadMore = b;
    },
  },
};
</script>