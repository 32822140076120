<template>
  <!-- <FocusLoop :is-visible="isOpen"> -->
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header modal-print-header mb-4">
          <div class="modal-print-row">
            <div class="modal-print-nav">
              <div class="data-results">Page {{ page }} of {{ numPages }}</div>
              <div aria-label="PDF page navigation">
                <ul class="pagination pdf-pagination">
                  <li class="page-item" v-if="page !== 1">
                    <a class="page-link" aria-label="Previous" @click="page--">
                      &lt;
                    </a>
                  </li>

                  <li class="page-item" v-if="page < numPages">
                    <a class="page-link" aria-label="Next" @click="page++">
                      &gt;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-print-right">
              <button
                class="btn btn-ametros"
                @click.prevent="downloadItem(urlLink)"
                aria-label="Download"
              >
                Download
              </button>
            </div>
          </div>
        </div>
        <div ref="pdfcontainer" style="width: 100%; align-items: center">
          <div
            v-if="loadedRatio > 0 && loadedRatio < 1"
            style="background-color: green; color: white; text-align: center"
            :style="{ width: loadedRatio * 100 + '%' }"
          >
            {{ Math.floor(loadedRatio * 100) }}%
          </div>
          <pdf
            ref="pdfRef"
            :key="key"
            :page="page"
            :source="urlLink"
            :disableAnnotationLayer="true"
            @rendered="handleDocumentRender"
            @rendering-failed="handleDocumentRenderFail"
            :width="pdfWidth"
          ></pdf>
        </div>
        <div class="modal-bottom modal-print-footer mb-4">
          <div class="modal-print-row">
            <div class="modal-print-nav">
              <div class="data-results">Page {{ page }} of {{ numPages }}</div>
              <div aria-label="PDF page navigation">
                <ul class="pagination pdf-pagination">
                  <li class="page-item" v-if="page !== 1">
                    <a class="page-link" aria-label="Previous" @click="page--">
                      &lt;
                    </a>
                  </li>

                  <li class="page-item" v-if="page < numPages">
                    <a class="page-link" aria-label="Next" @click="page++">
                      &gt;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </FocusLoop> -->
</template>
<script>
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Axios from "axios";
export default {
  name: "PdfEmbedded",
  components: {
    pdf,
  },
  props: ["pdfUrl", "fileName"],
  data() {
    return {
      src: {},
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      urlLink: "",
      label: "",
      isOpen: false,
      pdfWidth: 0,
      key: 0
    };
  },
  methods: {  
    downloadItem() {
      Axios.get(this.urlLink, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(err => console.log(err));
    },
    handleDocumentRender() {
      this.numPages = this.$refs.pdfRef.pageCount;
      this.pdfWidth = this.$refs.pdfcontainer.offsetWidth;
    },
    resizePDFContainer() {
      this.isOpen = false;
      this.pdfWidth = this.$refs.pdfcontainer.offsetWidth;
      this.isOpen = true;
    },
    handleDocumentRenderFail() {
      this.key = this.key + 1;
    }
  },
  created() {
    this.src = "";
    this.page = 1;
    this.numPages = 1;
    this.urlLink = "";
    this.urlLink = this.pdfUrl;
    this.label = this.fileName;
    this.src = this.urlLink;
    this.isOpen = true;
    window.addEventListener("resize", this.resizePDFContainer);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizePDFContainer);
  },
};
</script>

<style scoped lang="scss">
.pdf-pagination {
  padding-left: 2em;
}
</style>
