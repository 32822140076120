<template>
  <div id="register" class="register-container paytest-container">

    <div id="logo-box">
      <img src="../assets/images/ametros-learning-logo.png" alt="Ametros Learning logo" class="register-logo" />
    </div>

    <div>
      

      <div class="register-form paytest-form">
        <h1>Moneris Payment Test Form</h1>

        <router-link :to="{ name: 'login' }">Login</router-link>


        <ValidationObserver ref="form">
            <form class="w-100">
              <div class="form-group">
                <!-- <label>Registration Code</label> -->
                  <ValidationProvider name="Card number" rules="required" v-slot="{ errors }">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter card number"
                      v-model="input.card_number"
                      @input="getRegCode(input.reg_code)"
                      :class="[(isActive && errors[0]) ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                    >{{ errors[0].replace("The ", "" )}}</span>
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="showError"
                    >Card number invalid</span>
                  </ValidationProvider>
                <div>
                </div>
              </div>
              <div class="form-group">
                <!-- <label>First Name</label> -->
              <ValidationProvider name="Expiration month" rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  name="expiration_month"
                  class="form-control"
                  v-model="input.expiration_month"
                  placeholder="Enter expiration month"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
              </ValidationProvider>
              </div>

              <div class="form-group">
                <!-- <label>Last Name</label> -->
              <ValidationProvider name="Expiration year" rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  name="expiration_year"
                  class="form-control"
                  v-model="input.expiration_year"
                  placeholder="Enter expiration year"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
              </ValidationProvider>
              </div>

              <div class="form-group">
                <!-- <label>Email</label> -->
              <ValidationProvider name="Verification code" rules="required" v-slot="{ errors }">
                <input
                  type="text"
                  name="verification_code"
                  class="form-control"
                  v-model="input.verification_code"
                  placeholder="Enter verification code"
                  :class="[(isActive && errors[0]) ? 'invalid' : '']"
                />
                <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[isActive ? {'display': 'block'} : {'display': 'none'}]"
                      v-if="errors[0]"
                  >{{ errors[0].replace("The ", "" )}}</span>
              </ValidationProvider>
              </div>

              <div class="text-end">
                <button type="button" class="btn-ametros" v-on:click="process_payment()">Submit payment</button>
              </div>

            </form>
          </ValidationObserver>

          <div ref="editorArea" id="test-area">
            <p>This is some text hi.</p>
          </div>

      </div>
      
    </div>

    



    
  </div>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";

export default {
  name: "PaymentTest",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      input: {
        card_number: "",
        expiration_month: "",
        expiration_year: "",
        verification_code: ""
      },
      url: process.env.VUE_APP_URL,
      loggedIn: false,
      isActive: "",
      showError: false,
      currency: "CAD",
      price: "42.00",
      tax: "1.13", 
      taxable: true,
      final_price: "43.13",
      order_id: "100", 
      end_user_paid: false,
      account_exists: false,
      payment_attempt: false
      
    };
  },
  
  methods: {
    process_payment() {
      this.$refs.form.validate().then(success => {
        this.isActive = true;
        if (!success ) {
          return;
        } else {
          
          const form = {
            card_number: this.input.card_number,
            expiration_month: this.input.expiration_month,
            expiration_year: this.input.expiration_year,
            verification_code: this.input.verification_code, 
            currency: this.currency, 
            price: this.price,
            order_id: this.order_id
          };
          api
            .postPaymentTest(form)
            .then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.paytestSuccess
              );
              this.$router.replace({ name: "paytest-success" });
            })
            .catch(() => {
              this.showError = true;
            });
          }
        }
      );
    },
    intoView(refName) {
      var element = this.$refs[refName];
      console.log(element, "element variable again in payment_test.vue");
      //const contentBottom = this.$el.querySelector('#test-area');
      var top = element.offsetTop;
      console.log(top);
      console.log(this.$refs);
      //window.scrollTo(0, top);
      window.history.scrollRestoration = "manual";
      //contentBottom.scrollTop = 110;
      element.scrollIntoView({block: 'start', inline: 'nearest', behaviour: "smooth"});
    
    },
  },
  mounted() {
      this.intoView('editorArea');
  },
  
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#logo-box {
  width: 500px;
  display: flex;
}
#user-choice {
  display: block;
  width: 500px;
  margin-bottom: 1em;
  margin-top: 2em;

  .account-choice {
    display: block;
    width: 80%;
    margin: 0 auto;
    span {
      margin-bottom: 2em;
    }
  }
}

.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-bold;
    //text-transform: uppercase;
    font-size: 28px;
    margin: 0 0 40px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .modal {
    max-height: 750px;
    overflow-y: auto;
  }
}
</style>
