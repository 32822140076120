import store from "../../store";

export default function isLoggedin({ next }) {
  const auth_token_exists = store.state.user.user.authorization_token;
  try {
    if (auth_token_exists) {
      return next('dashboard')
    } else {
      return next()  
    }
  } catch(error) {
    return next()
  }
}
