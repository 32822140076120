import Vue from 'vue'
import Toasted from 'vue-toasted'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import store from "../store/index";
Vue.use(Loading);
Vue.use(Toasted)
var loader = ''

export const utilFunctionService = {
    setLocalStorageService(keyname, value) {
        localStorage.setItem(keyname, value);
    },

    getLocalStorageService(keyname) {
        if (localStorage.getItem(keyname) !== 'undefined') {
            return JSON.parse(localStorage.getItem(keyname));
        } else {
            return false;
        }
    },

    getAuthToken() {
        const userDetails = store.getters['user/getUserDetails'];
        if (userDetails) {
            return userDetails.authorization_token;
        } else {
            return false;
        }
    },

    currentUserId() {
        const userDetails = store.getters['user/getUserDetails'];
        if (userDetails) {
            return userDetails.id;
        } else {
            return false;
        }
    },

    getUserRoles() {
        const userDetails = store.getters['user/getUserDetails'];
        if (userDetails) {
            return userDetails.attributes.user_roles;
        } else {
            return false;
        }
    },

    removeLocalStorageService(keyname) {
        localStorage.removeItem(keyname);
    },

    removeSessionStorageService(keyname) {
        sessionStorage.removeItem(keyname);
    },

    showLoader() {
        loader = Vue.$loading.show({
            canCancel: true,
            container: this.fullPage,
            onCancel: this.onCancel,
            color: '#3dbc9e',
            loader: 'spinner',
            width: 40,
            height: 40
        })
    },

    showLoaderNoCancel() {
        loader = Vue.$loading.show({
            canCancel: false,
            container: this.fullPage,
            onCancel: this.onCancel,
            color: '#3dbc9e',
            loader: 'spinner',
            width: 40,
            height: 40
        })
    },
    hideLoader() {
        loader.hide()
    },

    showSuccess(message) {
        Vue.toasted.show(`
            <div class="toaster-msg toaster-msg-success">
              <div class="d-flex flex-nowrap">
                  <em class="icon-check"></em>
                  <span class="toaster-msg-success-text">${message}</span>
              </div>
            </div>`, {
            type: 'success',
            theme: "outline",
            duration: 10000,
            position: 'top-center',
            singleton: false,
            containerClass: [
                'toaster-msg',
                'toaster-msg-success'
            ],
            action: [
                {
                    text: ``,
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                }]
        })
    },

    showerr(message) {
        Vue.toasted.show(`
        <div class="toaster-msg toaster-msg-error">
          <div class="d-flex">
              <em class="icon-danger"></em>
              <span class="toaster-msg-error-text">${message}</span>
          </div>
        </div>`, {
            type: 'error',
            theme: "outline",
            duration: 10000,
            position: 'top-center',
            singleton: false,
            containerClass: [
                'toaster-msg',
                'toaster-msg-error'
            ],
            action: [
                {
                    text: ``,
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                }]
        })
    },

    getImageUrl() {
        let url = process.env.VUE_APP_URL
        return url.replace('api/', '')
    },

    bytesToSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB']
        if (bytes === 0) return 'n/a'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        if (i === 0) return `${bytes} ${sizes[i]}`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    },
    preventCopyPaste(event) {
        event.preventDefault();
        this.showerr("Pasting text is disabled.");
    },
}
