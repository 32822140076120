<template>
  <div>
    <div class="learn-steps">
      <h1 class="learn-title">{{ timeLineTitle }}</h1>
      <div v-if="sticky_note.length">
        <div v-for="(notes, index) in sticky_note" :key="index">
          <div class="learn-notes my-3" v-if="notes && notes.length">
            <p class="note-title">
              <img
                src="../assets/images/icon-yellownote.svg"
                alt=""
              />Note
            </p>
            <p v-html="notes">{{ notes }}</p>
          </div>
        </div>
      </div>
      <p class="learn-info" v-html="description"></p>
    </div>
    <!-- The v-if below is displayed when a submission has already been made -->
    <div v-if="emailData.length > 0">
      <div class="learn-object">
        <div v-for="(data, key) in emailData" :key="data.id">
          <div class="learn-object-forward" v-if="key === 0">
            <div class="learn-object-tag">
              {{ data.attributes.email_subject }}
            </div>
            <div class="learn-object-head">
              <div
                class="learn-object-col"
                v-for="character in toCharacters"
                :key="'toCharacter' + character.id"
              >
                <div class="learn-object-user"></div>
                <div class="learn-object-titles">
                  <h2>{{ myDetails.full_name }}</h2>
                  <p>To: {{ toCharacters[0].attributes.char_full_name }}</p>
                </div>
              </div>
            </div>
            <div class="learn-object-paragraph">
              <div class="learn-object-desc">
                <p v-html="data.attributes.email_body"></p>
              </div>
            </div>
          </div>
          <!-- Reply From -->
          <div class="learn-object-reply" v-if="key !== 0">
            <div class="learn-reply-head">
              <span>Reply from {{ myDetails.full_name }}</span>
            </div>
            <div class="learn-object-tag" v-if="data.attributes.email_subject">
              Reply: {{ data.attributes.email_subject }}
            </div>
            <div class="learn-object-head">
              <div class="learn-object-col">
                <div class="learn-object-user"></div>
                <div class="learn-object-titles">
                  <h2>{{ toCharacters[0].attributes.char_full_name }}</h2>
                  <p>To: {{ myDetails.full_name }}</p>
                </div>
              </div>
            </div>
            <div class="learn-object-paragraph">
              <div class="learn-object-desc">
                <p v-html="data.attributes.email_body"></p>
              </div>
            </div>
          </div>
          <div
            class="learn-object-reply"
            v-if="data.attributes.interim_response"
          >
            <div class="learn-reply-head">
              <span
                >Reply from
                {{
                  data.attributes.interim_response
                    ? toCharacters[0].attributes.char_full_name
                    : myDetails.full_name
                }}</span
              >
            </div>
            <div class="learn-object-tag" v-if="data.attributes.email_subject">
              Reply: {{ data.attributes.email_subject }}
            </div>
            <div class="learn-object-head">
              <div class="learn-object-col">
                <div class="learn-object-user">
                  <img
                    :src="toCharacters[0].attributes.char_photo_url.thumbnail"
                    v-if="toCharacters[0].attributes.char_photo_url.thumbnail"
                    v-bind:alt="toCharacters[0].attributes.char_first_name +' '+toCharacters[0].attributes.char_last_name +', '+toCharacters[0].attributes.world_role"
                  />
                </div>
                <div class="learn-object-titles">
                  <h2>
                    {{
                      data.attributes.interim_response
                        ? toCharacters[0].attributes.char_full_name
                        : myDetails.full_name
                    }}
                  </h2>
                  <p>
                    To:
                    {{
                      data.attributes.interim_response
                        ? myDetails.full_name
                        : toCharacters[0].attributes.char_full_name
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="learn-object-paragraph">
              <div class="learn-object-desc">
                <p v-html="data.attributes.interim_response"></p>
              </div>
            </div>
          </div>

          <div
            class="learn-object-reply"
            v-if="
              data.attributes.email_submission_response &&
              data.attributes.email_submission_response.attributes &&
              data.attributes.email_submission_response.attributes.response
            "
          >
            <div class="learn-reply-head">
              <span
                >Reply from
                {{
                  data.attributes.interim_response
                    ? toCharacters[0].attributes.char_full_name
                    : myDetails.full_name
                }}</span
              >
            </div>
            <div class="learn-object-tag" v-if="data.attributes.email_subject">
              Reply: {{ data.attributes.email_subject }}
            </div>
            <div class="learn-object-head">
              <div class="learn-object-col">
                <div class="learn-object-user">
                  <img
                    :src="toCharacters[0].attributes.char_photo_url.thumbnail"
                    v-if="toCharacters[0].attributes.char_photo_url.thumbnail"
                    v-bind:alt="toCharacters[0].attributes.char_first_name +' '+toCharacters[0].attributes.char_last_name +', '+toCharacters[0].attributes.world_role"
                  />
                </div>
                <div class="learn-object-titles">
                  <h2>{{ toCharacters[0].attributes.char_full_name }}</h2>
                  <p>To: {{ myDetails.full_name }}</p>
                </div>
              </div>
            </div>
            <div class="learn-object-paragraph">
              <div class="learn-object-desc">
                <p v-html="character_response"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="learn-object-reply learn-object-editable" v-if="resubmit">
          <div class="learn-reply-head">
            <span>Reply from {{ myDetails.full_name }}</span>
          </div>
          <div class="learn-object-head">
            <div class="learn-object-col">
              <div class="learn-object-user"></div>
              <div class="learn-object-titles">
                <h2>{{ toCharacters[0].attributes.char_full_name }}</h2>
                <p>To: {{ myDetails.full_name }}</p>
              </div>
            </div>
          </div>
          <!-- learn object-editor start-->
          <div class="learn-object-editor">
            <div class="form-group">
              <vue-editor
                :editorToolbar="simpleToolbar"
                v-model="emailBody"
                @input="
                  disableOrEnableSubmit();
                  autosaveEmail();
                "
              ></vue-editor>
            </div>
            <div class="learn-object-send">
              <button
                class="btn btn-ametros"
                @click="postEmailSubmissions"
                :disabled="disableSubmitBtn"
                aria-label="Submit"
              >
                Submit
              </button>
            </div>
            <!-- learn object-editor end-->
          </div>
        </div>
      </div>
      <div
        class="learn-object yellow-note"
        v-if="learner_hold_on_submission && status !== 'published'"
      >
        <p>
          Note: You won't be able to move to the next task until your instructor
          has reviewed and graded your submitted work.
        </p>
      </div>
      <lo-buttons
        v-if="status == 'published'"
        :nextMethod="showSubmissionFeedback"
        :nextStatus="nextStatus"
        text="See Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
      <lo-buttons
        v-if="
          !notSubmittedYet &&
          !learner_hold_on_submission && 
          status !== 'published'
        "
        :nextMethod="next"
        :nextStatus="nextStatus"
        text="See Final Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
    </div>

    <!-- The v-if below covers the scenario where no submission has been made yet -->
    <div class="learn-object" v-if="emailData.length === 0">
      <div class="learn-object-forward">
        <div class="learn-object-head">
          <!-- learn object col repeat start  -->
          <div
            class="learn-object-col"
            v-for="character in toCharacters"
            :key="'toCharacter' + character.id"
          >
            <div class="learn-object-label">To</div>
            <div class="learn-object-user">
              <img
                :src="character.attributes.char_photo_url.thumbnail"
                v-if="character.attributes.char_photo_url.thumbnail"
                v-bind:alt="character.attributes.char_first_name +' '+character.attributes.char_last_name +', '+character.attributes.world_role"
              />
            </div>
            <div class="learn-object-titles">
              <h2>{{ character.attributes.char_full_name }}</h2>
              <p>To Me</p>
            </div>
          </div>
          <div
            class="learn-object-col"
            v-for="character in ccCharacters"
            :key="character.id"
          >
            <div class="learn-object-label">CC</div>
            <div class="learn-object-user">
              <img
                :src="character.attributes.char_photo_url.thumbnail"
                v-if="character.attributes.char_photo_url.thumbnail"
                v-bind:alt="character.attributes.char_first_name +' '+character.attributes.char_last_name +', '+character.attributes.world_role"
              />
            </div>
            <div class="learn-object-titles">
              <h2>{{ character.attributes.char_full_name }}</h2>
              <p>{{ character.attributes.world_role }}</p>
            </div>
          </div>
          <!-- learn object col repeat end  -->
        </div>
        <div class="learn-object-mail">
          <div class="learn-object-tag">
            <input
              v-if="!alreadyPosted"
              class="form-control"
              v-model="name"
              @input="autosaveEmail()"
              type="text"
              placeholder="Subject"
              aria-label="Subject"
            />
            <span v-else>
              Subject:
              <span v-html="name"></span>
            </span>
          </div>
          <div class="learn-object-editor-wrap">
            <div class="learn-object-desc">
              <vue-editor
                v-if="!alreadyPosted"
                :editorToolbar="simpleToolbar"
                v-model="emailBody"
                @input="
                  disableOrEnableSubmit();
                  autosaveEmail();
                "
              ></vue-editor>
            </div>
          </div>
          <div class="learn-upload-btn" v-if="has_pdf_upload">
            <span>
              <input
                type="file"
                accept="application/pdf"
                @change="onFileChange"
                ref="referenceUpload"
                multiple
                aria-label="Attach PDF"
              />
              Attach PDF
            </span>
          </div>
          <div class="learner-uploaded-data" v-if="files.length > 0">
            <div class="learning-data-list">
              <div
                class="learning-data-item"
                v-for="(data, index) in files"
                :key="data.name"
              >
                <div class="learning-data-icon">
                  <a>
                    <img src="../assets/images/pdf-big-icon.png" alt="" />
                  </a>
                  <div class="learning-data-title text-detail">
                    <span class="text-right">{{ data.name }}</span>
                    <h4 class="file-size">{{ size(data.size) }}</h4>
                    <div class="progress-outer" v-if="isFileUploaded">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="uploadPercentage"
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <button 
                      @click="removeFile(index)" 
                      v-if="files.length != 0"
                      aria-label="Remove"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="has_pdf_upload">
          <div
            class="learn-object-send"
            v-if="(!name || !emailBody) && !alreadyPosted"
          >
            <button
              :class="`btn btn-ametros ${name && emailBody ? '' : 'disabled'}`"
              aria-label="Submit"
            >
              Submit
            </button>
          </div>
          <div class="learn-object-send" v-else>
            <button
              class="btn btn-ametros"
              @click="postEmailSubmissions"
              v-if="!alreadyPosted"
              aria-label="Submit"
            >
              Submit
            </button>
          </div>
        </div>

        <div v-if="!has_pdf_upload">
          <div
            class="learn-object-send"
            v-if="(!name || !emailBody) && !alreadyPosted"
          >
            <button
              :class="`btn btn-ametros ${name && emailBody ? '' : 'disabled'}`"
              aria-label="Submit"
            >
              Submit
            </button>
          </div>
          <div class="learn-object-send" v-else>
            <button
              class="btn btn-ametros"
              @click="postEmailSubmissions"
              v-if="!alreadyPosted"
              aria-label="Submit"
            >
              Submit
            </button>
          </div>
        </div>

        <div class="learn-btn" v-if="status == 'published'">
          <button
            class="btn secondary"
            @click="showSubmissionFeedback()"
            aria-label="See Feedback"
          >
            {{ "See Feedback" }}
            <em class="icon-arrow-right"></em>
          </button>
        </div>
      </div>
      <div class="messages-notes" v-if="interimResponse != null">
        <p class="note-title">Message from Instructor</p>
        <p v-html="interimResponse"></p>
      </div>
    </div>

    <div v-if="user_roles.includes('testing-internal')">
      <button @click="resetSubmissionEmail()" class="btn secondary">
        Reset Interaction
      </button>
    </div>

    <modal
      name="submissionEmailEvaluation"
      aria-label="submissionEmailEvaluation"
      role="dialog"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div
          class="
            modal-content
            modal-final-content
            modal-dialogic-content
            modal-grade-content
          "
        >
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('submissionEmailEvaluation')"
            aria-label="Close Modal"
          >
            <em class="icon-cross"></em>
          </button>
          <VuePerfectScrollbar
            class="modal-slide-content mt-2"
            :settings="settings"
          >
            <div class="modal-body m-auto">
              <div class="modal-title">
                <h1>Here is your Feedback.</h1>
                <div class="modal-grade">
                  <p v-if="display_grades">
                    Grade {{ Math.round(emailDebriefResult.overall_grade) }}%
                  </p>
                  <button
                    class="btn btn-ametros"
                    v-if="emailDebriefResult.overall_grade < 80 && canRepeat"
                    @click="resubmitData"
                    aria-label="Repeat"
                  >
                    Repeat
                  </button>
                </div>
              </div>
              <div class="messages-notes" v-if="emailDebriefResult.feedback">
                <p class="note-title">Instructor comments</p>
                <p v-html="emailDebriefResult.feedback"></p>
              </div>
              <div class="modal-grade-items">
                <div
                  class="modal-grade-row"
                  v-for="result in emailDebriefResult.submission_gradings"
                  :key="result.id"
                >
                  <div class="modal-grade-icon" v-if="display_grades">
                    <p>{{ result.attributes.score }}</p>
                    <span>Score</span>
                  </div>
                  <div class="modal-grade-content">
                    <div class="modal-grade-head">
                      <h4>{{ result.attributes.rubric_criteria }}</h4>
                      <button
                        v-if="result.attributes.rubric_criteria_desc"
                        :content="result.attributes.rubric_criteria_desc"
                        v-tippy="{ trigger: 'click', arrow: true }"
                        class="feedback-info no-style-btn"
                        aria-label="Show rubric criteria description"
                      >
                        i
                        <span class="visually-hidden">Show rubric criteria description</span>
                      </button>
                      <span v-if="display_grades"
                        >(Weight {{ result.attributes.rubric_weight }}%)</span
                      >
                    </div>
                    <p v-html="result.attributes.rubric_feedback"></p>
                  </div>
                </div>
              </div>
              <lo-buttons
                :nextMethod="next"
                :nextStatus="nextStatus"
                text="See Final Feedback"
                :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
              ></lo-buttons>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
    <PdfModal />
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import loButtons from "../components/loButtons.vue";
import PdfModal from "@/components/pdfModal";
import _ from "lodash";

export default {
  name: "submissionEmail",
  components: {
    PdfModal,
    VueEditor,
    VuePerfectScrollbar,
    loButtons
  },
  props: ["active_data", "nextStatus", "user", "percentage"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      loId: "",
      name: "",
      description: "",
      timeLineTitle: "",
      isPosted: true,
      toCharacters: "",
      ccCharacters: "",
      emailBody: "",
      fullToolbar: [],
      simpleToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      interimResponse: null,
      email_submission_response: "",
      sticky_note: [],
      learning_id: "",
      status: "",
      resp_id: "",
      alreadyPosted: false,
      emailDebriefResult: "",
      canRepeat: false,
      display_grades: true,
      emailData: [],
      resubmit: false,
      myDetails: {},
      feedback: "",
      learner_hold_on_submission: false,
      file: null,
      files: [],
      uploadPercentage: { width: "0%" },
      isFileUploaded: false,
      has_pdf_upload: false,
      url: utilFunctionService.getImageUrl(),
      character_response: "",
      user_roles: utilFunctionService.getUserRoles(),
      disableSubmitBtn: true,
      hasReviewedFeedback: false,
      isOpen: false
    };
  },
  async created() {
    this.loId = this.active_data.id;
    let attr = this.active_data.attributes;
    this.description = attr.learning_object.attributes.description;
    this.toCharacters =
      attr.learning_object.attributes.card_detail.attributes.to_character;
    this.ccCharacters =
      attr.learning_object.attributes.card_detail.attributes.cc_characters;
    this.has_pdf_upload =
      attr.learning_object.attributes.card_detail.attributes.pdf_submission_enabled;
    this.timeLineTitle = attr.learning_object.attributes.name;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    let lo_sect = attr.user_section.attributes.learning_object_sections;
    this.learning_id = attr.learning_object_id;
    this.hasReviewedFeedback = this.active_data.attributes.hasReviewedFeedback;
    for (let i = 0; i < lo_sect.length; i++) {
      if (lo_sect[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(lo_sect[i].attributes.sticky_note);
        this.learner_hold_on_submission =
          lo_sect[i].attributes.learner_hold_on_submission;
      }
    }
    this.myDetails = attr.user_section.attributes.user.attributes;
    this.getEmailSubmissions(this.loId);
    if (this.active_data.attributes.complete != true) {
      this.getDraftEmail(this.loId);
    }
  },
  methods: {
    beforeOpen() {
      this.isOpen = true;
    },
    size(bytes) {
      return utilFunctionService.bytesToSize(bytes);
    },
    next() {
      this.$parent.next();
    },
    getEmailSubmissions(id) {
      utilFunctionService.showLoader();
      api
        .getEmailSubmissions(id)
        .then((res) => {
          let dataVal = res.data.data;
          console.log(
            res.data.data,
            "*** res.data.data in getEmailSubmissions in submissionEmail.vue"
          );
          if (dataVal.length > 0) {
            this.emailData = dataVal;
            this.email_submission_response = dataVal[dataVal.length - 1]
              .attributes.email_submission_response
              ? dataVal[0].attributes.email_submission_response
              : "";
            this.status =
              dataVal[
                dataVal.length - 1
              ].attributes.email_submission_response_status;
            this.resp_id =
              dataVal[
                dataVal.length - 1
              ].attributes.email_submission_response_id;
            this.character_response =
              this.email_submission_response.attributes.response.replace(
                "[learner-name]",
                this.myDetails.first_name
              );
          }
          utilFunctionService.hideLoader();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getDraftEmail() {
      api.getDraftSubmissionEmail(this.loId).then((res) => {
        if (res.data && res.data.draft_state) {
          this.emailBody = res.data.email;
          this.name = res.data.subject;
        }
      });
    },
    autosaveEmail: _.debounce(function () {
      if (this.emailBody !== "") {
        this.saveDraft();
      }
    }, 2000),
    saveDraft() {
      let fdata = new FormData();
      fdata.append("user_email_submission[user_learn_obj_id]", this.loId);
      fdata.append("user_email_submission[email_body]", this.emailBody);
      fdata.append("user_email_submission[email_subject]", this.name);
      fdata.append("user_email_submission[is_draft]", true);
      if (this.alreadyPosted === false) {
        api.postEmailSubmissions(fdata);
      }
    },
    postEmailSubmissions() {
      utilFunctionService.showLoader();
      let fdata = new FormData();
      fdata.append("user_email_submission[user_learn_obj_id]", this.loId);
      fdata.append("user_email_submission[email_body]", this.emailBody);
      fdata.append("user_email_submission[email_subject]", this.name);
      fdata.append("user_email_submission[is_draft]", false);
      api
        .postEmailSubmissions(fdata)
        .then(() => {
          utilFunctionService.hideLoader();
          this.resubmit = false;
          this.alreadyPosted = true;
          utilFunctionService.showSuccess("Email submitted successfully");
          this.getEmailSubmissions(this.loId);
          this.$router.go();
        })
        .catch(() => {
          utilFunctionService.hideLoader();
          this.resubmit = false;
        });
    },
    showSubmissionFeedback() {
      api
        .getSubmissionEmailEvaluation(this.active_data.id, this.resp_id)
        .then((res) => {
          this.emailDebriefResult = res.data.data.attributes;
          this.canRepeat = res.data.can_repeat;
          this.display_grades = res.data.display_grade;
          this.$modal.show("submissionEmailEvaluation");
        })
        .catch(() => {});
    },
    resubmitData() {
      this.resubmit = true;
      this.$modal.hide("submissionEmailEvaluation");
    },
    resetSubmissionEmail() {
      api
        .resetSubmissionEmail(this.loId)
        .then(() => {
          this.$router.push({path: `/learning/${this.module_id}/${this.active_data.id}`})
          this.$router.go();
        })
        .catch(() => {});
    },
    disableOrEnableSubmit() {
      let trimmedString = this.emailBody.replace(/\s/g, "");
      // empty vue editor's length is 7 for some reason ...
      if (trimmedString.length > 7 && this.name.trim().length > 0) {
        this.disableSubmitBtn = false;
      } else {
        this.disableSubmitBtn = true;
      }
    },
    onFileChange(e) {
      let target = e.target,
        files = target.files || e.dataTransfer.files;
      const filename = files[0].name;
      const ext = filename.split(".").pop().toLowerCase();
      if (ext == "pdf") {
        this.file = files[0];
      } else {
        e.target.value = '';
        utilFunctionService.showerr("Please upload only pdf files.")
        return false;
      }
    },
  },
};
</script>
