<template>
    <div id="pearsonRegister">
        <div v-if="fromPearson">
            <register :regcode="regcode" :fromPearson="fromPearson"/>
        </div>
        <div v-else>
            <unauthorized />
        </div>

    </div>
</template>
<script>
import Register from '../views/register'
import Unauthorized from '../views/unauthorized'

export default {
  name: "PearsonRegister",
  components: {
    Register,
    Unauthorized
  },
  data() {
    return {
        fromPearson: false,
        regcode: ''
    };
  },
  mounted() {
  },
  created() {
   this.setRegcode();
   this.checkDomain();
  },
  methods: {
      setRegcode(){
          let route_arr = this.$route.path.split("/");
          this.regcode = route_arr[route_arr.length - 1];
      },
      checkDomain(){
         let referrer = document.referrer;
         console.log("***referrer");
         console.log(referrer);
         if(referrer.match(/.ecollege\.com./) || referrer.match(/.pearsoncmg\.com./) 
           || referrer.match(/.pearson\.com./)){
               console.log("Domain matched for Pearson...")
            this.fromPearson = true;
         }    
      }
    
  }
};
</script>

