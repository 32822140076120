<template>
  <div>
    <sso-terms
      v-if="showSSOTerms"
      :sso_learner_id="this.learner_id"
      :showSSOTerms="this.showSSOTerms"
      :already_paid="this.already_paid"
      :needs_to_pay="this.needs_to_pay"
      @update:parent="showSSOTerms = $event"
      @update:parentb="showSSOPaymentForm = $event"
      @update:parentc="showDashboard = $event"
    ></sso-terms>

    <sso-payment-form
      v-if="showSSOPaymentForm"
      :course_name="this.course_name"
      :price="this.price"
      :tax_rate="this.tax_rate"
      :taxable="this.taxable"
      :final_price="this.final_price"
      :learner_registration_id="this.learner_registration_id"
      :currency="this.currency"
      @update:parent="showSSOPaymentForm = $event"
      @update:parentb="showDashboard = $event"
    ></sso-payment-form>

    <div v-if="showDashboard">
      <VueSkipTo to="#main" label="Skip to main content" role="complementary" />
      <sidebar></sidebar>
      <main class="container pr-0" role="main">
        <div
          :class="[
            modules_data.length > 0 ? '' : 'dashboard-no-data',
            'dashboard',
          ]"
        >
          <div class="dashboard-content wow fadeIn" data-wow-delay="1s">
            <div class="wow fadeInDown" data-wow-delay="1s" v-if="isDisplay">
              <img
                class="dashboard-banner"
                v-if="dashBoard.welcome_img"
                :src="dashBoard.welcome_img"
                :alt="dashBoard.alt_text"
              />
            </div>
            <h1 class="wow fadeIn" data-wow-delay="1s">
              {{ dashBoard.welcome_text }}
            </h1>
            <h2 class="wow fadeIn" data-wow-delay="1s">
              {{ dashBoard.title }}
            </h2>
            <p class="wow fadeIn" data-wow-delay="1s">
              {{ dashBoard.description }}
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import ssoTerms from "@/components/SSOTerms.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { mapGetters } from "vuex";
import ssoPaymentForm from "../components/SSOPaymentForm.vue";

export default {
  watch: {
    titlePage: {
      immediate: true,
      handler() {
        document.title = this.titlePage;
      },
    },
  },
  name: "dashboard-component",
  metaInfo: {
    title: "Ametros-Learner",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: {
    sidebar,
    ssoTerms,
    ssoPaymentForm,
  },
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  filters: {
    uppCase: function (value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      let val = value.toString();
      if (onlyFirstCharacter) {
        return val.charAt(0).toUpperCase() + val.slice(1);
      } else {
        return val.toUpperCase();
      }
    },
    headings: function (value) {
      return value;
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      titlePage: "Ametros Learning - Dashboard",
      type: "dashboard",
      modules_data: [],
      isDisplay: false,
      showSSOPaymentForm: false,
      showSSOTerms: false,
      showDashboard: false,
      sso_accept_terms: "",
      sso_authenticated: false,
      course_name: "",
      payment_type: "",
      already_paid: "",
      needs_to_pay: "",
      price: "",
      tax_rate: "",
      currency: "",
      final_price: "",
      taxable: "",
      learnerRegData: "",
      learner_registration_id: "",
      learner_id: "",
      dashBoard: {
        title: "",
        description: "",
        welcome_img: "",
        welcome_text: "",
        alt_text: ""
      },
    };
  },
  methods: {
    dashboard() {
      api
        .dashboard()
        .then((res) => {
          this.dashBoard.title = res.data.data.attributes.title;
          this.dashBoard.description = res.data.data.attributes.description;
          this.dashBoard.welcome_img = res.data.data.attributes.welcome_img;
          this.dashBoard.welcome_text = res.data.data.attributes.welcome_text;
          this.dashBoard.alt_text = res.data.data.attributes.image_alt_text;
          this.isDisplay = true;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            if (this.sso_authenticated) {
              utilFunctionService.showerr(
                "Session has expired."
              );
              this.$store.dispatch("user/logout");
              this.$router.push({ name: "lti-logout" });
            } else {
              utilFunctionService.showerr(
                "Signature has expired. Please login again."
              );
              this.$store.dispatch("user/logout");
              this.$router.push({ name: "login" });
            }
          }
        });
    },
    set_sso_visibility() {
      if (this.sso_accept_terms == true && this.needs_to_pay == false) {
        this.showDashboard = true;
        this.showSSOPaymentForm = false;
        this.showSSOTerms = false;
      }
      if (this.sso_accept_terms == true && this.needs_to_pay == true) {
        this.showSSOPaymentForm = true;
        this.showSSOTerms = false;
        this.showDashboard = false;
      }
    },
  },
  created() {
    const user_data = this.userDetails;

    this.sso_accept_terms = user_data.attributes.sso_accept_terms;
    this.sso_authenticated = user_data.attributes.sso_authenticated;
    this.learner_id = user_data.id;
    this.learner_registration_id = user_data.attributes.learner_registration_id;


    if (this.sso_authenticated == true) {
      if (this.sso_accept_terms == false) {
        this.showSSOTerms = true;
        this.showDashboard = false;
      }

      // check if needs payament and if has paid or not
      if (this.learner_registration_id !== null) {
        api
          .getLearnerRegistration(this.learner_registration_id)
          .then((res) => {
            this.learnerRegData = res.data.data;
            this.courseData =
              res.data.data.attributes.customer_course.attributes;
            this.course_name = this.learnerRegData.attributes.course_name;
            this.payment_type = this.courseData.payment_type;
            this.price = parseFloat(this.courseData.price);
            this.tax_rate = parseFloat(this.courseData.tax_rate);
            this.already_paid = this.learnerRegData.attributes.end_user_paid;
            this.currency = this.courseData.currency;
            this.final_price = this.price + this.price * this.tax_rate;
            if (this.payment_type == "end_user" && this.already_paid == false) {
              console.log("*** hit this.needs_to_pay = true conditional set");
              this.needs_to_pay = true;
            }
            this.set_sso_visibility();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              console.log(error);
            }
          });
      } else {
        this.set_sso_visibility();
      }
    }

    if (this.sso_authenticated == false) {
      this.showDashboard = true;
    }

    this.dashboard();
  },
};
</script>

