<template>
  <div class="sidebar" role="navigation">
    <div class="logo">
      <router-link to="/dashboard" tabindex="0">
        <img src="../assets/images/ametros-learning-logo.png" alt="Ametros Learning logo" />
      </router-link>
    </div>
    <div class="navbar">
      <ul>
        <li v-bind:class="[currentPage('/active')  ? 'active' : '']">
          <router-link to="/active" tabindex="0">
            Active Modules
          </router-link>
        </li>
        <li v-bind:class="[currentPage('/completed')  ? 'active' : '']">
          <router-link to="/completed" tabindex="0">
            Completed Modules
          </router-link>
        </li>
        <li v-bind:class="[currentPage('/expired')  ? 'active' : '']">
          <router-link to="/expired" tabindex="0">
            Expired Modules
          </router-link>
        </li>
        <li class="navbar-item">
          <div class="navbar-support">
            <em class="icon-headphones"></em>
            <router-link to="/contact" tabindex="0">
            Support
            </router-link>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer">
      <router-link to="/profile">
        <div class="footer-image" v-if="learnerInfo && learnerInfo.attributes">
          <img :src="learnerInfo.attributes.profile_url"
                v-if="learnerInfo.attributes.profile_url" alt="Edit Profile" />
        </div>
      </router-link>
      <router-link to="/profile">
        <div class="edit-profile">
          <span>Edit Profile</span>
        </div>
      </router-link>
      <div class="footer-content" v-if="learnerInfo && learnerInfo.attributes">
        <div class="footer-title">{{ learnerInfo.attributes.first_name }}</div>
      </div>
      <button @click="logOut()" class="signOut">
        Sign_out
      </button>
    </div>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { mapGetters } from "vuex";
export default {
  name: "sidebar-component",
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  data() {
    return {
      toggle: "hide",
      learnerInfo: {},
      sso_authenticated: false,
    };
  },
  methods: {
    currentPage(submenu) {
      return this.$route.path === submenu ? true : false;
    },
    logOut() {
      if (this.sso_authenticated) {
        this.$store.dispatch("user/logout");
        utilFunctionService.removeLocalStorageService("vuex");
        this.$router.push({ name: "lti-info" });
      } else {
        this.$store.dispatch("user/logout");
        utilFunctionService.removeLocalStorageService("vuex");

        this.$router.push({ name: "login" });
      }
    },
  },
  updated() {
    if (
      this.$route.path !== "/content-document" &&
      this.$route.path !== "/content-images" &&
      this.$route.path !== "/content-videos"
    ) {
      this.toggle = "hide";
    } else {
      this.toggle = "show";
    }
  },
  created() {
    this.sso_authenticated = this.userDetails.attributes.sso_authenticated;
    this.learnerInfo = this.userDetails;
    if (
      this.$route.path !== "/content-document" &&
      this.$route.path !== "/content-images" &&
      this.$route.path !== "/content-videos"
    ) {
      this.toggle = "hide";
    } else {
      this.toggle = "show";
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.sidebar {
  .signOut {
    color: $input-error-color;
    background: none;
    border: none;
    @include font-regular;
    cursor: pointer;
    text-decoration: underline;
    @include media-breakpoint(sm) {
      margin: 0 0 0 10px;
    }
    @include media-breakpoint(md) {
      @include position(fixed, null, null, 30px, 20px);
    }
    @include media-breakpoint(xl) {
      @include position(null, null, null, 30px, 50px);
    }
  }
  @include position(fixed, 0, 0, 0, 0);
  width: 100%;
  z-index: 200;
  @include flexProperty(space-between, null, column);
  padding: 20px;
  background-color: $white;
  max-height: 100%;
  overflow: auto;
  @include media-breakpoint(sm) {
    @include flexProperty(flex-start, null, row, wrap);
    max-height: inherit;
    bottom: auto;
  }
  @include media-breakpoint(md) {
    width: 180px;
  }
  @include media-breakpoint(xl) {
    width: 230px;
    padding: 50px 10px 0 50px;
  }
  .logo {
    width: 160px;
    @include media-breakpoint(sm) {
      width: 48px;
      overflow: hidden;
      flex: none;
    }
    a {
      display: block;
      flex: none;
      img {
        @include media-breakpoint(sm) {
          width: 170px;
          flex: none;
          max-width: inherit;
        }
      }
    }
  }
  .navbar {
    @include font-bold;
    margin: auto 0;
    padding: 0;
    font-size: 14px;
    @include media-breakpoint(sm) {
      width: 100%;
      font-size: 16px;
    }
    ul {
      margin: 0;
      list-style: none;
      @include media-breakpoint(sm) {
        @include flexProperty;
        width: 100%;
      }
    }
    li {
      margin: 30px 0 0;
      @include media-breakpoint(sm) {
        padding: 0 45px 0 0;
        margin: 20px 0 0;
        span {
          display: none;
        }
      }
      &.navbar-item {
        margin: 50px 0 0;
        @include media-breakpoint(sm) {
          @include position(fixed, 0, 0);
          @include flexProperty(null, center);
          margin: 0;
          padding: 20px;
          width: auto;
        }
      }
    }
    a {
      @include font-regular;
      color: $black;
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
    }
    .navbar-support {
      @include font-semiBold;
      font-size: 15px;
      border-radius: 5px;
      display: inline-block;
      border: solid 1px $primary-button-color;
      background-color: $light-button-color;
      padding: 6px 14px;
      color: $primary-button-color;
      em {
        margin: 3px 5px 0 0;
        font-size: 12px;
      }
      &:hover {
        color: $primary-button-color;
      }
    }
    .active {
      a {
        @include font-black;
        color: $black;
        font-weight: bold;
      }
    }
  }
  .footer {
    padding: 20px 0 60px;
    @include media-breakpoint(sm) {
      @include flexProperty(null, flex-end);
      @include position(fixed, null, null, 0, 0);
      padding: 10px 10px 20px 20px;
      background: $white;
      width: 100%;
      height: 76px;
      box-shadow: rgba(0, 0, 0, 0.07) 0 0 20px;
    }
    .footer-image {
      width: 39px;
      height: 39px;
      overflow: hidden;
      flex: none;
      border-radius: 100%;
      background: url("../assets/images/image-placeholder.svg") no-repeat;
      background-position: center;
      background-size: contain;
      img {
        @include imageSize;
      }
    }
    .edit-profile {
      margin-bottom: 2em;
    }
    .footer-content {
      @include media-breakpoint(sm) {
        flex: 1;
        margin: 0 0 0 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .footer-title {
      @include font-black;
      font-size: 20px;
      margin: 8px 0 0;
      @include media-breakpoint(sm) {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .footer-subtitle {
      @include font-medium;
      font-size: 14px;
      line-height: 1.21;
      color: $list-color;
      @include media-breakpoint(sm) {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
