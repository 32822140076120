<template>
  <div class="btn-container">
    <div class="learn-btn" v-if="showCertificateOfCompletion && nextStatus">
      <button
        class="btn secondary cert-button"
        @click="showCertificationOfCompletion"
        :aria-label="!nextStatus ? 'Next' : 'See Certification of Completion'"
      >
        See Certification of Completion
        <em class="icon-arrow-right"></em>
      </button>
    </div>
    <div class="learn-btn">
      <button
        class="btn secondary"
        @click="nextParent"
        :aria-label="!nextStatus ? 'Next' : 'See Final Feedback'"
      >
        {{ !nextStatus ? "Next" : text }}
      </button>
    </div>
    <certification-of-completion-modal></certification-of-completion-modal>
  </div>
</template>
<script>
import certificationOfCompletionModal from "./certificationOfCompletionModal.vue";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
export default {
  name: "lo-buttons",
  props: [
    "nextStatus",
    "text",
    "showCertificateOfCompletion",
    "nextMethod"
  ],
  components: {
    certificationOfCompletionModal
  },
  methods: {
    nextParent() {
      this.nextMethod();
    },
    showCertificationOfCompletion() {
      utilFunctionService.showLoader();
      api
        .getCoC(this.$route.params.id)
        .then( res => {
          this.$modal.show("certificationOfCompletionModal", {
            coc_url: res.data.coc_url,
            course_name: res.data.course_name,
            social_share_id: res.data.social_share_id,
            social_share_link: res.data.social_share_link
          });
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 32px;
}
</style>
