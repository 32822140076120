<template>
  <div>
    <div v-if="showSSOPaymentForm">
      <sso-payment-form
        :course_name="this.course_name"
        :price="this.price"
        :tax_rate="this.tax_rate"
        :taxable="this.taxable"
        :final_price="this.final_price"
        :learner_registration_id="this.learner_registration_id"
        :currency="this.currency"
        @update:parent="showSSOPaymentForm = $event"
        @update:parentb="showDashboard = $event"
      ></sso-payment-form>
    </div>

    <div v-if="showActiveModules">
      <VueSkipTo to="#main" label="Skip to main content" role="complementary" />
      <sidebar></sidebar>
      <main class="container pr-0" role="main" id="main">
        <div
          :class="[
            modules_data.length > 0 ? '' : 'dashboard-no-data',
            'dashboard',
          ]"
        >
          <div class="dashboard-active-content">
            <div class="dashboard-active-head">
              <h1>{{ type | upperCase(true) }} Modules</h1>
              <h2 v-if="modules_data.length > 0 && modules_data.length < 10">
                0{{ modules_data.length }}
              </h2>
              <h2
                v-else-if="modules_data.length > 0 && modules_data.length >= 10"
              >
                {{ modules_data.length }}
              </h2>
            </div>
            <div class="dashboard-active-list">
              <div
                aria-label="Loading"
                class="vld-overlay is-active is-dashboard-page"
                v-if="isLoader"
              >
                <div class="vld-background"></div>
                <div class="vld-icon">
                  <svg
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    stroke="#3dbc9e"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g transform="translate(1 1)" stroke-width="2">
                        <circle
                          stroke-opacity=".25"
                          cx="18"
                          cy="18"
                          r="18"
                        ></circle>
                        <path
                          d="M36 18c0-9.94-8.06-18-18-18"
                          transform="rotate(157.563 18 18)"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="0.8s"
                            repeatCount="indefinite"
                          ></animateTransform>
                        </path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <!-- dashboard active item start  -->
              <div
                class="dashboard-no-text"
                v-if="modules_data.length === 0 && !isLoader"
              >
                <p v-if="type === 'active' || type === 'expired'">
                  You don't have any {{ type }} Modules.
                </p>
                <p v-else>You haven't {{ type }} any Modules.</p>
              </div>
              <div v-if="modules_data.length > 0">
                <carousel
                  class="dashboard-carousel"
                  :margin="0"
                  :autoplay="false"
                  :rewind="false"
                  :nav="true"
                  :responsive="{
                    0: { items: 1 },
                    768: { items: 2 },
                    1024: { items: 3 },
                    1367: { items: 4 },
                  }"
                  :loop="false"
                  :dots="false"
                >
                  <div v-for="item in modules_data" :key="item.id">
                    <div v-if="no_access_us_ids.includes(item.id)">
                      <div class="dashboard-active-item">
                        <div class="dashboard-active-img">
                          <img
                            :src="
                              item.attributes.learn_mod.attributes.photo_url
                                .large_version
                            "
                            v-if="
                              item.attributes.learn_mod.attributes.photo_url
                                .large_version
                            "
                            v-bind:alt="
                              item.attributes.learn_mod.attributes.image_caption
                            "
                          />
                        </div>
                        <div class="dashboard-active-btn" v-if="item.attributes.sequential_24_hour_delay && !has_12_hours_passed(item.attributes.previous_module_time_completed)">
                          Wait 12 Hours For The Module to Open
                          <em class="icon-caret-right"></em>
                        </div>
                        <div class="dashboard-active-btn" v-else>
                          Finish Preceding Module to Access
                          <em class="icon-caret-right"></em>
                        </div>
                        <div class="dashboard-active-overlay"></div>
                        <div class="dashboard-active-content">
                          <div class="dashboard-active-title">
                            {{ item.attributes.learn_mod.attributes.name }}
                          </div>
                          <div
                            class="dashboard-section-number"
                            v-if="item.attributes.section_name && item.attributes.learner_display_section"
                          >
                            {{ item.attributes.section_name }}
                          </div>
                          <div class="dashboard-progress-bar">
                            <div class="progress">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                :style="{
                                  width: [
                                    item.attributes.complete_percentage + '%',
                                  ],
                                }"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :aria-label="
                                  item.attributes.complete_percentage +
                                  '% completed'
                                "
                              ></div>
                            </div>
                            <div class="dashboard-progress-text">
                              {{ item.attributes.completed_ulos_count }}/{{
                                item.attributes.total_count
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="dashboard-badge">
                          <div
                            class="dashboard-badge-item"
                            v-if="item.attributes.learner_messages_count > 0"
                          >
                            <img src="../assets/images/messages-white.svg" />
                            {{ item.attributes.learner_messages_count }}
                          </div>
                          <div
                            class="dashboard-badge-item"
                            v-if="item.attributes.learner_submissions_count > 0"
                          >
                            <img
                              src="../assets/images/submission-white.svg"
                            />{{ item.attributes.learner_submissions_count }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <router-link
                        :to="`/learning/${item.id}`"
                        class="dashboard-active-item"
                      >
                        <div class="dashboard-active-img">
                          <img
                            :src="
                              item.attributes.learn_mod.attributes.photo_url
                                .large_version
                            "
                            v-if="
                              item.attributes.learn_mod.attributes.photo_url
                                .large_version
                            "
                            v-bind:alt="
                              item.attributes.learn_mod.attributes.image_caption
                            "
                          />
                        </div>
                        <div class="dashboard-active-btn">
                          Continue <em class="icon-caret-right"></em>
                        </div>
                        <div class="dashboard-active-overlay"></div>
                        <div class="dashboard-active-content">
                          <div class="dashboard-active-title">
                            {{ item.attributes.learn_mod.attributes.name }}
                          </div>
                          <div
                            class="dashboard-section-number"
                            v-if="item.attributes.section_name && item.attributes.learner_display_section"
                          >
                            {{ item.attributes.section_name }}
                          </div>
                          <div class="dashboard-progress-bar">
                            <div class="progress">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                :style="{
                                  width: [
                                    item.attributes.complete_percentage + '%',
                                  ],
                                }"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :aria-label="
                                  item.attributes.complete_percentage +
                                  '% completed'
                                "
                              ></div>
                            </div>
                            <div class="dashboard-progress-text">
                              {{ item.attributes.completed_ulos_count }}/{{
                                item.attributes.total_count
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="dashboard-badge">
                          <div
                            class="dashboard-badge-item"
                            v-if="item.attributes.learner_messages_count > 0"
                          >
                            <img src="../assets/images/messages-white.svg" />
                            {{ item.attributes.learner_messages_count }}
                          </div>
                          <div
                            class="dashboard-badge-item"
                            v-if="item.attributes.learner_submissions_count > 0"
                          >
                            <img
                              src="../assets/images/submission-white.svg"
                            />{{ item.attributes.learner_submissions_count }}
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import sidebar from "@/components/sidebar.vue";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { mapGetters } from "vuex";
import moment from "moment";
import ssoPaymentForm from "../components/SSOPaymentForm.vue";

export default {
  name: "list-data-component",
  components: {
    sidebar,
    carousel,
    ssoPaymentForm,
  },
  filters: {
    upperCase: function (value, onlyFirstCharacter) {
      if (!value) {
        return "";
      }
      value = value.toString();
      if (value === "file") {
        value = "Pdf";
      }
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    },
    heading: function (value) {
      return value;
    },
  },
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      title: `Ametros Learning -  ${
        this.$route.path.substr(1).charAt(0).toUpperCase() +
        this.$route.path.substr(1).slice(1)
      } Modules`,
      type: "",
      modules_data: [],
      no_access_us_ids: [],
      url: utilFunctionService.getImageUrl(),
      dashBoard: {
        title: "",
        description: "",
        welcome_img: "",
        welcome_text: "",
      },
      display: true,
      isUrl: false,
      activeSprint: 0,
      isLoader: false,
      activeMultiMod: [],
      learnerRegData: [],
      courseData: [],
      course_name: "",
      payment_type: "",
      price: "",
      tax_rate: "",
      already_paid: "",
      currency: "",
      final_price: "",
      showSSOPaymentForm: false,
      showActiveModules: true,
      sso_authenticated: "",
      learner_registration_id: "",
      taxable: "",
      needs_to_pay: false
    };
  },

  methods: {
    has_12_hours_passed(previous_module_time_completed) {
      if (!previous_module_time_completed) return true;
      let previous_module_time_completed_date = new Date(
        previous_module_time_completed
      );
      let current_date = new Date();
      let time_difference =
        current_date.getTime() - previous_module_time_completed_date.getTime();
      let hours_difference = time_difference / (1000 * 60 * 60);
      return hours_difference >= 12;
    },
    scrollHandle(evt) {
      console.log(evt);
    },
    getList(type) {
      this.isLoader = true;
      let searchType = type;
      if (type === "expired") searchType = "active";
      api
        .getList(searchType)
        .then((res) => {
          this.display = true;
          this.modules_data = res.data.data;
          if (this.modules_data.length > 0) {
              let modules_in_courses = this.modules_data.reduce((acc, module) => {
                const courseId = module.attributes.customer_course_id;
                if (!acc[courseId]) {
                  acc[courseId] = [];
                }
                acc[courseId].push(module);
                return acc;
              }, {});

              for (const courseId in modules_in_courses) {
                modules_in_courses[courseId].sort((a, b) => a.attributes.elm_order - b.attributes.elm_order);
                this.getNoAccessIDs(modules_in_courses[courseId]);
              }
              this.modules_data = Object.values(modules_in_courses).flat();
              console.log(`*** this.modules_data  ${this.modules_data}`);
          }
          if (type === "active") {
            this.modules_data = this.modules_data.filter(
              (module) => moment(module.attributes.course_end_date) > moment() 
              || module.attributes.course_end_date === null || module.attributes.course_open_start
            );
          } else if (type === "expired") {
            this.modules_data = this.modules_data.filter(
              (module) => moment(module.attributes.course_end_date) < moment() && !module.attributes.course_open_start
            );
          }
          this.isUrl = true;
          this.isLoader = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            if (this.sso_authenticated) {
              utilFunctionService.showerr(
                "Session has expired."
              );
              this.$store.dispatch("user/logout");
              this.$router.push({ name: "lti-logout" });
            } else {
              utilFunctionService.showerr(
                "Signature has expired.Please login again"
              );
              utilFunctionService.removeLocalStorageService("learner");
              this.$router.push({ name: "login" });
            }
          }
        });
    },
    getNoAccessIDs(modules) {
      console.log("*** hit getNoAccessIDs()");

      const sort_modules_prep = modules.filter(
        (obj) =>
          obj.attributes.multi_mod_course &&
          obj.attributes.sequential_mod_access === true
      );
      console.log(sort_modules_prep, "*** sort_modules_prep");
      let current_first = [];
      if (sort_modules_prep.length === 0) {
        return;
      } else {
        current_first = sort_modules_prep[0].attributes.elm_order;
        if (sort_modules_prep[0].attributes.sequential_24_hour_delay) {
          this.has_12_hours_passed(sort_modules_prep[0].attributes.previous_module_time_completed)
            ? null
            : this.no_access_us_ids.push(sort_modules_prep[0].id);
        }
        const sort_modules = sort_modules_prep.filter(
          (obj) =>
            obj.attributes.completed_ulos_count === 0 &&
            obj.attributes.elm_order > current_first
        );
        console.log(sort_modules, "*** sort_modules");
        sort_modules.forEach((element) => {
          console.log(element, "*** element");

          this.no_access_us_ids.push(element.id);
        });
        this.$store.dispatch("user/setUserSectionAccess", this.no_access_us_ids);
        this.activeMultiMod = sort_modules_prep[0];
      }
    },
    set_sso_visibility() {
      if (this.needs_to_pay == false) {
        this.showSSOPaymentForm = false;
      } else {
        this.showSSOPaymentForm = true;
        this.showActiveModules = false;
      }
    },
  },
  created() {
    //console.log("*** hit created in listData");
    let type = this.$route.path;
    this.isUrl = true;
    this.type = type.substr(1);
    this.activeSprint = this.type;
    this.getList(this.type);

    const user_data = this.userDetails;

    this.sso_authenticated = user_data.attributes.sso_authenticated;
    this.learner_id = user_data.id;
    this.learner_registration_id = user_data.attributes.learner_registration_id;

    if (this.sso_authenticated == true) {
      if (this.learner_registration_id !== null) {
        api
          .getLearnerRegistration(this.learner_registration_id)
          .then((res) => {
            this.learnerRegData = res.data.data;
            this.courseData =
              res.data.data.attributes.customer_course.attributes;
            this.course_name = this.learnerRegData.attributes.course_name;
            this.payment_type = this.courseData.payment_type;
            this.price = parseFloat(this.courseData.price);
            this.tax_rate = parseFloat(this.courseData.tax_rate);
            this.already_paid = this.learnerRegData.attributes.end_user_paid;
            this.currency = this.courseData.currency;
            this.final_price = this.price + this.price * this.tax_rate;
            if (this.payment_type == "end_user" && this.already_paid == false) {
              //console.log("*** hit this.needs_to_pay = true conditional set");
              this.needs_to_pay = true;
            }
            this.set_sso_visibility();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              console.log(error);
            }
          });
      } else {
        this.set_sso_visibility();
      }
      
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.modules_data = [];
        let type = this.$route.path;
        this.type = type.substr(1);
        this.activeSprint = this.type;
        this.getList(this.type);
        this.title =
          this.$route.path.substr(1).charAt(0).toUpperCase() +
          this.$route.path.substr(1).slice(1);
        this.title = `Ametros Learning -  ${
          this.$route.path.substr(1).charAt(0).toUpperCase() +
          this.$route.path.substr(1).slice(1)
        } Modules`;
      }
    },
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
};
</script>
