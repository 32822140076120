<template>
  <div>
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title"><img src="../assets/images/icon-yellownote.svg" alt="" />Note</p>
          <p v-html="notes">{{ notes }}</p>
        </div>
      </div>
    </div>
    <p class="learn-info" v-html="card_data.description"></p>
    <div class="introduction">
      <carousel
        :margin="0"
        :nav="true"
        :navText="[]"
        :autoplay="false"
        :autoHeight="false"
        :items="1"
        :dots="false"
        :loop="true"
      >
        <div v-for="(img, i) in image" :key="img.id">
          <div v-if="captionImage" class="introduction-video-text">
            {{ img.attributes.caption }}
          </div>
          <div class="introduction-video">
            <img
              :src="img.attributes.resource_url.large_version"
              :alt="img.attributes.global_resource.attributes.tag_list"
              class="intro-slide-video"
            />
            <div class="introduction-intro-top">
              <!-- <div v-if="captionImage"
                    class="introduction-intro-title"
                    >{{img.caption}}</div> -->
              <!-- <p class="introduction-intro-caption">-Leonardo da Vinci</p> -->
            </div>
            <div class="introduction-intro-bottom">
              <div class="introduction-intro-count">
                {{ i + 1 }}/{{ image.length }}
              </div>
            </div>
          </div>
          <div class="introduction-progress dashboard-progress-bar">
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{ width: [((i + 1) * 100) / image.length + '%'] }"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-label="[((i + 1) * 100) / image.length + '% completed']"
              ></div>
            </div>
          </div>
        </div>
        <div class="introduction-progress dashboard-progress-bar">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: [((i + 1) * 100) / image.length + '%'] }"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              :aria-label="[((i + 1) * 100) / image.length + '% completed']"
            ></div>
          </div>
        </div>
      </carousel>
      <!-- learn next btn start -->
      <lo-buttons
        :nextMethod="next"
        :nextStatus="nextStatus"
        text="See Final Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
      <!-- learn next btn end -->
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import loButtons from "../components/loButtons.vue";
import { utilFunctionService } from "@/utils/utils.service";
export default {
  name: "slide-component",
  props: ["active_data", "nextStatus"],
  components: {
    carousel,
    loButtons
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      tag: "",
      loId: "",
      card_type: "",
      card_data: {},
      image: [],
      captionImage: false,
      url: utilFunctionService.getImageUrl(),
      sticky_note: [],
      learning_id: "",
    };
  },
  async created() {
    this.tag =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.slider_images[0].attributes.global_resource.attributes.tag_list;
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.loId = this.active_data.attributes.learning_object.id;
    this.image = this.card_data.card_detail.attributes.slider_images;
    this.captionImage = this.card_data.card_detail.attributes.has_caption;
    let attr = this.active_data.attributes;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    let loSections = attr.user_section.attributes.learning_object_sections;
    this.learning_id = attr.learning_object_id;
    for (let i = 0; i < loSections.length; i++) {
      if (loSections[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(loSections[i].attributes.sticky_note);
      }
    }
  },
  methods: {
    next() {
      this.$parent.next();
    },
  },
};
</script>