<template>
  <div
    class="learn-steps"
  >
    <h1 class="learn-title">{{ intros.attributes.name }}</h1>
    <p class="learn-info" v-if="intros.attributes.description != 'null'">
      {{ intros.attributes.description }}
    </p>
    <div
      class="suggestion-container"
      style="display: flex"
      v-if="intros.attributes.intro_documents"
    >
      <div
        class="suggestion-row"
        v-for="item in intros.attributes.intro_documents"
        :key="item.id"
      >
        <div class="suggestion-pdf">
          <button
            class="btn pdf-download"
            @click.prevent="
              downloadPdf(item.attributes.resource_url, 'Introductory_Document')
            "
            aria-label="download PDF"
          >
            <span class="truncate">{{ item.attributes.file_name }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="introduction" v-if="intros.attributes.intro_video != null">
      <div class="wistia_responsive_wrapper">
        <iframe
          :src="
            preIframUrl +
            intros.attributes.intro_video.attributes.wistia_code +
            postIframUrl
          "
          title="Video.mp4"
          class="wistia_embed"
          name="wistia_embed"
          allowtransparency="true"
          scrolling="no"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div
        class="intro-transcript"
        v-if="
          intros.attributes &&
          intros.attributes.intro_video.attributes.transcript
        "
      >
        <p class="transcript-title">Transcript:</p>
        <readmore
          more-str="Read more"
          :text="intros.attributes.intro_video.attributes.transcript"
          link="#"
          less-str="Read less"
          :max-chars="100"
        ></readmore>
      </div>
    </div>
    <div class="learn-btn">
      <button
        class="btn secondary"
        @click="getActive(0)"
        tabindex="0"
        :aria-label="
          menus[menus.length - 1].attributes.complete
            ? 'View Module'
            : 'Start Module'
        "
      >
        {{
          menus[menus.length - 1].attributes.complete
            ? "View Module"
            : "Start Module"
        }}
        <em class="icon-arrow-right"></em>
      </button>
    </div>
  </div>
</template>
<script>
import readmore from "../components/readMore.vue";
import axios from "axios";
export default {
  name: "learningIntro",
  components: {
    readmore
  },
  props: ["intros", "menus"],
  data() {
    return {
			preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
		};
  },
  methods: {
    getActive(menuIndex) {
			this.$parent.getActive(menuIndex);
		},
    downloadPdf(link, name) {
      axios.get(link, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", name);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
	}
};
</script>
