<template>
<div id="unauthorized" class="unauthorized-page">
    <img src="../assets/images/ametros-learning-logo.png" alt="Ametros Learning Logo" class="logo" />
        <div class="unauthorized-form mt-2">
            <em class="icon-cross icn"></em>
            <h4 class="mt-4">Access Denied</h4>
            <p class="mt-4">You are not authorized to access this resource. 
                You might not have the permission to view the contents in the requested page.</p>
        </div>
</div>
</template>
<script>
export default {
  name: "Unauthorized-component" 
};
</script>
<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.unauthorized-page {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 80vh;
  @include media-breakpoint(sm) {
     background-color: $theme-light-background;
  }
  .logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .icn {
      color: red;
      font-size: 1.73em;
  }
   .unauthorized-form {
    text-align: center;
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
}
</style>


