<template>
  <div>
    <modal
      name="emailIterationInfo"
      role="dialog"
      aria-label="emailIterationInfo"
      width="560"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-info-content">
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('emailIterationInfo')"
          >
            <em class="icon-cross"></em>
          </button>
          <div class="modal-body" v-if="emailFeedbackResponse.attributes">
            <h3>What is an iteration?</h3>
            {{ emailFeedbackResponse.attributes.iteration_explanation }}
          </div>
        </div>
      </FocusLoop>
    </modal>
  </div>
</template>

<script>

export default {
  name: "EmailIterationInfoModal",
  data() {
    return {
        emailFeedbackResponse: {
          attributes: {
            iteration_explanation: ""
          }
        },
        infoModalValues: {},
        isOpen: false
    }
  },
  methods: {
    beforeOpen(event) {
      this.emailFeedbackResponse = event.params.emailFeedbackResponse
      this.isOpen = true;
    }
  },
};
</script>