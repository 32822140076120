<template>
  <div>
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title">
            <img src="../assets/images/icon-yellownote.svg" alt="" />Note
          </p>
          <p v-html="notes">{{ notes }}</p>
        </div>
      </div>
    </div>
    <p class="learn-info" v-html="card_data.description"></p>
    <p
      class="learn-info"
      v-html="card_data.card_detail.attributes.description"
    ></p>
    <div class="introduction">
      <lo-buttons
        :nextMethod="next"
        :nextStatus="nextStatus"
        text="See Final Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
    </div>
  </div>
</template>
<script>
import loButtons from "../components/loButtons.vue"
export default {
  name: "richText",
  props: ["active_data", "nextStatus"],
  components: {
    loButtons
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      loId: "",
      card_type: "",
      card_data: {},
      sticky_note: [],
      learning_id: "",
    };
  },
  created() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    console.log(this.card_data);
    this.loId = this.active_data.attributes.learning_object.id;
    let attr = this.active_data.attributes;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    let sectLo = attr.user_section.attributes.learning_object_sections;
    this.learning_id = attr.learning_object_id;
    for (let i = 0; i < sectLo.length; i++) {
      if (sectLo[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(sectLo[i].attributes.sticky_note);
      }
    }
  },
  methods: {
    next() {
      this.$parent.next();
    },
  },
};
</script>