<template>
  <div>
    <modal
      name="emailNoPlotPointFeedback"
      aria-label="emailNoPlotPointFeedback"
      role="dialog"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div
          class="modal-content modal-feedback-content modal-score-content"
          v-if="emailFeedbackResponse"
        >
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('emailNoPlotPointFeedback')"
          >
            <em class="icon-cross"></em>
          </button>
          <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
            <div class="modal-body">
              <div class="d-flex flex-wrap w-100 justify-content-between">
                <h1>Feedback</h1>
               <!-- <div class="d-flex">
                  <p>
                    Number of iterations:{{
                      emailFeedbackResponse.attributes.iteration
                    }}
                  </p>
                  <button
                    @click="
                      $modal.show('emailIterationInfo', {
                        emailFeedbackResponse: emailFeedbackResponse,
                      })
                    "
                    class="feedback-info"
                  >
                    i
                    <span class="visually-hidden">Show email iteration info modal</span>
                  </button>
                </div>-->
              </div>
              
              <div
                class="modal-score-strip mb-4"
                v-if="
                  overallAssmntItem &&
                  overallAssmntItem.display_feedback &&
                  emailFeedbackResponse.attributes.display_overall_assessment
                "
              >
                <span class="modal-score-icon" v-if="!hideAssessmentLabel">
                  <img
                    v-if="overallAssmntItem.assessment_icon_url"
                    :src="overallAssmntItem.assessment_icon_url"
                    :alt="overallAssmntItem.assessment_label"
                  />
                  {{ overallAssmntItem.assessment_label }}
                </span>
                <p v-html="overallAssmntItem.feedback"></p>
              </div>

              <div
                class="feedback-items mastery"
                v-for="values in emailFeedbackResponse.attributes
                  .learner_debrief_items"
                :key="values.id"
              >
                <div
                  class="feedback-icon"
                  v-if="!emailFeedbackResponse.attributes.hide_assessment_label"
                >
                  <img
                    :src="
                      values.attributes.email_assessment_item.attributes
                        .assessment_icon_url
                    "
                    alt=""
                  />
                </div>
                <div class="feedback-content">
                  <div
                    class="feedback-head"
                    v-if="!emailFeedbackResponse.attributes.hide_assessment_label"
                  >
                    {{
                      values.attributes.email_assessment_item.attributes
                        .assessment_label
                    }}
                  </div>
                  <div class="d-flex">
                    <h2>
                      {{ values.attributes.global_skill.name }}
                    </h2>
                    <button
                      @click="
                        $modal.show('emailFeedbackInfo', {
                          skillName: values.attributes.global_skill.name,
                          infoModalValues: values
                        })
                      "
                      class="feedback-info"
                    >
                      i
                      <span class="visually-hidden">Show email feedback info modal</span>
                    </button>
                  </div>
                  <div
                    class="emailBody"
                    v-html="values.attributes.debrief_content"
                  ></div>
                  <div
                    class="col-12 px-0"
                    v-for="item in values.attributes.email_assessment_item
                      .attributes.adaptive_contents"
                    :key="item.id"
                  >
                    <!-- suggestion container slide  -->
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'slide_learn_obj'
                      "
                    >
                      <h3>Suggested Content</h3>
                      <div class="suggestion-row">
                        <div class="suggestion-slide">
                          <a
                            @click="
                              $modal.show('suggestedContentModal', {
                                data: item.attributes.content_details.attributes
                                  .slider_images,
                                type: 'slide',
                                title: item.attributes,
                              })
                            "
                          >
                            <img
                              :src="
                                item.attributes.content_details.attributes
                                  .slider_images[0].attributes.resource_url
                                  .thumbnail
                              "
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'text_learn_obj'
                      "
                    >
                      <h3>Suggested Content</h3>
                      <div class="suggestion-row">
                        <div
                          class="suggestion-slide"
                          v-html="
                            item.attributes.content_details.attributes.title
                          "
                        ></div>
                      </div>
                    </div>
                    <!-- suggestion container pdf  -->
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'file_learn_obj'
                      "
                    >
                      <h3>Suggested Content(file)</h3>
                      <div class="suggestion-row">
                        <div class="suggestion-pdf">
                          <a
                            class="btn pdf-download"
                            :href="
                              item.attributes.content_details.attributes
                                .resource_url
                            "
                            @click.prevent="
                              downloadPdf(
                                item.attributes.content_details.attributes
                                  .resource_url,
                                'Suggested_Content'
                              )
                            "
                          >
                            <span class="truncate">{{
                              item.attributes.content_details.attributes
                                .resource_file_name
                            }}</span>
                          </a>
                          <!-- <a href="#"><span class="truncate">Business</span><span>.doc</span></a> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'video_learn_obj'
                      "
                    >
                      <h3>Suggested Content(video)</h3>
                      <div class="suggestion-row">
                        <div class="suggestion-video">
                          <a
                            @click="
                              $modal.show('suggestedContentModal', {
                                data: item.attributes.content_details.attributes
                                  .global_video.attributes.wistia_code,
                                type: 'video',
                                title: item.attributes,
                              })
                            "
                          >
                            <img
                              :src="
                                item.attributes.content_details.attributes
                                  .global_video.attributes.wistia_thumbnail_url
                              "
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- feedback video sendtart -->
                </div>
              </div>

              <div class="reattempts-wrapper">
                <div class="reattempts-info" v-if="!course_lo_reattempts_disabled && lo_reattempts_enabled && (lo_reattempts_max - lo_reattempt_number > 0) && user_section_data.complete_percentage < 100">
                  <p>Do you want to try this interaction again? You can reset this interaction <strong>{{ lo_reattempts_max - lo_reattempt_number }}</strong> more time<span v-if="lo_reattempts_max - lo_reattempt_number > 1">s</span>.</p> 
                  <p>Click "Try again" now if you'd like to apply the feedback you received or see what happens when you submit a different message.</p>

                  <div class="two-buttons">
                    <button class="btn secondary" @click="retryInteraction()">Try again</button>
  
                    <button class="btn secondary" @click="next">
                      {{ !nextStatus ? "Next" : "Go To Dashboard" }}
                      <em class="icon-arrow-right"></em>
                    </button>
                  </div>
                </div>
                <!--<div class="reattempts-info" v-if="!course_lo_reattempts_disabled && lo_reattempts_enabled && (lo_reattempts_max - lo_reattempt_number === 0)">
                  <p>You have used <strong>{{ lo_reattempt_number }}</strong> retry attempts.</p>
                  <p>You have used all of your retry attempts.</p>
                </div>-->
              </div>

              <div class="modal-btn" v-if="(!course_lo_reattempts_disabled && lo_reattempts_enabled && (lo_reattempts_max - lo_reattempt_number == 0)) || !lo_reattempts_enabled || user_section_data.complete_percentage >= 100">
                <div class="learn-btn">
                  <button class="btn secondary" @click="next">
                    {{ !nextStatus ? "Next" : "Go To Dashboard" }}
                    <em class="icon-arrow-right"></em>
                  </button>
                </div>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
    <email-iteration-info-modal></email-iteration-info-modal>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import emailIterationInfoModal from './emailIterationInfoModal.vue';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import api from "../services/api";
export default {
  components: { 
    emailIterationInfoModal,
    VuePerfectScrollbar,
  },
  name: "EmailFeedbackModal",
  data() {
    return {
      emailFeedbackResponse: {
        attributes: {
          iteration: 0
        }
      },
      settings: {
        maxScrollbarLength: 30,
      },
      overallAssmntItem: {},
      hideAssessmentLabel: false,
      nextStatus: "",
      isOpen: false,
      course_lo_reattempts_disabled: '',
      lo_reattempts_enabled: '',
      lo_reattempts_max: '',
      lo_reattempt_number: '',
      module_id: '',
      active_data_id: '', 
      user_section_data: {}
    };
  },
  methods: {
    beforeOpen(event) {
      this.emailFeedbackResponse = event.params.emailFeedbackResponse;
      this.overallAssmntItem = event.params.overallAssmntItem;
      this.hideAssessmentLabel = event.params.hideAssessmentLabel;
      this.nextStatus = event.params.nextStatus;
      this.course_lo_reattempts_disabled = event.params.course_lo_reattempts_disabled;
      this.lo_reattempts_enabled = event.params.lo_reattempts_enabled;
      this.lo_reattempts_max = event.params.lo_reattempts_max;
      this.lo_reattempt_number = event.params.lo_reattempt_number;
      this.module_id = event.params.module_id;
      this.active_data_id = event.params.active_data_id;
      this.user_section_data = this.$parent.active_data.attributes.user_section.attributes;
      // this.retries = event.params.retries;
      this.isOpen = true;
    },
    downloadPdf(link, name) {
      axios.get(link, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", name);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    modalInfoModal(feedback, name, values) {
      this.skillName = name;
      this.infoModalValues = values;
      this.show(feedback);
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    next(type){
      this.$parent.next(type);
    },
    retryInteraction() {
      this.$modal.show("dialog", {
        title: "Are you sure you would like to try again? Please note that any work submitted in your previous attempt at this interaction will not be saved. Your assessment on previous attempts will still be available in your final feedback.",
        text: "",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Yes I'm sure",
            handler: () => {
              utilFunctionService.showLoader();
              api.retryInteraction(this.module_id, this.active_data_id).then(() => {
                utilFunctionService.hideLoader();
                location.reload();
              }).catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showerr("An error has occurred. Please contact Ametros support for assistance.")
              })
            },
          },
        ]
      });
      // api.retryInteraction().then(() => {
      //   utilFunctionService.hideLoader();
      //   location.reload();
      // }).catch(() => {
      //   utilFunctionService.hideLoader();
      //   utilFunctionService.showerr("An error has occurred. Please contact Ametros support for assistance.")
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
.writ-comprehension {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
  .writ-comprehension-rubric {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .rubric-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      .rubric-item__title {
        margin-right: 32px;
        h5, h6 {
          font-weight: bold;
        }
      }
    }
  }
}
.modal-feedback-content {
  h1 {
    font-family: 'muliblack', sans-serif;
    font-size: 25px;
    width: 100%;
    line-height: normal;
  }
  h2 {
    font-family: 'muliblack', sans-serif;
    font-size: 24px;
    width: 100%;
    line-height: normal;
  }
}

.feedback-head {
  color: #000000;
}


.suggestion-container h3 {
  font-family: 'mulibold', sans-serif;
  padding: 14px 0 0;
  font-size: 13px;
  color: #000;
}
</style>