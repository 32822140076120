<template>
  <modal
    name="certificationOfCompletionModal"
    role="dialog"
    aria-label="certificationOfCompletion"
		class="modal-fullScreen"
    width="560"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <FocusLoop :is-visible="isOpen">
      <div class="modal-content modal-final-content modal-dialogic-content">
        <button
          type="button"
          class="modal-close"
          aria-label="Close Modal"
          @click="$modal.hide('certificationOfCompletionModal')"
        >
          <em class="icon-cross"></em>
        </button>
        <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
          <div class="modal-body CoC-body m-auto">
            <h1>Certification of Completion</h1>
            <div class="CoC-container">
              <img
                :src="CoCUrl"
              />
            </div>
            <div class="CoC-toolbar">
              <button
                @click="downloadCoC()"
                class="btn"
              >
                Download Certificate
              </button>
              <div class="CoC-social">
                <p>Share Certificate on:</p>
                <ShareNetwork
                    network="linkedin"
                    :url="socialShareURL"
                    class="btn"
                  >
                  <img src="../assets/social-icons/linkedin.svg">
                  <span>Share on LinkedIn</span>
                </ShareNetwork>
                <ShareNetwork
                    network="twitter"
                    :url="socialShareURL"
                    :title="shareTitle"
                    class="btn"
                  >
                  <img src="../assets/social-icons/twitter.svg">
                  <span>Share on Twitter</span>
                </ShareNetwork>
                <ShareNetwork
                    network="facebook"
                    :url="socialShareURL"
                    :title="shareTitle"
                    class="btn"
                  >
                  <img src="../assets/social-icons/facebook.svg">
                  <span>Share on Facebook</span>
                </ShareNetwork>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </FocusLoop>
  </modal>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import axios from "axios";
export default {
  name: "certificationOfCompletionModal",
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      isOpen: false,
      settings: {
        maxScrollbarLength: 30,
      },
      CoCUrl: "",
      shareTitle: "",
      socialShareURL: ""
    };
  },
  methods: {
    beforeOpen(event) {
      // const SOCIAL_SHARE_URL = "https://aelp-stage-social-share.herokuapp.com/"
      this.CoCUrl = event.params.coc_url;
      this.socialShareURL = `${event.params.social_share_link}`
      this.shareTitle = `I completed ${event.params.course_name} at Ametros Learning`;
      this.isOpen = true;
      utilFunctionService.hideLoader();
    },
    downloadCoC() {
      utilFunctionService.showSuccess("Downloading Certificate of Completion");
      utilFunctionService.showLoader();
      if (!this.CoCUrl) {
        api
          .downloadCertificateOfCompletion(this.$route.params.id)
          .then((res) => {
            if (res && res.data) {
              this.CoCUrl = res.data.coc_url;
              utilFunctionService.hideLoader();
            }
          })
          .then(() => {
            axios
              .get(this.CoCUrl, { responseType: "blob" })
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "image/png",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", "certificate_of_completion.png");
                link.click();
                URL.revokeObjectURL(link.href);
                utilFunctionService.hideLoader();
              })
              .catch((err) => {
                utilFunctionService.showerr(
                  `The following error occurred: ${err}. Please reach out to Ametros support.`
                );
                utilFunctionService.hideLoader();
              });
          });
      } else {
        axios
          .get(this.CoCUrl, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "image/png",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "certificate_of_completion.png");
            link.click();
            URL.revokeObjectURL(link.href);
            utilFunctionService.hideLoader();
          })
          .catch((err) => {
            utilFunctionService.showErr(
              `The following error occurred: ${err}. Please reach out to Ametros support.`
            );
            utilFunctionService.hideLoader();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CoC-body {
	h1 {
		font-weight: bold;
    font-size: 35px;
	}
  .CoC-container {
    padding: 64px 0;
    width: 100%;
    img { 
      width: 100%;
    }
  }
  .CoC-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .CoC-social {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      .btn {
        border: 1px solid black;
        line-height: 0;
        img {
          width: 15px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>