<template>
  <div>
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <p class="learn-info" v-html="card_data.description"></p>
    <div class="learn-docs-pdf" v-if="showPDF">
      <PdfEmbedded
        :pdfUrl="card_data.card_detail.attributes.resource_url"
        :fileName="card_data.card_detail.attributes.resource_file_name"
      />
    </div>
    <div class="introduction">
      <lo-buttons
        :nextMethod="next"
        :nextStatus="nextStatus"
        text="See Final Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
    </div>
  </div>
</template>
<script>
import PdfEmbedded from "../components/embeddedPdf.vue";
import loButtons from "../components/loButtons.vue";
export default {
  name: "pdf-component",
  components: {
    PdfEmbedded,
    loButtons
  },
  props: ["active_data", "nextStatus"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      loId: "",
      card_type: "",
      card_data: {},
      showPDF: false
    };
  },
  mounted() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.loId = this.active_data.attributes.learning_object.id;
    // Temporary fix to address pdf component rendering issue
    setTimeout(() => {
      this.showPDF = true
    }, 100)
  },
  methods: {
    next() {
      this.$parent.next();
    },
  },
};
</script>
