var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn-sidebar sidebar-border",attrs:{"role":"navigation"}},[_c('div',{ref:"learnSidebarItems",staticClass:"learn-sidebar-items"},[(_vm.menus.length > 0)?_c('div',{staticClass:"learn-sidebar-left"},[(_vm.user_roles.includes('testing-internal'))?_c('ul',{attrs:{"tabindex":"0"}},[(_vm.enableIntegrity)?_c('li',{staticClass:"learn-sidebar-head learn-sidebar-item item-complete",on:{"click":function($event){return _vm.getActive('I')}}},[_vm._m(0),_c('div',{class:[
              'learn-sidebar-intro',
              _vm.menus.length === 0 ||
              (!_vm.card_type && _vm.intros && _vm.intros.attributes && _vm.showIntegrity)
                ? 'active'
                : '',
            ]},[_c('div',{staticClass:"learn-sidebar-icon"}),_vm._v(" Academic Integrity ")])]):_vm._e(),_c('li',{staticClass:"learn-sidebar-head learn-sidebar-item item-complete",on:{"click":function($event){return _vm.getActive('')}}},[_vm._m(1),_c('div',{class:[
              'learn-sidebar-intro',
              _vm.menus.length === 0 ||
              (_vm.showIntro && !_vm.card_type && _vm.intros && _vm.intros.attributes)
                ? 'active'
                : '',
            ]},[_c('div',{staticClass:"learn-sidebar-icon"}),_vm._v(" Introduction ")])]),_vm._l((_vm.menus),function(menu,i){return _c('li',{key:menu.id,class:[
            'learn-sidebar-item',
            menu.attributes.complete
              ? _vm.active_data.id === menu.id
                ? 'item-completed'
                : 'item-complete'
              : _vm.active_data.id === menu.id
              ? 'item-progress'
              : '',
          ]},[_c('div',{staticClass:"learn-sidebar-icon"}),_c('button',{class:[
              'learn-sidebar-content',
              i === 0 ||
              _vm.menus[i > 0 ? i - 1 : 0].attributes.complete ||
              _vm.menus[i].attributes.complete
                ? 'cursor'
                : 'cursor',
            ],on:{"click":function($event){return _vm.getActive(i)}}},[(menu.completed_by)?_c('div',{staticClass:"learn-sidebar-milestone"},[_c('h4',[_vm._v("Milestone "+_vm._s(menu.milestoneIndex))]),_c('p',[_vm._v(_vm._s(menu.completed_by))])]):_vm._e(),(i < 9)?_c('div',{staticClass:"learn-sidebar-number"},[_vm._v("0"+_vm._s(i + 1))]):_vm._e(),(i >= 9)?_c('div',{staticClass:"learn-sidebar-number"},[_vm._v(_vm._s(i + 1))]):_vm._e(),(menu.attributes.complete)?_c('div',{staticClass:"learn-sidebar-status"},[_vm._v(" COMPLETED "),_c('em',{staticClass:"icon-caret-right"})]):_vm._e(),(
                !menu.attributes.complete &&
                _vm.active_data &&
                _vm.active_data.id === menu.id
              )?_c('div',{staticClass:"learn-sidebar-status"},[_vm._v(" IN PROGRESS "),_c('em',{staticClass:"icon-caret-right"})]):_vm._e(),_c('div',{staticClass:"learn-sidebar-title"},[_vm._v(" "+_vm._s(menu.attributes.learning_object.attributes.name)+" ")])])])})],2):_vm._e(),(!_vm.user_roles.includes('testing-internal'))?_c('ul',{attrs:{"tabindex":"0"}},[(_vm.enableIntegrity)?_c('li',{staticClass:"learn-sidebar-head learn-sidebar-item item-complete",on:{"click":function($event){return _vm.getActive('I')}}},[_vm._m(2),_c('div',{class:[
              'learn-sidebar-intro',
              _vm.menus.length === 0 ||
              (!_vm.card_type && _vm.intros && _vm.intros.attributes && _vm.showIntegrity)
                ? 'active'
                : '',
            ]},[_c('div',{staticClass:"learn-sidebar-icon"}),_vm._v(" Academic Integrity ")])]):_vm._e(),_c('li',{staticClass:"learn-sidebar-head learn-sidebar-item item-complete",on:{"click":function($event){return _vm.getActive('')}}},[_vm._m(3),_c('div',{class:[
              'learn-sidebar-intro',
              _vm.menus.length === 0 ||
              (_vm.showIntro && !_vm.card_type && _vm.intros && _vm.intros.attributes)
                ? 'active'
                : '',
            ]},[_c('div',{staticClass:"learn-sidebar-icon"}),_vm._v(" Introduction ")])]),_vm._l((_vm.menus),function(menu,i){return _c('li',{key:menu.id,class:[
            'learn-sidebar-item',
            menu.attributes.complete
              ? _vm.active_data.id === menu.id
                ? 'item-completed'
                : 'item-complete'
              : _vm.active_data.id === menu.id
              ? 'item-progress'
              : '',
          ],attrs:{"id":[
            menu.attributes.complete
              ? _vm.active_data.id === menu.id
                ? 'currentActive'
                : ''
              : _vm.active_data.id === menu.id
              ? 'currentActive'
              : '',
          ]}},[_c('button',{on:{"click":function($event){i === 0 ||
              _vm.menus[i > 0 ? i - 1 : 0].attributes.complete ||
              _vm.menus[i].attributes.complete
                ? _vm.getActive(i)
                : ''}}},[_c('div',{staticClass:"learn-sidebar-icon"}),_c('div',{class:[
                'learn-sidebar-content',
                i === 0 ||
                _vm.menus[i > 0 ? i - 1 : 0].attributes.complete ||
                _vm.menus[i].attributes.complete
                  ? 'cursor'
                  : '',
              ]},[(menu.completed_by)?_c('div',{staticClass:"learn-sidebar-milestone"},[_c('h4',[_vm._v("Milestone "+_vm._s(menu.milestoneIndex))]),_c('p',[_vm._v(_vm._s(menu.completed_by))])]):_vm._e(),(i < 9)?_c('div',{staticClass:"learn-sidebar-number"},[_vm._v("0"+_vm._s(i + 1))]):_vm._e(),(i >= 9)?_c('div',{staticClass:"learn-sidebar-number"},[_vm._v(_vm._s(i + 1))]):_vm._e(),(menu.attributes.complete)?_c('div',{staticClass:"learn-sidebar-status"},[_vm._v(" Completed "),_c('em',{staticClass:"icon-caret-right"})]):_vm._e(),(
                  !menu.attributes.complete &&
                  _vm.active_data &&
                  _vm.active_data.id === menu.id
                )?_c('div',{staticClass:"learn-sidebar-status"},[_vm._v(" IN PROGRESS "),_c('em',{staticClass:"icon-caret-right"})]):_vm._e(),_c('div',{staticClass:"learn-sidebar-title"},[_vm._v(" "+_vm._s(menu.attributes.learning_object.attributes.name)+" ")])]),_c('div',{staticClass:"learn-sidebar-badge"},[(menu.attributes.learner_submissions_count > 0)?_c('div',{staticClass:"learn-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/badge-message-square.svg"),"alt":""}})]):_vm._e(),(menu.attributes.learner_messages_count > 0)?_c('div',{staticClass:"learn-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/badge-message-round.svg"),"alt":""}})]):_vm._e()])])])})],2):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mobile-menu mobile-menu-close",on:{"click":function($event){return _vm.toggleMenu()}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 34 34"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('rect',{attrs:{"width":"34","height":"34","fill":"#000000","rx":"17"}}),_c('path',{attrs:{"fill":"#FFF","fill-rule":"nonzero","stroke":"#FFF","d":"M18.322 17l4.486-4.487c.124-.124.192-.288.192-.464 0-.176-.068-.341-.192-.464l-.393-.393c-.123-.124-.288-.192-.464-.192-.176 0-.34.068-.464.192L17 15.678l-4.487-4.486c-.123-.124-.288-.192-.464-.192-.175 0-.34.068-.464.192l-.393.393c-.256.256-.256.672 0 .928L15.679 17l-4.487 4.487c-.124.124-.192.288-.192.464 0 .176.068.34.192.464l.393.393c.124.124.289.192.464.192.176 0 .34-.068.464-.192L17 18.321l4.487 4.487c.123.124.288.192.464.192h0c.176 0 .34-.068.464-.192l.393-.393c.124-.123.192-.288.192-.464 0-.176-.068-.34-.192-.464L18.322 17z"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn-sidebar-img"},[_c('img',{attrs:{"src":require("../assets/images/image-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn-sidebar-img"},[_c('img',{attrs:{"src":require("../assets/images/image-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn-sidebar-img"},[_c('img',{attrs:{"src":require("../assets/images/image-1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"learn-sidebar-img"},[_c('img',{attrs:{"src":require("../assets/images/image-1.png"),"alt":""}})])
}]

export { render, staticRenderFns }