<template>
  <div class="modal-title">
    <div class="final-feedback-title">
      <h1>Final Feedback</h1>
      <h1>{{ headerInfo.name }}</h1>
    </div>
    <div class="grading">
      <div v-if="!this.disableReportBtn">
        <h1
          v-if="
            (enableGrading && overallGradeDisplayType === 'percentage') ||
            (enableGrading && overallGradeDisplayType === 'both')
          "
        >
          {{ overallGradePercentage }}%
        </h1>
        <p
          v-if="
            (enableGrading && overallGradeDisplayType === 'labels') ||
            (enableGrading && overallGradeDisplayType === 'both')
          "
          class="grading-p"
        >
          {{ overallGradeLabel }}
        </p>
      </div>
      <button
        class="btn btn-ametros download-report"
        @click="downloadFinalEvaluationPDF(pdfURL)"
        :disabled="this.disableReportBtn"
      >
        {{ updatedReport ? "Download Updated Report" : "Download Report" }}
      </button>
      <p v-if="this.disableReportBtn" class="grading-progress">
        Grading in progress. Report will be available for download when grading
        is complete.
      </p>
      <button
        v-if="!this.disableReportBtn"
        class="btn regen-report"
        @click.prevent="regenerateReport(pdfURL)"
      >
        Regenerate Report
      </button>
      <p class="desc" v-html="headerInfo.final_debrief_overview"></p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { utilFunctionService } from "@/utils/utils.service";
import api from "../../services/api";
export default {
  name: "finalFeedbackHeader",
  props: {
    headerInfo: Object,
    disableReportBtn: Boolean,
    enableGrading: Boolean,
    overallGradeDisplayType: String,
    overallGradeLabel: String,
    overallGradePercentage: Number,
    pdfURL: String,
  },
  data() {
    return {
      id: this.$route.params.id,
      downloadPdfURL: this.pdfURL,
      updatedReport: false,
    };
  },
  methods: {
    downloadFinalEvaluationPDF() {
      utilFunctionService.showSuccess("Downloading Final Evaluation PDF");
      utilFunctionService.showLoader();
      if (!this.downloadPdfURL) {
        api
          .finalEvaluationPDF(this.id)
          .then((res) => {
            if (res && res.data) {
              this.downloadPdfURL = res.data.evaluation_url;
              utilFunctionService.hideLoader();
            }
          })
          .then(() => {
            axios
              .get(this.downloadPdfURL, { responseType: "blob" })
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", "Ametros-report.pdf");
                link.click();
                URL.revokeObjectURL(link.href);
                this.updatedReport = true;
                utilFunctionService.hideLoader();
              })
              .catch((err) => {
                utilFunctionService.showerr(
                  `The following error occurred: ${err}. Please reach out to Ametros support.`
                );
                utilFunctionService.hideLoader();
              });
          });
      } else {
        axios
          .get(this.downloadPdfURL, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/pdf",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "Ametros-report.pdf");
            link.click();
            URL.revokeObjectURL(link.href);
            utilFunctionService.hideLoader();
          })
          .catch((err) => {
            utilFunctionService.showErr(
              `The following error occurred: ${err}. Please reach out to Ametros support.`
            );
            utilFunctionService.hideLoader();
          });
      }
    },
    regenerateReport() {
      utilFunctionService.showSuccess(
        "Final Evaluation PDF regeneration in process"
      );
      utilFunctionService.showLoader();
      api
        .finalEvaluationPDF(this.id)
        .then((res) => {
          if (res && res.data) {
            this.downloadPdfURL = res.data.evaluation_url;
            utilFunctionService.hideLoader();
          }
        })
        .then(() => {
          axios
            .get(this.downloadPdfURL, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.setAttribute("download", "Ametros-report.pdf");
              link.click();
              URL.revokeObjectURL(link.href);
              this.updatedReport = true;
              utilFunctionService.hideLoader();
            })
            .catch((err) => {
              utilFunctionService.showErr(
                `The following error occurred: ${err}. Please reach out to Ametros support.`
              );
              utilFunctionService.hideLoader();
            });
        });
    },
  },
};
</script>

<style>
</style>

