var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showSSOPaymentForm)?_c('div',[_c('sso-payment-form',{attrs:{"course_name":this.course_name,"price":this.price,"tax_rate":this.tax_rate,"taxable":this.taxable,"final_price":this.final_price,"learner_registration_id":this.learner_registration_id,"currency":this.currency},on:{"update:parent":function($event){_vm.showSSOPaymentForm = $event},"update:parentb":function($event){_vm.showDashboard = $event}}})],1):_vm._e(),(_vm.showActiveModules)?_c('div',[_c('VueSkipTo',{attrs:{"to":"#main","label":"Skip to main content","role":"complementary"}}),_c('sidebar'),_c('main',{staticClass:"container pr-0",attrs:{"role":"main","id":"main"}},[_c('div',{class:[
          _vm.modules_data.length > 0 ? '' : 'dashboard-no-data',
          'dashboard',
        ]},[_c('div',{staticClass:"dashboard-active-content"},[_c('div',{staticClass:"dashboard-active-head"},[_c('h1',[_vm._v(_vm._s(_vm._f("upperCase")(_vm.type,true))+" Modules")]),(_vm.modules_data.length > 0 && _vm.modules_data.length < 10)?_c('h2',[_vm._v(" 0"+_vm._s(_vm.modules_data.length)+" ")]):(_vm.modules_data.length > 0 && _vm.modules_data.length >= 10)?_c('h2',[_vm._v(" "+_vm._s(_vm.modules_data.length)+" ")]):_vm._e()]),_c('div',{staticClass:"dashboard-active-list"},[(_vm.isLoader)?_c('div',{staticClass:"vld-overlay is-active is-dashboard-page",attrs:{"aria-label":"Loading"}},[_c('div',{staticClass:"vld-background"}),_c('div',{staticClass:"vld-icon"},[_c('svg',{attrs:{"viewBox":"0 0 38 38","xmlns":"http://www.w3.org/2000/svg","width":"40","height":"40","stroke":"#3dbc9e"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('g',{attrs:{"transform":"translate(1 1)","stroke-width":"2"}},[_c('circle',{attrs:{"stroke-opacity":".25","cx":"18","cy":"18","r":"18"}}),_c('path',{attrs:{"d":"M36 18c0-9.94-8.06-18-18-18","transform":"rotate(157.563 18 18)"}},[_c('animateTransform',{attrs:{"attributeName":"transform","type":"rotate","from":"0 18 18","to":"360 18 18","dur":"0.8s","repeatCount":"indefinite"}})],1)])])])])]):_vm._e(),(_vm.modules_data.length === 0 && !_vm.isLoader)?_c('div',{staticClass:"dashboard-no-text"},[(_vm.type === 'active' || _vm.type === 'expired')?_c('p',[_vm._v(" You don't have any "+_vm._s(_vm.type)+" Modules. ")]):_c('p',[_vm._v("You haven't "+_vm._s(_vm.type)+" any Modules.")])]):_vm._e(),(_vm.modules_data.length > 0)?_c('div',[_c('carousel',{staticClass:"dashboard-carousel",attrs:{"margin":0,"autoplay":false,"rewind":false,"nav":true,"responsive":{
                  0: { items: 1 },
                  768: { items: 2 },
                  1024: { items: 3 },
                  1367: { items: 4 },
                },"loop":false,"dots":false}},_vm._l((_vm.modules_data),function(item){return _c('div',{key:item.id},[(_vm.no_access_us_ids.includes(item.id))?_c('div',[_c('div',{staticClass:"dashboard-active-item"},[_c('div',{staticClass:"dashboard-active-img"},[(
                            item.attributes.learn_mod.attributes.photo_url
                              .large_version
                          )?_c('img',{attrs:{"src":item.attributes.learn_mod.attributes.photo_url
                              .large_version,"alt":item.attributes.learn_mod.attributes.image_caption}}):_vm._e()]),(item.attributes.sequential_24_hour_delay && !_vm.has_12_hours_passed(item.attributes.previous_module_time_completed))?_c('div',{staticClass:"dashboard-active-btn"},[_vm._v(" Wait 12 Hours For The Module to Open "),_c('em',{staticClass:"icon-caret-right"})]):_c('div',{staticClass:"dashboard-active-btn"},[_vm._v(" Finish Preceding Module to Access "),_c('em',{staticClass:"icon-caret-right"})]),_c('div',{staticClass:"dashboard-active-overlay"}),_c('div',{staticClass:"dashboard-active-content"},[_c('div',{staticClass:"dashboard-active-title"},[_vm._v(" "+_vm._s(item.attributes.learn_mod.attributes.name)+" ")]),(item.attributes.section_name && item.attributes.learner_display_section)?_c('div',{staticClass:"dashboard-section-number"},[_vm._v(" "+_vm._s(item.attributes.section_name)+" ")]):_vm._e(),_c('div',{staticClass:"dashboard-progress-bar"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({
                                width: [
                                  item.attributes.complete_percentage + '%',
                                ],
                              }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100","aria-label":item.attributes.complete_percentage +
                                '% completed'}})]),_c('div',{staticClass:"dashboard-progress-text"},[_vm._v(" "+_vm._s(item.attributes.completed_ulos_count)+"/"+_vm._s(item.attributes.total_count)+" ")])])]),_c('div',{staticClass:"dashboard-badge"},[(item.attributes.learner_messages_count > 0)?_c('div',{staticClass:"dashboard-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/messages-white.svg")}}),_vm._v(" "+_vm._s(item.attributes.learner_messages_count)+" ")]):_vm._e(),(item.attributes.learner_submissions_count > 0)?_c('div',{staticClass:"dashboard-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/submission-white.svg")}}),_vm._v(_vm._s(item.attributes.learner_submissions_count)+" ")]):_vm._e()])])]):_c('div',[_c('router-link',{staticClass:"dashboard-active-item",attrs:{"to":`/learning/${item.id}`}},[_c('div',{staticClass:"dashboard-active-img"},[(
                            item.attributes.learn_mod.attributes.photo_url
                              .large_version
                          )?_c('img',{attrs:{"src":item.attributes.learn_mod.attributes.photo_url
                              .large_version,"alt":item.attributes.learn_mod.attributes.image_caption}}):_vm._e()]),_c('div',{staticClass:"dashboard-active-btn"},[_vm._v(" Continue "),_c('em',{staticClass:"icon-caret-right"})]),_c('div',{staticClass:"dashboard-active-overlay"}),_c('div',{staticClass:"dashboard-active-content"},[_c('div',{staticClass:"dashboard-active-title"},[_vm._v(" "+_vm._s(item.attributes.learn_mod.attributes.name)+" ")]),(item.attributes.section_name && item.attributes.learner_display_section)?_c('div',{staticClass:"dashboard-section-number"},[_vm._v(" "+_vm._s(item.attributes.section_name)+" ")]):_vm._e(),_c('div',{staticClass:"dashboard-progress-bar"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({
                                width: [
                                  item.attributes.complete_percentage + '%',
                                ],
                              }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100","aria-label":item.attributes.complete_percentage +
                                '% completed'}})]),_c('div',{staticClass:"dashboard-progress-text"},[_vm._v(" "+_vm._s(item.attributes.completed_ulos_count)+"/"+_vm._s(item.attributes.total_count)+" ")])])]),_c('div',{staticClass:"dashboard-badge"},[(item.attributes.learner_messages_count > 0)?_c('div',{staticClass:"dashboard-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/messages-white.svg")}}),_vm._v(" "+_vm._s(item.attributes.learner_messages_count)+" ")]):_vm._e(),(item.attributes.learner_submissions_count > 0)?_c('div',{staticClass:"dashboard-badge-item"},[_c('img',{attrs:{"src":require("../assets/images/submission-white.svg")}}),_vm._v(_vm._s(item.attributes.learner_submissions_count)+" ")]):_vm._e()])])],1)])}),0)],1):_vm._e()])])])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }