<template>
  <div class="learn-sidebar sidebar-border" role="navigation">
    <div class="learn-sidebar-items" ref="learnSidebarItems">
      <div v-if="menus.length > 0" class="learn-sidebar-left">
        <ul v-if="user_roles.includes('testing-internal')" tabindex="0">
          <!-- Integrity in side menu start -->
          <li
            class="learn-sidebar-head learn-sidebar-item item-complete"
            @click="getActive('I')"
            v-if="enableIntegrity"
          >
            <div class="learn-sidebar-img">
              <img src="../assets/images/image-1.png" alt="" />
            </div>
            <div
              :class="[
                'learn-sidebar-intro',
                menus.length === 0 ||
                (!card_type && intros && intros.attributes && showIntegrity)
                  ? 'active'
                  : '',
              ]"
            >
              <div class="learn-sidebar-icon"></div>
              Academic Integrity
            </div>
          </li>
          <!-- Integrity in side menu end -->

          <li
            class="learn-sidebar-head learn-sidebar-item item-complete"
            @click="getActive('')"
          >
            <div class="learn-sidebar-img">
              <img src="../assets/images/image-1.png" alt="" />
            </div>
            <div
              :class="[
                'learn-sidebar-intro',
                menus.length === 0 ||
                (showIntro && !card_type && intros && intros.attributes)
                  ? 'active'
                  : '',
              ]"
            >
              <div class="learn-sidebar-icon"></div>
              Introduction
            </div>
          </li>
          <li
            :class="[
              'learn-sidebar-item',
              menu.attributes.complete
                ? active_data.id === menu.id
                  ? 'item-completed'
                  : 'item-complete'
                : active_data.id === menu.id
                ? 'item-progress'
                : '',
            ]"
            v-for="(menu, i) in menus"
            :key="menu.id"
          >
            <div class="learn-sidebar-icon"></div>
            <button
              :class="[
                'learn-sidebar-content',
                i === 0 ||
                menus[i > 0 ? i - 1 : 0].attributes.complete ||
                menus[i].attributes.complete
                  ? 'cursor'
                  : 'cursor',
              ]"
              @click="getActive(i)"
            >
              <div class="learn-sidebar-milestone" v-if="menu.completed_by">
                <h4>Milestone {{ menu.milestoneIndex }}</h4>
                <p>{{ menu.completed_by }}</p>
              </div>
              <div class="learn-sidebar-number" v-if="i < 9">0{{ i + 1 }}</div>
              <div class="learn-sidebar-number" v-if="i >= 9">{{ i + 1 }}</div>
              <div class="learn-sidebar-status" v-if="menu.attributes.complete">
                COMPLETED
                <em class="icon-caret-right"></em>
              </div>
              <div
                class="learn-sidebar-status"
                v-if="
                  !menu.attributes.complete &&
                  active_data &&
                  active_data.id === menu.id
                "
              >
                IN PROGRESS
                <em class="icon-caret-right"></em>
              </div>
              <div class="learn-sidebar-title">
                {{ menu.attributes.learning_object.attributes.name }}
              </div>
            </button>
          </li>
        </ul>
        <ul v-if="!user_roles.includes('testing-internal')"  tabindex="0">
          <!-- Integrity in side menu start -->
          <li
            class="learn-sidebar-head learn-sidebar-item item-complete"
            @click="getActive('I')"
            v-if="enableIntegrity"
          >
            <div class="learn-sidebar-img">
              <img src="../assets/images/image-1.png" alt="" />
            </div>
            <div
              :class="[
                'learn-sidebar-intro',
                menus.length === 0 ||
                (!card_type && intros && intros.attributes && showIntegrity)
                  ? 'active'
                  : '',
              ]"
            >
              <div class="learn-sidebar-icon"></div>
              Academic Integrity
            </div>
          </li>
          <!-- Integrity in side menu end -->

          <li
            class="learn-sidebar-head learn-sidebar-item item-complete"
            @click="getActive('')"
          >
            <div class="learn-sidebar-img">
              <img src="../assets/images/image-1.png" alt="" />
            </div>
            <div
              :class="[
                'learn-sidebar-intro',
                menus.length === 0 ||
                (showIntro && !card_type && intros && intros.attributes)
                  ? 'active'
                  : '',
              ]"
            >
              <div class="learn-sidebar-icon"></div>
              Introduction
            </div>
          </li>
          <li
            :class="[
              'learn-sidebar-item',
              menu.attributes.complete
                ? active_data.id === menu.id
                  ? 'item-completed'
                  : 'item-complete'
                : active_data.id === menu.id
                ? 'item-progress'
                : '',
            ]"
            :id="[
              menu.attributes.complete
                ? active_data.id === menu.id
                  ? 'currentActive'
                  : ''
                : active_data.id === menu.id
                ? 'currentActive'
                : '',
            ]"
            v-for="(menu, i) in menus"
            :key="menu.id"
          >
            <button @click="
                i === 0 ||
                menus[i > 0 ? i - 1 : 0].attributes.complete ||
                menus[i].attributes.complete
                  ? getActive(i)
                  : ''
              "
            >
              <div class="learn-sidebar-icon"></div>
              <div
                :class="[
                  'learn-sidebar-content',
                  i === 0 ||
                  menus[i > 0 ? i - 1 : 0].attributes.complete ||
                  menus[i].attributes.complete
                    ? 'cursor'
                    : '',
                ]"
              >
                <div class="learn-sidebar-milestone" v-if="menu.completed_by">
                  <h4>Milestone {{ menu.milestoneIndex }}</h4>
                  <p>{{ menu.completed_by }}</p>
                </div>

                <div class="learn-sidebar-number" v-if="i < 9">0{{ i + 1 }}</div>
                <div class="learn-sidebar-number" v-if="i >= 9">{{ i + 1 }}</div>
                <div class="learn-sidebar-status" v-if="menu.attributes.complete">
                  Completed
                  <em class="icon-caret-right"></em>
                </div>
                <div
                  class="learn-sidebar-status"
                  v-if="
                    !menu.attributes.complete &&
                    active_data &&
                    active_data.id === menu.id
                  "
                >
                  IN PROGRESS
                  <em class="icon-caret-right"></em>
                </div>
                <div class="learn-sidebar-title">
                  {{ menu.attributes.learning_object.attributes.name }}
                </div>
              </div>
              <div class="learn-sidebar-badge">
                <div
                  class="learn-badge-item"
                  v-if="menu.attributes.learner_submissions_count > 0"
                >
                  <img
                    src="../assets/images/badge-message-square.svg"
                    alt=""
                  />
                </div>
                <div
                  class="learn-badge-item"
                  v-if="menu.attributes.learner_messages_count > 0"
                >
                  <img
                    src="../assets/images/badge-message-round.svg"
                    alt=""
                  />
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-menu mobile-menu-close" @click="toggleMenu()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 34 34"
      >
        <g fill="none" fill-rule="evenodd">
          <rect width="34" height="34" fill="#000000" rx="17" />
          <path
            fill="#FFF"
            fill-rule="nonzero"
            stroke="#FFF"
            d="M18.322 17l4.486-4.487c.124-.124.192-.288.192-.464 0-.176-.068-.341-.192-.464l-.393-.393c-.123-.124-.288-.192-.464-.192-.176 0-.34.068-.464.192L17 15.678l-4.487-4.486c-.123-.124-.288-.192-.464-.192-.175 0-.34.068-.464.192l-.393.393c-.256.256-.256.672 0 .928L15.679 17l-4.487 4.487c-.124.124-.192.288-.192.464 0 .176.068.34.192.464l.393.393c.124.124.289.192.464.192.176 0 .34-.068.464-.192L17 18.321l4.487 4.487c.123.124.288.192.464.192h0c.176 0 .34-.068.464-.192l.393-.393c.124-.123.192-.288.192-.464 0-.176-.068-.34-.192-.464L18.322 17z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "learnSidebar",
  props: [
    "menus",
    "enableIntegrity",
    "showIntro",
    "card_type",
    "intros",
    "user_roles",
    "active_data",
  ],
  methods: {
    getActive(i) {
      this.$parent.getActive(i);
    },
    toggleMenu() {
      document
        .querySelector(".learn-sidebar")
        .classList.toggle("learn-sidebar-show");
    },
  },
};
</script>

