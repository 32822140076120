// import store from "../../store/index";
export default function auth({ to, next }) {
  const userDetails = localStorage.getItem('vuex');
  if (!userDetails) {
    if (to.params.id === undefined) {
      return next(`login?&name=${to.name}`)
    } else {
      return next(`login?&name=${to.name}&id=${to.params.id}&loId=${to.params.loId}`)
    }
  }
  return next()
}
