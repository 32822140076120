<template>
  <div class="learn-steps">
    <h1 class="learn-title">{{ timeLineTitle }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title">
            <img src="../assets/images/icon-yellownote.svg" alt="" />Note
          </p>
          <p v-html="notes">{{ notes }}</p>
        </div>
      </div>
    </div>
    <p class="learn-info" v-html="description"></p>
    <div class="learn-object" v-if="emailBody">
      <div class="learn-object-forward">
        <div class="learn-object-head wrap-step3">
          <!-- learn object col repeat start  -->
          <div
            class="learn-object-col"
            v-for="character in toCharacters"
            :key="character.id"
          >
            <div class="learn-object-label">From</div>
            <div class="learn-object-user">
              <img
                :src="character.attributes.char_photo_url.thumbnail"
                v-if="character.attributes.char_photo_url.thumbnail"
                v-bind:alt="character.attributes.char_first_name +' '+character.attributes.char_last_name +', '+character.attributes.world_role"
              />
            </div>
            <div class="learn-object-titles">
              <h2>{{ character.attributes.char_full_name }}</h2>
            </div>
          </div>
          <div class="cc-nowrap" v-if="ccCharacters.length">
            <div class="cc-label">CC</div>
            <div
              class="learn-object-col cc-col-step3"
              v-for="character in ccCharacters"
              :key="character.id"
            >
              <div class="learn-object-user">
                <img
                  :src="character.attributes.char_photo_url.thumbnail"
                  v-if="character.attributes.char_photo_url.thumbnail"
                  v-bind:alt="character.attributes.char_first_name +' '+character.attributes.char_last_name +', '+character.attributes.world_role"
                />
              </div>
              <div class="learn-object-titles">
                <h2>{{ character.attributes.char_full_name }}</h2>
                <p>{{ character.attributes.world_role }}</p>
              </div>
            </div>
          </div>
          <div class="learn-object-col">
            <div class="learn-object-label">To</div>
            <div class="learn-object-user">
              <img
                :src="user.attributes.profile_url"
                v-if="user.attributes.profile_url"
                alt=""
              />
            </div>
            <div class="learn-object-titles">
              <h2>{{ user.attributes.full_name }}</h2>
            </div>
          </div>
          <!-- learn object col repeat end  -->
        </div>
        <div class="learn-object-mail">
          <div class="learn-object-tag">Subject: {{ name }}</div>
          <div class="learn-object-paragraph">
            <div class="learn-object-desc">
              <div class="emailBody" v-html="emailBody"></div>
            </div>
          </div>
        </div>
        <div class="learner-uploaded-data" v-if="pdfs.length > 0">
          <div class="learning-data-list" v-for="pdf of pdfs" :key="pdf.id">
            <div class="learning-data-item">
              <div class="learning-data-icon">
                <button
                  @click="
                    $modal.show('PdfModal', {
                      pdfUrl: pdf.attributes.resource_url,
                      fileName: pdf.attributes.file_name,
                    })
                  "
                  aria-label="Open PDF"
                >
                  <img src="../assets/images/pdf-big-icon.png" alt="" />
                  <span class="visually-hidden">Open PDF</span>
                </button>
                <div class="text-detail">
                  <span class="text-right">{{ pdf.attributes.file_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lo-buttons
      text="See Final Feedback"
      :nextMethod="next"
      :nextStatus="nextStatus"
      :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
    ></lo-buttons>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import loButtons from "../components/loButtons.vue";
export default {
  name: "email-component",
  props: ["active_data", "nextStatus"],
  components: {
    loButtons
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },

      loId: "",
      card_type: "",
      card_data: {},
      name: "",
      description: "",
      timeLineTitle: "",
      url: utilFunctionService.getImageUrl(),
      toCharacters: "",
      ccCharacters: "",
      sticky_note: [],
      learning_id: "",
      emailBody: "",
      pdfs: []
    };
  },
  async created() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.loId = this.active_data.attributes.learning_object.id;
    this.name =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.title;
    this.description =
      this.active_data.attributes.learning_object.attributes.description;
    this.toCharacters =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.to_characters;
    this.ccCharacters =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.cc_characters;
    this.timeLineTitle =
      this.active_data.attributes.learning_object.attributes.name;
    this.user = this.active_data.attributes.user_section.attributes.user;
    this.pdfs =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.global_resources;
    this.getEmailBody();
    let attr = this.active_data.attributes;
    this.learning_id = attr.learning_object_id;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    let sections = attr.user_section.attributes.learning_object_sections;
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(sections[i].attributes.sticky_note);
      }
    }
  },
  methods: {
    next() {
      this.$parent.next();
    },
    getEmailBody() {
      //console.log("*** hit getEmailBody method in email.vue");
      if (!this.active_data.attributes.email_variation) {
        api.findPlotPointEmail(this.active_data.id).then((res) => {
          let emailBody = res.data.email_body;
          this.emailBody = emailBody.replaceAll(
            "[learner-name]",
            this.user.attributes.first_name
          );
        });
      } else {
        let emailBody = this.active_data.attributes.email_variation;
        this.emailBody = emailBody.replaceAll(
          "[learner-name]",
          this.user.attributes.first_name
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.emailBody {
  /deep/ p {
    margin: 0 !important;
    a {
      display: inline-block;
    }
  }
}
.cc-label {
  padding-left: 45px;
  font-size: 13px;
  color: #606060;
  display: flex;
}
.learn-object-mail {
  border-top: 1px solid #ccc;
  padding-top: 2em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 2em;
}
.cc-nowrap {
  padding: 1em 0 0 1em;
}
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: flex-end;
}
</style>
