<template>
  <div class="learn-steps">
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title">
            <img src="../assets/images/icon-yellownote.svg" alt="" />Note
          </p>
          <p v-html="notes"></p>
        </div>
      </div>
    </div>
    <p class="learn-info" v-html="card_data.description"></p>

    <div class="learn-object mt-6">
      <!-- learn object-editor start-->
      <div class="learn-object-form learn-editor-height mt-4" v-if="has_text">
        <span v-if="!notSubmittedYet">
          <h1 class="mb-5 submission-title">Your submission:</h1>
          <div v-html="editorText" v-if="!show_writing_assessment"></div>
          <div class="learn-notes writing-note" v-if="show_writing_assessment">
            Coherence issues were detected with your submission and are
            highlighted below. See feedback for more information.
          </div>
          <div
            v-html="assessed_writing"
            v-if="show_writing_assessment"
            class="assessed-writing"
          ></div>
        </span>
        <span v-else>
          <vue-editor
            :editorToolbar="simpleToolbar"
            v-model="editorText"
          ></vue-editor>
        </span>
      </div>
      <div class="learn-upload-btn" v-if="has_file_upload && !file">
        <span>
          <input
            type="file"
            accept="application/pdf, text/csv"
            @change="onFileChange"
            aria-label="Upload File"
          />
          Upload File
        </span>
      </div>

      <div class="learner-uploaded-data" v-if="file">
        <div class="learning-data-list">
          <div class="learning-data-item">
            <div class="learning-data-icon" v-if="isPDF">
              <button
                @click="
                  $modal.show('pdfModal', {
                    pdfUrl: submissionData.attributes.attachment_url,
                    label: file.name,
                  })
                "
                aria-label="Show PDF"
              >
                <img src="../assets/images/pdf-big-icon.png" alt="" />
                <span class="visually-hidden">Show PDF</span>
              </button>
            </div>
            <div class="learning-data-icon" v-if="isCSV">
              <button
                @click="
                  $modal.show('csvModal', {
                    active_data: submissionData,
                    pdfUrl: submissionData.attributes.attachment_url,
                    label: file.name,
                  })
                "
                aria-label="Show CSV"
              >
                <img src="../assets/images/csv_placeholder.png" alt="" />
                <span class="visually-hidden">Show CSV</span>
              </button>
            </div>
            <div class="learning-data-title">
              <h4>{{ file.name }}</h4>
              <div class="progress-outer" v-if="isFileUploaded">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="uploadPercentage"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <a @click="removeFile" v-if="notSubmittedYet">Remove</a>
            </div>
          </div>
        </div>
      </div>

      <div class="learn-object-send" v-if="has_file_upload">
        <button
          v-if="notSubmittedYet"
          :class="`btn btn-ametros ${file ? '' : 'disabled'}`"
          @click="$modal.show('submitShow')"
          aria-label="Submit"
        >
          Submit
        </button>
      </div>
      <div class="learn-object-send" v-else>
        <button
          class="btn btn-ametros"
          @click="$modal.show('submitShow')"
          v-if="notSubmittedYet"
          aria-label="Submit"
        >
          Submit
        </button>
      </div>
    </div>

    <div
      class="messages-notes"
      v-if="interimResponse != null && !submissionFeedback"
    >
      <p class="note-title">Message from Instructor</p>
      <p v-html="interimResponse"></p>
    </div>

    <div class="messages-notes" v-if="submissionFeedback">
      <p class="note-title">Message from Instructor</p>
      <p v-html="submissionFeedback"></p>
    </div>

    <div
      class="learn-object yellow-note"
      v-if="
        !notSubmittedYet &&
        !learner_hold_on_submission &&
        !submissionFeedback &&
        !interimResponse &&
        status !== 'published'
      "
    >
      <p>
        Note: This submission needs to be graded by your instructor. You will
        receive an email notification when your grade is ready.
      </p>
    </div>

    <div
      class="learn-object yellow-note"
      v-if="
        !notSubmittedYet && learner_hold_on_submission && status !== 'published'
      "
    >
      <p>
        Note: You won't be able to move to the next task until your instructor
        has reviewed and graded your submitted work.
      </p>
    </div>

    <div v-if="user_roles.includes('testing-internal')">
      <button @click="resetSubmission()" class="btn secondary">
        Reset Interaction
      </button>
    </div>

    <lo-buttons
      v-if="status == 'published'"
      :nextMethod="showSubmissionFeedback"
      :nextStatus="nextStatus"
      text="See Feedback"
      :showCertificateOfCompletion="
        active_data.attributes.enable_certificate_of_completion
      "
    ></lo-buttons>
    <lo-buttons
      v-if="
        !notSubmittedYet &&
        !learner_hold_on_submission &&
        status !== 'published'
      "
      :nextMethod="next"
      :nextStatus="nextStatus"
      text="See Final Feedback"
      :showCertificateOfCompletion="
        active_data.attributes.enable_certificate_of_completion
      "
    ></lo-buttons>
    <modal
      name="alertShow"
      aria-label="alertShow"
      role="dialog"
      class="modal-delete"
      height="auto"
      :width="350"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-delete-content">
          <div class="modal-body modal-delete-body">
            Your submission has been sent successfully.
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              @click="$modal.hide('alertShow')"
              class="btn medium primary"
              aria-label="close modal"
            >
              Close
            </button>
          </div>
        </div>
      </FocusLoop>
    </modal>
    <modal
      name="submitShow"
      aria-label="submitShow"
      role="dialog"
      class="modal-delete"
      height="auto"
      :width="350"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-delete-content">
          <div class="modal-body modal-delete-body">
            <p>
              Are you sure? You won't be able to make any changes after
              submitting.
            </p>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              @click="$modal.hide('submitShow')"
              class="btn medium"
              aria-label="No"
            >
              No
            </button>
            <button
              type="button"
              @click="handleFormSubmit"
              class="btn medium primary"
              aria-label="Yes"
            >
              Yes
            </button>
          </div>
        </div>
      </FocusLoop>
    </modal>
    <modal
      name="submissionEmailResult"
      aria-label="submissionEmailResult"
      role="dialog"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div
          class="modal-content modal-final-content modal-dialogic-content modal-grade-content"
        >
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('submissionEmailResult')"
            aria-label="close modal"
          >
            <em class="icon-cross"></em>
          </button>
          <VuePerfectScrollbar
            class="modal-slide-content mt-2"
            :settings="settings"
          >
            <div class="modal-body m-auto">
              <div class="modal-title">
                <h1>Here is your feedback.</h1>
                <div
                  class="modal-grade"
                  v-if="emailDebriefResult.display_grade"
                >
                  <p>
                    Grade {{ Math.round(emailDebriefResult.overall_grade) }}%
                  </p>
                </div>
              </div>

              <div class="writing-explanation" v-if="show_writing_assessment">
                Coherence in writing is the logical bridge between words,
                sentences, and paragraphs. Coherent writing uses devices to
                connect ideas within each sentence and paragraph. Main ideas and
                meaning can be difficult for the reader to follow if the writing
                lacks coherence.
              </div>

              <div class="modal-grade-items">
                <div
                  class="modal-grade-row"
                  v-for="result in emailDebriefResult.data"
                  :key="result.id"
                >
                  <div
                    class="modal-grade-icon"
                    v-if="emailDebriefResult.display_grade"
                  >
                    <p>{{ result.attributes.score }}</p>
                    <span>Score</span>
                  </div>
                  <div class="modal-grade-content">
                    <div class="modal-grade-head">
                      <h4>{{ result.attributes.rubric_criteria }}</h4>
                      <button
                        v-if="result.attributes.rubric_criteria_desc"
                        :content="result.attributes.rubric_criteria_desc"
                        v-tippy="{ trigger: 'click', arrow: true }"
                        class="feedback-info no-style-btn"
                        aria-label="Show rubric criteria description"
                      >
                        i
                        <span class="visually-hidden"
                          >Show rubric criteria description</span
                        >
                      </button>
                      <span v-if="emailDebriefResult.display_grade"
                        >(Weight {{ result.attributes.rubric_weight }}%)</span
                      >
                    </div>
                    <p v-html="result.attributes.rubric_feedback"></p>
                  </div>
                </div>
              </div>
              <lo-buttons
                v-if="!notSubmittedYet"
                :nextMethod="next"
                :nextStatus="nextStatus"
                text="See Final Feedback"
                :showCertificateOfCompletion="
                  active_data.attributes.enable_certificate_of_completion
                "
              ></lo-buttons>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import axios from "axios";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import loButtons from "../components/loButtons.vue";

export default {
  name: "submissionGrading",
  components: {
    VueEditor,
    VuePerfectScrollbar,
    loButtons,
  },
  props: ["active_data", "nextStatus"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      loId: "",
      status: "",
      card_type: "",
      card_data: {},
      fullToolbar: [],
      simpleToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      editorText: null,
      file: null,
      notSubmittedYet: true,
      url: utilFunctionService.getImageUrl(),
      uploadPercentage: { width: "0%" },
      isFileUploaded: false,
      has_file_upload: false,
      has_text: false,
      interimResponse: null,
      submissionFeedback: null,
      emailDebriefResult: "",
      sticky_note: [],
      learner_hold: false,
      user_roles: utilFunctionService.getUserRoles(),
      learning_id: "",
      submissionData: {},
      hasReviewedFeedback: false,
      assessed_writing: "",
      show_writing_assessment: false,
      isOpen: false,
      isCSV: false,
      isPDF: false,
    };
  },
  created() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.loId = this.active_data.id;
    this.has_file_upload =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.has_file_upload;
    this.has_text =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.has_text;
    let attr = this.active_data.attributes;
    let sectionData = attr.user_section.attributes.learning_object_sections;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    this.learning_id = attr.learning_object_id;
    this.hasReviewedFeedback = this.active_data.attributes.hasReviewedFeedback;
    for (let i = 0; i < sectionData.length; i++) {
      if (sectionData[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(sectionData[i].attributes.sticky_note);
        this.learner_hold_on_submission =
          sectionData[i].attributes.learner_hold_on_submission;
      }
    }
    this.getSubmissionData();
  },
  methods: {
    beforeOpen() {
      this.isOpen = true;
    },
    showSubmissionFeedback() {
      api
        .getSubmissionEmailDebrief(this.active_data.id)
        .then((res) => {
          this.emailDebriefResult = res.data;
          this.$modal.show("submissionEmailResult");
        })
        .catch(() => {});
    },
    handleUserSubmission() {
      let fb = new FormData();
      fb.append("user_submission[user_learn_obj_id]", this.active_data.id);
      if (this.has_file_upload) {
        if (this.file) {
          fb.append("user_submission[attachment]", this.file);
          this.isFileUploaded = true;
        }
      }
      if (this.has_text) {
        if (this.editorText) {
          fb.append("user_submission[user_text]", this.editorText);
        }
      }
      if (this.has_file_upload && this.has_text) {
        if (this.editorText && this.file) this.setSubmission(fb);
      } else {
        if (this.has_file_upload && this.file) this.setSubmission(fb);
        if (this.has_text && this.editorText) {
          utilFunctionService.showLoader();
          this.setSubmission(fb);
        }
      }
    },
    setSubmission(fb) {
      let accessToken = utilFunctionService.getAuthToken();
      axios
        .post(`${this.url}api/v1/user_submissions.json`, fb, {
          headers: {
            Authorization: `${accessToken}`,
            "content-type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage.width = `${Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )}%`;
          },
        })
        .then(() => {
          this.getSubmissionData();
          this.isFileUploaded = false;

          //this.notSubmittedYet = false;
          utilFunctionService.hideLoader();
          this.$modal.show("alertShow");
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    onFileChange(e) {
      let target = e.target,
        files = target.files || e.dataTransfer.files;
      const filename = files[0].name;
      const ext = filename.split(".").pop().toLowerCase();
      if (ext == "pdf" || ext == "csv") {
        this.file = files[0];
        if (ext == "pdf") {
          this.isPDF = true;
          this.isCSV = false;
        } else if(ext == "csv"){
          this.isCSV = true;
          this.isPDF = false;
        } else {
          this.isCSV = false;
          this.isPDF = false;
        }
      } else {
        e.target.value = "";
        utilFunctionService.showerr(
          "Please upload only pdf files or CSV files."
        );
        return false;
      }
    },
    clearForm() {
      this.file = null;
      this.editorText = null;
    },
    next() {
      if (this.has_file_upload && this.has_text) {
        if (this.editorText && this.file && !this.notSubmittedYet)
          this.$parent.next();
      } else {
        if (this.has_file_upload && this.file && !this.notSubmittedYet)
          this.$parent.next();
        if (this.has_text && this.editorText && !this.notSubmittedYet)
          this.$parent.next();
      }
    },
    setUpAssessedWriting() {
      this.submissionData.attributes.writing_assessment.attributes.sentences.forEach(
        (sentence) => {
          //console.log(sentence, "*** sentence");

          if (sentence.attributes.coherence == true) {
            //let node = document.createTextNode(sentence.attributes.sentence);
            //assessed_writing_div.appendChild(node);
            let replacer = sentence.attributes.sentence; //.replace("<p><br></p>","");
            this.assessed_writing = this.assessed_writing + " " + replacer;
          } else {
            //let spanNode = document.createElement("span");
            //spanNode.setAttribute("class", "highlight");
            //let node = document.createTextNode(sentence.attributes.sentence);
            //spanNode.addEventListener("click", this.toggleMarking)
            //spanNode.appendChild(node);
            //assessed_writing_div.appendChild(spanNode);
            let replacer = sentence.attributes.sentence; //.replace("<p><br></p>","");
            this.assessed_writing =
              this.assessed_writing +
              "<span class='highlight' data-sentence-number='" +
              sentence.attributes.sentence_order +
              "'> " +
              replacer +
              "</span>";
          }
        }
      );
    },
    getSubmissionData() {
      api
        .getSubmissionData(this.active_data.id)
        .then((res) => {
          this.submissionData = res.data.data;
          if (this.submissionData) {
            if (
              this.submissionData.attributes.user_learn_obj_complete === true
            ) {
              this.notSubmittedYet = false;
            } else {
              this.notSubmittedYet = true;
            }

            if (this.submissionData.attributes.attachment_url) {
              let url = decodeURI(
                this.submissionData.attributes.attachment_url
              ).split(`"`);
              this.file = { name: decodeURI(url[1]), size: null };
              const ext = this.file.name.split(".").pop().toLowerCase();
              if (ext == "pdf") {
                this.isPDF = true;
                this.isCSV = false;
              } else if(ext == "csv"){
                this.isCSV = true;
                this.isPDF = false;
              } else {
                this.isCSV = false;
                this.isPDF = false;
              }
            }

            this.editorText = this.submissionData.attributes.user_text;
            this.interimResponse =
              this.submissionData.attributes.interim_response;
            this.submissionFeedback = this.submissionData.attributes.feedback;
            this.status = this.submissionData.attributes.status;

            if (this.submissionData.attributes.writing_assessment) {
              if (
                this.submissionData.attributes.writing_assessment.attributes
                  .sentences.length > 0
              ) {
                this.setUpAssessedWriting();
                this.show_writing_assessment =
                  this.submissionData.attributes.show_writing_assessment;
              }
            }
          } else {
            this.clearForm();
          }
        })
        .catch(() => {
          this.clearForm();
        });
    },
    resetSubmission() {
      api
        .resetSubmission(this.loId)
        .then(() => {
          this.$router.push({path: `/learning/${this.module_id}/${this.active_data.id}`})
          this.$router.go();
        })
        .catch(() => {});
    },
    removeFile() {
      this.file = null;
    },
    handleFormSubmit() {
      this.handleUserSubmission();
      this.$modal.hide("submitShow");
    },
  },
};
</script>
<style scoped lang="scss">
.writing-explanation {
  width: 60%;
  font-size: 1.1em;
  margin-bottom: 4rem;
}
p {
  margin-bottom: 1rem !important;
}
.submission-title {
  font-size: 1.25rem;
  font-weight: bold;
}
</style>