<template>
  <div>

    <div class="terms-conditions-content">
      <h1>Terms and Conditions</h1>
      <h2>AMETROS LEARNING INC. END USER LICENSE AGREEMENT</h2>
      <p>
        PLEASE READ THIS AGREEMENT CAREFULLY BEFORE DOWNLOADING,
        INSTALLING OR USING THE SIMULATION APPLICATION.
      </p>

      <h3 class="terms-bold">Agreement</h3>

      <p>
        Ametros Learning Inc. ("<strong>Ametros</strong>") provides the
        Ametros Experiential Learning Platform
        ("<strong>Platform</strong>") solely subject to and on the
        condition that you ("<strong>You</strong>" or
        "<strong>Your</strong>", as applicable) accept and comply with the
        following Terms and Conditions of Use (the
        "<strong>Agreement</strong>"). Please read this Agreement
        carefully before using the Platform, as it governs Your right to
        use the Platform provided by Ametros. By clicking the "ACCEPT"
        button, You accept this Agreement and agree to be bound to all the
        terms and conditions of use in this Agreement, and represent and
        warrant that you are of legal age and capacity to enter into a
        binding agreement (or that a parent or legal guardian is entering
        into this Agreement on your behalf, and to that extent "You"
        includes Your parent or legal guardian, as applicable, in this
        Agreement). Please do not click "accept" nor use the Ametros
        Platform if You do not agree to all of these terms. Without any
        notice, Ametros reserves the right to update the Agreement at any
        time, and Your continued use of the Platform implies Your
        acceptance of those changes. This Agreement is between you and
        Ametros.
      </p>

      <h3 class="terms-bold">License and Restrictions</h3>

      <p>
        Subject to and conditional on Your strict compliance with all
        terms and conditions set forth in this agreement, Ametros grants
        to You a non-exclusive, non-transferable, non-assignable,
        non-sublicensable, limited license to access, view and use the
        Platform for personal, educational and informational purposes
        only. The Platform shall not be used for any commercial purposes,
        nor shall it be reproduced, transmitted, published, distributed,
        rented, leased, lent, pledged or transferred, directly or
        indirectly, to another party without the express prior written
        consent of Ametros. Any signs belonging to Ametros, such as,
        logos, names, copyright notices, trademarks, trade names, service
        marks or any intellectual property may not be destroyed, removed,
        obscured or otherwise altered.
      </p>

      <p>
        In this Agreement, "<strong>Intellectual Property Right</strong>"
        means any and all registered and unregistered rights granted,
        applied for or otherwise now or hereafter in existence under or
        related to any patent, copyright, trademark, trade secret,
        database protection or other Intellectual Property Rights laws,
        and all similar or equivalent rights or forms of protection, in
        any part of the world. In accessing and using the Platform, you
        shall not: (i) change, alter, modify, adapt or create derivative
        works based on the Platform or any portion thereof; (ii) interfere
        with, disrupt or disable the integrity or performance of the
        Platform or data contained therein or use the Platform to send or
        store any virus, worm, time bomb, Trojan horse or other harmful or
        malicious code, file, script, agent, program or component; (iii)
        copy, reverse engineer, decode, decompile, disassemble, decrypt,
        reassemble or translate or unbundle any component of the Platform
        or do anything that is intended to discover the source code,
        confidential information or trade secrets inherent in the
        Platform; (iv) use the Platform in any way that violates any
        Ametros Intellectual Property Right or is in violation of any
        applicable law; (v) upload to or transmit via the Platform any
        content or anything else that if reproduced, published,
        transmitted or used may violate any law (including intellectual
        property and privacy laws) or give rise to civil or other
        liability; (vi) access the Platform for purposes that are
        competitive to Ametros, including for the purposes of building
        simulations or products competitive to Ametros's simulations and
        products or evaluating the Platform for competitors of Ametros;
        (vii) alter, remove or obscure any proprietary, copyright or other
        intellectual property notices or other indicia of ownership of
        Ametros or any other party contained on, in, or associated with
        the simulation; or (viii) attempt to gain unauthorized access to
        the Platform or related systems or networks or data contained
        therein, or permit direct or indirect access to or use of the
        Platform or any user data in a way that circumvents a contractual
        usage limit ("<strong>Unauthorized Access</strong>").
      </p>

      <p>
        The license to use Ametros is a non-exclusive, non-transferable,
        non-assignable, non-sublicensable, limited and revocable right.
        Ametros retains all right, title and interest in and to the
        Platform and all Intellectual Property Rights therein or relating
        thereto, including any derivatives thereto. Your possession,
        access or use of the Platform does not transfer any ownership of
        the Platform or any Intellectual Property Rights to You.
      </p>

      <h3 class="terms-bold">Other Obligations</h3>

      <p>
        You agree to be solely liable and responsible for Your use of the
        Platform, through access provided by You, and any and all of Your
        content that You display, upload, download or transmit through the
        use of the Platform. All of Your content accessed, stored or
        replicated in our networks or systems remains Your sole property.
        At no point does Ametros retain any rights to, or liability from,
        any of Your content stored or transferred to Ametros.
        Notwithstanding the foregoing, You agree that Ametros may (i)
        grant to Your educational institution or business organization,
        and representatives thereof, as applicable, access to (a) Your
        content that You display, upload, download or transmit through the
        Platform, and (b) information about Your progress, and/or
        completion of, a course or module within the Platform; and (ii)
        collect and store information regarding use of the Platform
        (including Your user content) and about equipment on which the
        Platform is installed or through which it is otherwise access and
        used to provide support and maintenance services, to improve the
        functionality and performance of the Platform, developing updates
        to the Platform, and verifying compliance with the terms of this
        Agreement and enforcing Ametros' rights, including all
        Intellectual Property Rights.
      </p>

      <p>
        The parties acknowledge that the information collected during Your
        use of the Platform may include personal information about You.
        The parties agree to act in accordance with the Personal
        Information Protection and Electronic Documents Act
        ("<strong>PIPEDA</strong>") and applicable provincial private
        sector privacy legislation which the Governor in Council has by
        order declared substantially similar to PIPEDA with respect to
        collection, use, disclosure, retention of and access to personal
        information, and any other applicable laws and regulations with
        respect to the collection and use of personal information.
      </p>

      <p>
        The Platform and our networks and systems may only be used for
        lawful purposes. Use of the Platform for transmission,
        distribution, retrieval or storage of any information, data or
        other material in violation of any applicable law is prohibited.
        This prohibition includes, but is not limited to material or data
        which: (i) infringes any copyright, trade-mark, trade secret or
        other Intellectual Property Right; (ii) violates export control
        laws or regulations; (iii) violates any party's privacy or similar
        rights; (iv) constitutes use or dissemination of child
        pornography; (v) is defamatory or libelous of any person; (vii)
        violates any person's privacy rights; or (vii) is otherwise
        illegal or unlawful (collectively, "<strong
          >Unauthorized Content</strong
        >"). You warrant to us that You have obtained any required
        consents for the collection, use, transfer and processing of
        personal data of any individuals as required by law. You may not
        interfere with, disrupt or harm in any way the Platform, including
        the networks and systems that form part of the licensed
        application. BY USING THE APPLICATION, YOU REPRESENT, WARRANT AND
        AGREE THAT YOU UNDERSTAND AND AGREE TO THE TERMS OF THIS AGREEMENT
        AND THAT YOU WILL USE THE APPLICATION IN A MANNER CONSISTENT WITH
        ALL APPLICABLE LAWS AND REGULATIONS AND IN ACCORDANCE WITH THE
        TERMS AND CONDITIONS OF THIS AGREEMENT.
      </p>

      <p>
        You acknowledge and agree that the licensed application is
        provided via the Internet and that You are responsible for
        obtaining Your own access to the Internet and that we shall not
        provide such access or any applications in relation thereto. You
        acknowledge and agree that there are inherent risks and
        limitations associated with the communication of information over
        the Internet including, without limitation, the possibility that
        such information may be lost, intercepted or altered or otherwise
        rendered non-confidential ("<strong>Internet Risks</strong>"). You
        acknowledge and agree that while Ametros enables communications
        through the Platform to be undertaken using industry standard SSL
        and / or HTTPS encryption, you are responsible for enabling and
        using such encryption and that no encryption technology is
        completely secure. You acknowledge and agree that any
        communication undertaken in connection with the Platform, even if
        encrypted, may be subject to Internet Risks, and that,
        notwithstanding any other provision herein, we shall not in any
        way be responsible or liable for any claim, action, damage or
        expense (direct, indirect, consequential or otherwise) arising
        from such Internet Risks, the communication of information over
        the Internet or Your actions or the actions of any third party.
      </p>

      <p>
        You represent, acknowledge, covenant and agree that (i) You are
        responsible for compliance with all applicable data protection,
        privacy and security requirements, including fulfilling any data
        privacy and security requirements directly with us; (ii) You are
        duly authorized to provide personal data to us and You do so
        lawfully in compliance with relevant legislation; and (iii)
        Ametros and any of our subsidiaries or agents can process such
        data and may transfer such data to countries outside of the
        country of origin.
      </p>

      <h3 class="terms-bold">Content, Copyright and Ownership</h3>

      <p>
        You agree that the content of Ametros on the Platform is protected
        by copyright under both Canadian and foreign laws, and that title
        to the content remains with Ametros. Any use of the content that
        is not expressly permitted by this Agreement is a breach of the
        terms of this Agreement and may violate copyright, trademark and
        other laws. Ametros reserves all the rights not expressly granted
        herein.
      </p>

      <h3 class="terms-bold">Warranty and Limited Liability</h3>

      <p>
        Use of the Platform and any applications provided by Ametros is at
        Your own risk. You acknowledge that You have independently
        determined that the Platform and licensed application meet Your
        requirements and that You shall not rely on any representation
        made, or information provided, by us as to the suitability of the
        Platform or licensed applications for any particular purpose.
      </p>

      <p>
        Ametros warrants that the licensed application will perform
        substantially in accordance with the applicable functional
        specification during the currency of this Agreement. Ametros may
        update the functional specifications from time to time. This
        warranty does not apply if the licensed application failed because
        of an accident, abuse, misapplication or unauthorized
        modification, or due to Your failure to maintain the currency of
        Your supporting applications. If You notify us within the warranty
        period that certain aspects of the licensed application fails to
        meet this warranty, we will, at our option, repair or replace the
        licensed application, in accordance with the terms and conditions
        of our then-current policy.
      </p>

      <p>
        Ametros does not make any warranties or representations regarding
        the accuracy, reliability, completeness or timeliness of the
        licensed application provided.
      </p>

      <p>
        Ametros assumes no liability in relation to the use of the Ametros
        website or application including, but not limited to, any
        liability that may stem from delay, failure, interruption or
        corruption of any data or other information transmitted. In no
        event shall we, or our agents, be liable to You for more than the
        amount of any actual direct damages up to $1,000, that are the
        subject of the claim, regardless of the cause and whether arising
        in contract, tort (including negligence) or otherwise. This
        limitation will not apply to claims for bodily injury (including
        death) and damage to real property and tangible personal property
        for which we are legally liable.
      </p>

      <p>
        THE WARRANTIES AND LIMITATIONS SET OUT IN THIS AGREEMENT ARE YOUR
        EXCLUSIVE WARRANTIES AND SOLE REMEDIES AND REPLACE ALL OTHER
        WARRANTIES, REPRESENTATIONS OR CONDITIONS, EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES,
        REPRESENTATIONS OR CONDITIONS OF NON-INFRINGEMENT, MERCHANTABILITY
        AND FITNESS FOR A PARTICULAR PURPOSE, EVEN IF WE HAVE BEEN
        INFORMED FOR SUCH PURPOSE. NO AGENT OF US IS AUTHORIZED TO ALTER
        OR EXCEED OUR WARRANTY OBLIGATIONS AS SET FORTH HEREIN. THE
        SIMULATION APPLICATION MAY CONTAIN TECHNOLOGY THAT IS NO FAULT
        TOLERANT AND IS NOT DESIGNED, MANUFACTURED OR INTENDED FOR USE IN
        ENVIRONMENTS OR APPLICATIONS IN WHICH THE FAILURE OF THE
        SIMULATION APPLICATION COULD LEAD TO DEATH, PERSONAL INJURY, OR
        SEVERE PHYSICAL, PROPERTY OR ENVIRONMENTAL DAMAGE.
      </p>

      <h3 class="terms-bold">Governing Law / International Users</h3>

      <p>
        This Agreement and all matters arising out of or relating to this
        Agreement are governed by the laws of the Province of Ontario and
        the federal laws of Canada applicable therein.
      </p>

      <p>
        Users of the licensed application of Ametros who are outside of
        the province of Ontario and Canada are also subject to this
        Agreement. Access to the Ametros website or application and
        licensed application from outside Canada is at Your own risk and
        You are responsible for compliance with the laws in Your
        jurisdiction.
      </p>

      <h3 class="terms-bold">Customer Indemnity</h3>

      <p>
        You agree to indemnify and hold harmless from and against any
        claims, demands, causes of action, liabilities or settlements
        whatsoever, Ametros, directors, officers, employees, agents and
        suppliers of Ametros, and shall defend or settle all claims and
        actions brought against us by third parties, arising of or in any
        way related to Your use of the Platform or the licensed
        application howsoever such claim or action shall have occurred or
        arose. This includes, but is not limited to, reasonable legal and
        accounting fees.
      </p>

      <h3 class="terms-bold">Maintenance Services</h3>

      <p>
        Maintenance Services, which may include support services, shall be
        made available to You by us in accordance with our then-current
        descriptions. Ametros may subcontract any portion or all of
        Maintenance Services to subcontractors selected by us provided
        that we will remain fully responsible for the performance of such
        Maintenance Services and its obligations hereunder.
      </p>

      <h3 class="terms-bold">Termination</h3>

      <p>
        This Agreement and the license granted hereunder shall remain in
        effect until terminated as set forth herein. You may terminate
        this Agreement by ceasing the use of the Platform at any time.
        Ametros may terminate this agreement by ceasing to offer the
        Platform at any time. Your permission to use the licensed
        application automatically terminates once You violate any of the
        terms and conditions of this Agreement. Subject to this Subsection
        (Termination), either You or Ametros may terminate this Agreement
        upon written notice to the other party if a material breach of
        this Agreement by the other party is not corrected within 30 days
        of receipt of a written notice of such breach.
      </p>

      <p>
        In addition, Ametros may, without notice, temporarily suspend Your
        or any party's access to the Platform by deactivating any
        password(s) or Internet links if we reasonably suspect that You or
        any other parties are obtaining Unauthorized Access to or using
        Unauthorized Content in connection with such networks, system or
        information. These suspensions will be for such periods of time as
        we may determine is necessary to permit the thorough investigation
        of such suspected activity.
      </p>

      <p>
        Upon termination for any reason, You will immediately cease and
        desist all use of the Platform and the license granted hereunder
        will also terminate.
      </p>

      <p>
        You acknowledge and agree that if Your account has been disabled
        for any reason, Ametros shall have the right to automatically
        delete all data associated with your account. Deletion of data
        does not require prior notification to or approval by You. Once
        deleted, data associated with your account cannot be restored.
      </p>

      <p>
        Both Your and our obligations under this Agreement which by their
        nature would continue beyond the termination, cancellation or
        expiration of this Agreement shall survive such termination,
        cancellation or expiration.
      </p>

      <h3 class="terms-bold">General</h3>

      <p>
        You agree not to assign, or otherwise transfer this Agreement or
        Your rights under it, or delegate Your obligations, without our
        prior written consent of Ametros, and any attempt to do so is
        void.
      </p>

      <p>
        You represent and warrant that You are licensing application for
        Your own internal domestic private and non-commercial use in the
        country for which it has been licensed or purchased and not for
        resale or re-license. You agree not to export, either directly or
        indirectly, the Platform or any copy thereof in whole or in part,
        nor any hardware or system incorporating the Platform in whole or
        in part without having first obtained clearance or a license to
        re-export from the applicable governments as required under their
        respective re-export regulations.
      </p>

      <p>
        Neither You nor we will bring a legal action under this Agreement
        more than two years after the cause of action arose.
      </p>

      <p>
        Neither You nor we are responsible for failure to fulfill any
        obligations due to causes beyond either of our reasonable control.
      </p>

      <p>
        In the event that any provision of this Agreement or portions
        thereof is held to be invalid or unenforceable, the remainder of
        this Agreement will remain in full force and effect.
      </p>

      <p>
        You acknowledge and agree that Your failure to comply with the
        provisions of this Agreement intended to protect our proprietary
        rights in the Platform may cause immediate and irreparable harm.
        Accordingly, in the event of a violation, or threatened violation,
        of this Agreement, in addition to any other right or remedy, we
        shall be entitled to seek equitable relief by way of temporary or
        permanent injunction or any other remedy that a court may deem
        appropriate.
      </p>

      <p>
        Any provision of this Agreement that is prohibited or
        unenforceable in any jurisdiction will, as to that jurisdiction,
        be ineffective to the extent of the prohibition or
        unenforceability without invalidating the remaining provisions of
        this Agreement and any prohibition or unenforceability in any
        jurisdiction will not invalidate or render unenforceable that
        provision in any other jurisdiction.
      </p>

      <p>
        The terms and conditions of this Agreement form the complete and
        exclusive agreement between You and Ametros and replace any prior
        oral or written proposals, correspondence or communications
        regarding the subject matter herein. Any changes to this Agreement
        must be made by mutual agreement in writing.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "termsConditions",
  components: {
  },
  data() {
    return {
      title: "Login",
      settings: {
        maxScrollbarLength: 30,
      },
    };
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
  methods: {
    opened() {
      console.log(this.$refs.TAC);
      this.$nextTick(() => {
        const TACRef = this.$refs.TAC;
        TACRef.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

#logo-box {
  display: flex;
}

.terms-conditions-content {
  p {
    margin: 1em 0;
    font-size: 14px;
  }
  h2 {
    font-size: 22px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
  }
}

.container {
  width: 100%;
  margin-left: 0;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1280px;
}
.terms-bold {
  font-weight: bold;
  margin-top: 2em;
  font-size: 16px;
}

.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-bold;
    font-size: 28px;
    margin: 0 0 40px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .modal {
    height: 100vh;
    max-height: 750px;
    overflow-y: auto;
  }
}
</style>