<template>
  <div class="learn-steps">
    <h1 class="learn-title">{{ intro.name }}</h1>
    <div v-if="sticky_note.length">
      <div v-for="(notes, index) in sticky_note" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title">
            <img src="../assets/images/icon-yellownote.svg" alt="" />Note
          </p>
          <p v-html="notes">{{ notes }}</p>
        </div>
      </div>
    </div>
    <p class="learn-info" v-html="intro.description"></p>
    <div class="introduction">
      <div class="wistia_responsive_wrapper">
        <iframe
          :src="
            preIframUrl + global_video.attributes.wistia_code + postIframUrl
          "
          title="Video.mp4"
          class="wistia_embed"
          name="wistia_embed"
          allowtransparency="true"
          scrolling="no"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <div class="intro-transcript">
        <p class="transcript-title">Transcript:</p>
        <readmore
          more-str="Read more"
          :text="global_video.attributes.transcript"
          link="#"
          less-str="Read less"
          :max-chars="400"
        ></readmore>
      </div>
      <lo-buttons
        :nextStatus="nextStatus"
        :nextMethod="next"
        text="See Final Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
    </div>
  </div>
</template>
<script>
import readmore from "../components/readMore.vue"
import loButtons from "../components/loButtons.vue";
export default {
  name: "video-component",
  components: {
    readmore,
    loButtons
  },
  props: ["active_data", "nextStatus"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 50,
      },
      loId: "",
      card_type: "",
      card_data: {},
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      global_video: {},
      sticky_note: [],
      learning_id: "",
      showCertificateOfCompletion: true
    };
  },
  async created() {
    this.intro = this.active_data.attributes.learning_object.attributes;
    this.loId = this.active_data.attributes.learning_object.id;
    this.global_video = this.intro.card_detail.attributes.global_video;
    let attr = this.active_data.attributes;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    let lo_sections = attr.user_section.attributes.learning_object_sections;
    this.learning_id = attr.learning_object_id;
    for (let i = 0; i < lo_sections.length; i++) {
      if (lo_sections[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(lo_sections[i].attributes.sticky_note);
      }
    }
  },
  methods: {
    next() {
      this.$parent.next();
    },
  },
};
</script>

<style scoped lang="scss">
.intro-transcript {
  /deep/ p {
    display: block !important;
  }
}
</style>