<template>
  <div class="display-inline">
    <button
      @click="
        $modal.show('pdfModal', {
          pdfUrl: resourceUrl,
          lable: resource_file_name,
        })
      "
      type="button"
      class="btn btn-link"
      aria-label="Show PDF"
    >
      {{ this.resource_file_name }}
      <span class="visually-hidden">Show PDF</span>
    </button>
  </div>
</template>
<script>
import api from "../services/api";

export default {
  name: "filePopup",
  props: ["resource_id", "resource_file_name"],
  data() {
    return {
      resourceUrl: "",
    };
  },
  methods: {
    getResourceUrl() {
      api.getResourceUrl(this.resource_id).then((res) => {
        this.resourceUrl = res.data.resource_url;
      });
    },
  },
  created() {
    this.getResourceUrl();
  },
};
</script>
<style lang="scss">
.display-inline {
  display: inline-block;
}
</style>