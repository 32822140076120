var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.dialogicFeedbackItem.name))]),(_vm.dialogicFeedbackItem.reattempts_present)?_c('div',[_c('div',{staticClass:"attempts-tabs"},_vm._l((_vm.dialogicFeedbackItem.total_attempts_used_on_lo),function(n){return _c('button',{key:n,class:n === 1
            ? `attempt-tab active-reattempt-tab ${[_vm.dialogicFeedbackItem.lo_id]}`
            : `attempt-tab ${[_vm.dialogicFeedbackItem.lo_id]}`,attrs:{"id":_vm.dialogicFeedbackItem.data[n - 1].id,"data-loid":_vm.dialogicFeedbackItem.lo_id},on:{"click":function($event){return _vm.changeReattemptTab(_vm.dialogicFeedbackItem.data[n - 1].id)}}},[_vm._v(" Attempt "+_vm._s(_vm.dialogicFeedbackItem.total_attempts_used_on_lo - n + 1)+" ")])}),0),_vm._l((_vm.dialogicFeedbackItem.total_attempts_used_on_lo),function(n){return _c('div',{key:n,class:n === 1
          ? 'active-details details-ulo' +
            [_vm.dialogicFeedbackItem.data[n - 1].id] +
            ' details-lo-' +
            [_vm.dialogicFeedbackItem.lo_id]
          : 'details-hide details-ulo-' +
            [_vm.dialogicFeedbackItem.data[n - 1].id] +
            ' details-lo-' +
            [_vm.dialogicFeedbackItem.lo_id],attrs:{"id":'details-' + _vm.dialogicFeedbackItem.data[n - 1].id}},[(
          _vm.dialogicFeedbackItem.data[n - 1] &&
          _vm.dialogicFeedbackItem.data[n - 1].attributes &&
          _vm.dialogicFeedbackItem.data[n - 1].attributes.dialogic_debrief_evaluation
        )?_c('div',_vm._l((_vm.dialogicFeedbackItem.data[n - 1]
            .attributes.dialogic_debrief_evaluation),function(dialogicList,key){return _c('div',{key:dialogicList.id,staticClass:"dialogic-final-feedback-item"},_vm._l((_vm.dialogicFeedbackItem.data[n - 1].attributes
              .dialogic_debrief_evaluation[key]),function(dialogic){return _c('div',{key:dialogic.id},[_c('p',{staticClass:"feedback-item-title"},[_vm._v(" "+_vm._s(dialogic.attributes.question_concept)+" ")]),_c('div',{staticClass:"item"},[(
                  dialogic.attributes.key_topic_missed &&
                  !_vm.dialogicFeedbackItem.data[n - 1].attributes
                    .hide_assessment_label
                )?_c('div',{staticClass:"feedback-icon item-image"}):(
                  !_vm.dialogicFeedbackItem.data[n - 1].attributes
                    .hide_assessment_label
                )?_c('div',{staticClass:"feedback-icon item-image"},[(dialogic.attributes.assessment_icon_url)?_c('img',{attrs:{"src":dialogic.attributes.assessment_icon_url,"alt":dialogic.attributes.assessment_label}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"item-content"},[(dialogic.attributes.key_topic_missed)?_c('div',[(
                      !_vm.dialogicFeedbackItem.data[n - 1].attributes
                        .hide_assessment_label
                    )?_c('div',{staticClass:"label"},[_vm._v(" Missed ")]):_vm._e()]):_c('div',[(
                      !_vm.dialogicFeedbackItem.data[n - 1].attributes
                        .hide_assessment_label
                    )?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(dialogic.attributes.assessment_label)+" ")]):_vm._e()]),(dialogic.attributes.topic_label)?_c('h4',[_vm._v(" "+_vm._s(dialogic.attributes.topic_label)+" ")]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(dialogic.attributes.debrief_received)}})])])])}),0)}),0):_c('div',[_c('div',{staticClass:"item"},[(
              _vm.dialogicFeedbackItem.assessment &&
              !_vm.dialogicFeedbackItem.hide_assessment_label
            )?_c('div',{staticClass:"item-image feedback-icon"},[(
                _vm.dialogicFeedbackItem.assessment.attributes.assessment_icon_url
              )?_c('img',{attrs:{"src":_vm.dialogicFeedbackItem.assessment.attributes.assessment_icon_url,"alt":_vm.dialogicFeedbackItem.assessment.attributes.assessment_label}}):_vm._e()]):_vm._e(),(_vm.dialogicFeedbackItem.assessment)?_c('div',{staticClass:"item-content"},[(!_vm.dialogicFeedbackItem.hide_assessment_label)?_c('div',{staticClass:"label"},[_c('h4',[_vm._v(" "+_vm._s(_vm.dialogicFeedbackItem.assessment.attributes.assessment_label ? _vm.dialogicFeedbackItem.assessment.attributes.assessment_label : "Missed")+" ")])]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(
                _vm.dialogicFeedbackItem.assessment.attributes.feedback
                  ? _vm.dialogicFeedbackItem.assessment.attributes.feedback
                  : ''
              )}})]):_vm._e()])])])})],2):_c('div',[(
        _vm.dialogicFeedbackItem.data &&
        _vm.dialogicFeedbackItem.data.attributes &&
        _vm.dialogicFeedbackItem.data.attributes.dialogic_debrief_evaluation
      )?_c('div',_vm._l((_vm.dialogicFeedbackItem.data.attributes
          .dialogic_debrief_evaluation),function(dialogicList,key){return _c('div',{key:dialogicList.id,staticClass:"dialogic-final-feedback-item"},_vm._l((_vm.dialogicFeedbackItem.data.attributes
            .dialogic_debrief_evaluation[key]),function(dialogic){return _c('div',{key:dialogic.id},[_c('p',{staticClass:"feedback-item-title"},[_vm._v(" "+_vm._s(dialogic.attributes.question_concept)+" ")]),_c('div',{staticClass:"item"},[(
                dialogic.attributes.key_topic_missed &&
                !_vm.dialogicFeedbackItem.data.attributes.hide_assessment_label
              )?_c('div',{staticClass:"feedback-icon item-image"}):(
                !_vm.dialogicFeedbackItem.data.attributes.hide_assessment_label
              )?_c('div',{staticClass:"feedback-icon item-image"},[(dialogic.attributes.assessment_icon_url)?_c('img',{attrs:{"src":dialogic.attributes.assessment_icon_url,"alt":dialogic.attributes.assessment_label}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"item-content"},[(dialogic.attributes.key_topic_missed)?_c('div',[(
                    !_vm.dialogicFeedbackItem.data.attributes.hide_assessment_label
                  )?_c('div',{staticClass:"label"},[_vm._v(" Missed ")]):_vm._e()]):_c('div',[(
                    !_vm.dialogicFeedbackItem.data.attributes.hide_assessment_label
                  )?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(dialogic.attributes.assessment_label)+" ")]):_vm._e()]),(dialogic.attributes.topic_label)?_c('h4',[_vm._v(" "+_vm._s(dialogic.attributes.topic_label)+" ")]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(dialogic.attributes.debrief_received)}})])])])}),0)}),0):_c('div',[_c('div',{staticClass:"item"},[(
            _vm.dialogicFeedbackItem.assessment &&
            !_vm.dialogicFeedbackItem.hide_assessment_label
          )?_c('div',{staticClass:"item-image feedback-icon"},[(_vm.dialogicFeedbackItem.assessment.attributes.assessment_icon_url)?_c('img',{attrs:{"src":_vm.dialogicFeedbackItem.assessment.attributes.assessment_icon_url,"alt":_vm.dialogicFeedbackItem.assessment.attributes.assessment_label}}):_vm._e()]):_vm._e(),(_vm.dialogicFeedbackItem.assessment)?_c('div',{staticClass:"item-content"},[(!_vm.dialogicFeedbackItem.hide_assessment_label)?_c('div',{staticClass:"label"},[_c('h4',[_vm._v(" "+_vm._s(_vm.dialogicFeedbackItem.assessment.attributes.assessment_label ? _vm.dialogicFeedbackItem.assessment.attributes.assessment_label : "Missed")+" ")])]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.dialogicFeedbackItem.assessment.attributes.feedback
                ? _vm.dialogicFeedbackItem.assessment.attributes.feedback
                : ''
            )}})]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }