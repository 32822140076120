<template>
  <div>
    <div class="wistia_responsive_wrapper">
      <iframe
        :src="preIframUrl + wistia_code + postIframUrl"
        title="Video.mp4"
        class="wistia_embed"
        name="wistia_embed"
        allowtransparency="true"
        scrolling="no"
        allowfullscreen
        mozallowfullscreen
        webkitallowfullscreen
        oallowfullscreen
        msallowfullscreen
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "videoPopup",
  props: ["wistia_code"],
  data() {
    return {
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
    };
  },
};
</script>