<template>
  <modal
    name="finalFeedbackLlmDialogicModal"
    role="dialog"
    aria-label="finalFeedbackLlmDialogicModal"
    class="modal-fullScreen"
    width="100%"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <!-- <FocusLoop :is-visible="isOpen"> -->
    <div
      class="modal-content modal-final-content modal-dialogic-content final-feedback-modals"
    >
      <button
        type="button"
        class="modal-close"
        aria-label="Close Modal"
        @click="$modal.hide('finalFeedbackLlmDialogicModal')"
      >
        <em class="icon-cross"></em>
      </button>
      <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
        <div class="modal-body m-auto">
          <div class="modal-title">
            <div class="final-feedback-title">
              <h1>Final Feedback</h1>
            </div>
            <div class="grading">
              <h2>Grading</h2>
              <p>
                <span class="score">{{ totalScore }}</span> / {{ maxScore }}
              </p>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <h2>Here is your performance in this Module:</h2>
              <p v-if="grade === 'mastery'">
                Excellent! Your overall score in the debriefs with Jeff was
                {{ ((totalScore / maxScore) * 100).toFixed(0) }}%. Just a
                reminder, at any point in time you can always go back and review
                the specific feedback provided for each debrief.
              </p>
              <p v-if="grade === 'competent'">
                Good job. Your overall score in the debriefs with Jeff was
                {{ ((totalScore / maxScore) * 100).toFixed(0) }}%. Just a
                reminder, at any point in time you can always go back and review
                the specific feedback provided for each debrief. Take some time
                to review where you may have missed some points on the debriefs,
                and determine how to improve your information-gathering skills
                for the future.
              </p>
              <p v-if="grade === 'developing'">
                Your overall score in the debriefs with Jeff was
                {{ ((totalScore / maxScore) * 100).toFixed(0) }}%. Just a
                reminder, at any point in time you can always go back and review
                the specific feedback provided for each debrief. Remember to
                always ask the 3 core questions at your consultations, as well
                as the additional supplementary questions when a patient’s INR
                is out of range. Asking these questions in a systematic manner
                is important for gathering the information you will need when
                determining what to prescribe in your debriefs.
              </p>
            </div>
          </div>
          <div class="modal-btn pb-5">
            <router-link to="/dashboard" class="btn secondary">
              Go To Dashboard
              <em class="icon-arrow-right"></em>
            </router-link>
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
    <!-- </FocusLoop> -->
  </modal>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: "finalFeedbackLlmDialogicModal",
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      isOpen: true,
      student_id: "",
      feedback_data: [],
      feedbackData: "",
      gradeData: [],
      totalScore: 0,
      grade: "",
      maxScore: 0,
      settings: {
        maxScrollbarLength: 30,
      },
    };
  },
  methods: {
    beforeOpen(event) {
      console.log(event);
      this.feedbackData = event.params.feedbackData;
      this.getFinalLlmDialogicFeedback(event.params.feedbackData);
    },
    beforeClose(event) {
      console.log(event);
      this.resetData();
    },
    resetData() {
      this.feedbackData = "";
      this.totalScore = 0;
      this.grade = "";
      this.maxScore = 0;
      this.gradeData = [];
    },
    getFinalLlmDialogicFeedback(data) {
      console.log(data);
      this.feedbackData.forEach((feedback) => {
        let assessment_results = Object.values(feedback.assessment_results);
        let filteredData = assessment_results.filter(item => 'result' in item);
        let newObj = {
          1: parseFloat(filteredData[0].result),
          2: parseFloat(filteredData[1].result),
          3: parseFloat(filteredData[2].result),
          new_scoring: true,
        };
        this.gradeData.push(newObj);
      });
      this.calculateTotalScore();
    },
    calculateTotalScore() {
      console.log("*** this.gradeData in calculateTotalScore", this.gradeData);
      this.gradeData.forEach((feedback) => {
        if (feedback.new_scoring === true) {
          this.maxScore += 5;
        } else {
          this.maxScore += 5;
        }
        let score =
          parseFloat(feedback[1]) +
          parseFloat(feedback[2]) +
          parseFloat(feedback[3]);
        this.totalScore = this.totalScore + score;
      });
      if (this.totalScore / this.maxScore >= 0.8) {
        this.grade = "mastery";
      } else if (
        this.totalScore / this.maxScore >= 0.5 &&
        this.totalScore / this.maxScore < 0.8
      ) {
        this.grade = "competent";
      } else {
        this.grade = "developing";
      }
    },
  },
  channels: {
    LlmDialogicFeedbackChannel: {
      connected() {
        console.log("I am connected.");
      },
      received(data) {
        console.log(data);
        this.$cable.unsubscribe("LlmDialogicFeedbackChannel");
      },
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  width: 100%;
  text-align: right;
}
h1 {
  font-family: "muliblack";
  font-size: 40px;
  line-height: 100%;
  margin: 30px 0;
}
h2 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin: 50px 0 10px;
}
h3 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin-bottom: 1.4em;
}
h4 {
  font-family: "muliregular";
  font-size: 16px;
  margin: 0 0 5px;
}
h5 {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
.row {
  padding: 40px 0;
  border-bottom: #eaeaea 1px solid;
}
.row:last-child {
  border-bottom: 0;
}
.dialogic-final-feedback-item {
  margin-bottom: 3em;
  display: block;
  float: left;
  width: 100%;

  .item {
    margin: 10px 0 0;
  }
  .feedback-item-title {
    font-size: 16px;
    font-family: "muliextrabold";
    margin: 14px 0 0;
    color: #000000;
  }
}
.item {
  margin: 20px 0 0;
  float: left;
  width: 100%;
}
.item-image {
  float: left;
  width: 78px;
  height: 78px;
  margin: 0 25px 0 0;
}
.item-image img {
  max-width: 100%;
}
.item-content {
  float: left;
  margin: 3px 0 0;
}
.label {
  font-weight: bold;
  font-family: "muliblack";
  font-size: 13px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  color: black;
}
.grading {
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.grading-p {
  font-size: 32px;
  margin-bottom: 16px;
}
.grade-score-head {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.grade-score-head h3 {
  float: left;
}
.grade-score-head p {
  float: right;
  font-family: "mulibold";
}
.item-score {
  background-color: #3dbc9e;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 24px;
}
.item-score {
  h3 {
    font-family: "mulisemibold";
    font-size: 20px;
    line-height: 100%;
    color: #fff;
    margin: 12px 0 0;
  }
}
.grade-score {
  font-family: "mulibold" !important;
  float: right;
  margin-top: 5px;
}
.item-score-head {
  font-family: "mulibold" !important;
  margin-bottom: 5px;
}
.item-label {
  color: #3dbc9e;
  font-size: 14px;
  margin: 0;
}
.container {
  width: 100%;
  margin-left: 0;
}
.final-feedback-title {
  font-size: 35px;
}

.details-hide {
  display: none;
}
.active-details {
  display: block;
}

.attempts-tabs {
  display: flex;
  padding-left: 0;
  .attempt-tab {
    border: 1px solid #ccc;
    padding: 1em;
    display: inline-flex;
    margin-right: 1em;
    cursor: pointer;
  }
  .active-reattempt-tab {
    background: #3dbc9e;
    color: white;
  }
}

.coding-concept {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
</style>

