<template>
  <div>
    <modal
      name="emailFeedbackInfo"
      aria-label="emailFeedbackInfo"
      role="dialog"
      width="560"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-info-content">
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('emailFeedbackInfo')"
          >
            <em class="icon-cross"></em>
          </button>
          <div class="modal-body" v-if="infoModalValues.attributes">
            <h3>How {{ skillName }} is Assessed.</h3>
            <p
              v-if="
                infoModalValues.attributes.email_skill.eval_explanation != null
              "
            >
              {{ infoModalValues.attributes.email_skill.eval_explanation }}
            </p>
            <p
              v-if="
                infoModalValues.attributes.email_skill.eval_explanation == null &&
                infoModalValues.attributes.global_skill.eval_explanation != null
              "
            >
              {{ infoModalValues.attributes.global_skill.eval_explanation }}
            </p>
          </div>
        </div>
      </FocusLoop>
    </modal>
  </div>
</template>

<script>
export default {
  name: "FeedbackInfoModal",
  data() {
    return {
      skillName: "",
      infoModalValues: {
        attributes: {
          email_skill: {
            eval_explanation: "",
          },
          global_skill: {
            eval_explanation: "",
          },
        },
      },
      isOpen: false,
    };
  },
  methods: {
    beforeOpen(event) {
      this.infoModalValues = event.params.infoModalValues;
      this.skillName = event.params.skillName;
      this.isOpen = true;
    },
  },
};
</script>