<template>
  <modal
    name="finalFeedback"
    role="dialog"
    aria-label="FinalFeedbackModal"
    class="modal-fullScreen"
    width="100%"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @opened="opened"
    @close="closeModal"
  >
    <FocusLoop :is-visible="isOpen">
      <div
        class="
          modal-content modal-final-content modal-dialogic-content
          final-feedback-modals
        "
      >
        <button
          type="button"
          class="modal-close"
          aria-label="Close Modal"
          @click="closeModal()"
        >
          <em class="icon-cross"></em>
        </button>
        <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
          <div class="modal-body m-auto">
            <final-feedback-header
              :headerInfo="finalDebrief"
              :disableReportBtn="disableReportBtn"
              :enableGrading="enable_grading"
              :overallGradeDisplayType="overall_grade_display_type"
              :overallGradeLabel="overall_grade_label"
              :overallGradePercentage="overall_grade_percentage"
              :pdfUrl="pdfUrl"
            ></final-feedback-header>
            <div class="container" :key="attemptRefreshKey">
              <div class="row">
                <h2>Here is your performance in this Module:</h2>
              </div>
              <div v-for="finalDebriefItem in finalDebrief.data" :key="finalDebriefItem.id">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <llmChatFinalFeedback
                      v-if="finalDebriefItem.card_type === 'llm_chat'"
                      :llmChatFeedbackItem="finalDebriefItem"
                    />
                    <llmDialogicFinalFeedback
                      v-if="finalDebriefItem.card_type === 'llm_dialogic'"
                      :llmDialogicFeedbackItem="finalDebriefItem"
                    />
                    <llmEmailFinalFeedback 
                      v-if="finalDebriefItem.card_type === 'llm_email'"
                      :llmEmailFeedbackItem="finalDebriefItem"
                    />
                    <codingFinalFeedback 
                      v-if="finalDebriefItem.card_type === 'coding'"
                      :codingFeedbackItem="finalDebriefItem"
                    />
                    <dialogicFinalFeedback 
                      v-if="finalDebriefItem.card_type === 'dialogic'"
                      :dialogicFeedbackItem="finalDebriefItem"
                    />
                    <!-- Component goes here -->
                    <div
                      v-if="
                        finalDebriefItem.card_type === 'submission_email' &&
                        finalDebriefItem.data != null
                      "
                    >
                      <div class="grade-score-head">
                        <h3>{{ finalDebriefItem.name }}</h3>
                        <h4
                          v-if="finalDebriefItem.display_grade && finalDebriefItem.published"
                          class="grade-score"
                        >
                          Grade: {{ Math.round(finalDebriefItem.overall_grade) }}
                        </h4>
                        <h4
                          v-if="finalDebriefItem.display_grade && !finalDebriefItem.published"
                          class="grade-score"
                        >
                          Grade: Not Yet Graded
                        </h4>
                        <p v-if="finalDebriefItem.data.attributes.feedback">
                          Instructor notes:
                        </p>
                        <div
                          v-html="finalDebriefItem.data.attributes.feedback"
                          v-if="finalDebriefItem.data.attributes.feedback"
                        ></div>
                      </div>

                      <div
                        v-if="
                          finalDebriefItem.data.attributes.submission_gradings.length > 0 &&
                          finalDebriefItem.published
                        "
                      >
                        <div
                          v-for="submission in finalDebriefItem.data.attributes
                            .submission_gradings"
                          :key="submission.id"
                        >
                          <div class="item">
                            <div class="item-image item-score">
                              {{ submission.attributes.score }}
                            </div>
                            <div class="item-content">
                              <h4 class="label item-label">
                                {{ submission.attributes.rubric_criteria }}
                              </h4>
                              <div class="item-score-head">
                                <strong>
                                  Weight
                                  {{ submission.attributes.rubric_weight }}%
                                </strong>
                              </div>
                              <p
                                v-html="submission.attributes.rubric_feedback"
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="item-label">
                          Your submission needs to be graded by your instructor.
                          You will receive an email notification when your grade
                          is ready.
                        </div>
                      </div>
                    </div>
                    <!-- Component goes here -->
                    <div
                      v-if="finalDebriefItem.card_type === 'submission'"
                      class="submission-grading"
                    >
                      <h3>{{ finalDebriefItem.name }}</h3>

                      <div class="grade-score-head">
                        <h4
                          v-if="finalDebriefItem.display_grade && finalDebriefItem.published"
                          class="grade-score"
                        >
                          Grade: {{ Math.round(finalDebriefItem.overall_grade) }}
                        </h4>
                        <h4
                          v-if="finalDebriefItem.display_grade && !finalDebriefItem.published"
                          class="grade-score"
                        >
                          Grade: Not Yet Graded
                        </h4>
                        <p v-if="finalDebriefItem.feedback">Instructor notes:</p>

                        <div v-if="finalDebriefItem.feedback" v-html="finalDebriefItem.feedback"></div>
                      </div>
                      <div v-if="finalDebriefItem.overall_grade > 0 && finalDebriefItem.published">
                        <div
                          v-for="submission in finalDebriefItem.data"
                          :key="submission.id"
                        >
                          <div class="item">
                            <div class="item-image item-score" v-if="finalDebriefItem.display_grade">
                              {{ submission.attributes.score }}
                            </div>
                            <div class="item-content" v-if="finalDebriefItem.display_grade">
                              <h4 class="label item-label">
                                {{ submission.attributes.rubric_criteria }}
                              </h4>
                              <div class="item-score-head" v-if="finalDebriefItem.display_grade">
                                <strong>
                                  Weight
                                  {{ submission.attributes.rubric_weight }}%
                                </strong>
                              </div>
                              <p
                                v-html="submission.attributes.rubric_feedback"
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="item-label">
                          Your submission needs to be graded by your instructor.
                          You will receive an email notification when your grade
                          is ready.
                        </div>
                      </div>
                    </div>
                    <!-- Component goes here -->
                    <div v-if="finalDebriefItem.card_type === 'quiz'">
                      <h3>{{ finalDebriefItem.name }}</h3>
                      <div class="item">
                        <div
                          class="item-image feedback-icon"
                          v-if="
                            finalDebriefItem.assessment &&
                            finalDebriefItem.assessment.attributes.assessment_icon_url
                          "
                        >
                          <img
                            v-if="
                              finalDebriefItem.assessment.attributes.assessment_icon_url
                            "
                            :src="
                              finalDebriefItem.assessment.attributes.assessment_icon_url
                            "
                            :alt="finalDebriefItem.assessment.attributes.assessment_label"
                          />
                        </div>
                        <div class="item-content">
                          <div class="item-content" >
                            <p
                              v-html="
                                finalDebriefItem.feedback
                                  ? 'Score:' + finalDebriefItem.feedback
                                  : 'Score: N/A'
                              "
                            ></p>
                            <p
                              v-if="
                                finalDebriefItem.assessment && finalDebriefItem.assessment.attributes
                              "
                              v-html="finalDebriefItem.assessment.attributes.feedback"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Component goes here -->
                    <div v-if="finalDebriefItem.card_type === 'email'" class="email-item">
                      <div v-if="finalDebriefItem.reattempts_present">
                        <h3>{{ finalDebriefItem.name }}</h3>

                        <div class="attempts-tabs">
                          <button
                            v-for="n in finalDebriefItem.total_attempts_used_on_lo"
                            :class="
                              n === 1
                                ? 'attempt-tab active-reattempt-tab' +
                                  ' ' +
                                  [finalDebriefItem.lo_id]
                                : 'attempt-tab' + ' ' + [finalDebriefItem.lo_id]
                            "
                            :id="finalDebriefItem.data[n - 1].id"
                            :key="n"
                            :data-loid="finalDebriefItem.lo_id"
                            @click="changeReattemptTab(finalDebriefItem.data[n - 1].id)"
                          >
                            Attempt {{ (finalDebriefItem.total_attempts_used_on_lo - n) + 1 }}
                          </button>
                        </div>

                        <div
                          v-for="n in finalDebriefItem.total_attempts_used_on_lo"
                          :key="n"
                          :id="'details-' + finalDebriefItem.data[n - 1].id"
                          :class="
                            n === 1
                              ? 'active-details details-ulo' +
                                [finalDebriefItem.data[n - 1].id] +
                                ' details-lo-' +
                                [finalDebriefItem.lo_id]
                              : 'details-hide details-ulo-' +
                                [finalDebriefItem.data[n - 1].id] +
                                ' details-lo-' +
                                [finalDebriefItem.lo_id]
                          "
                        >
                          <div v-if="finalDebriefItem.data">
                            <div
                              v-if="
                                finalDebriefItem.data[n - 1].attributes &&
                                finalDebriefItem.data[n - 1].attributes
                                  .learner_debrief_items &&
                                finalDebriefItem.data[n - 1].attributes
                                  .learner_debrief_items.length > 0
                              "
                            >
                              <div
                                v-for="email in finalDebriefItem.data[n - 1].attributes
                                  .learner_debrief_items"
                                :key="email.id"
                              >
                                <div class="item">
                                  <div
                                    class="item-image feedback-icon"
                                    v-if="
                                      !finalDebriefItem.data[n - 1].attributes
                                        .hide_assessment_label
                                    "
                                  >
                                    <img
                                      :src="
                                        email.attributes.email_assessment_item
                                          .attributes.assessment_icon_url
                                      "
                                      :alt="
                                        email.attributes.email_assessment_item
                                          .attributes.assessment_label
                                      "
                                    />
                                  </div>
                                  <div class="item-content">
                                    <div
                                      class="label"
                                      v-if="
                                        !finalDebriefItem.data[n - 1].attributes
                                          .hide_assessment_label
                                      "
                                    >
                                      <h4>
                                        {{
                                          email.attributes.email_assessment_item
                                            .attributes.assessment_label
                                        }}
                                      </h4>
                                    </div>
                                    <h4>
                                      {{ email.attributes.global_skill.name }}
                                    </h4>
                                    <div
                                      v-html="email.attributes.debrief_content"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="
                                finalDebriefItem.data[n - 1].attributes &&
                                finalDebriefItem.data[n - 1].attributes
                                  .user_email_assessment_items &&
                                finalDebriefItem.data[n - 1].attributes
                                  .user_email_assessment_items.length > 0
                              "
                            >
                              <div
                                v-for="email in finalDebriefItem.data.attributes
                                  .user_email_assessment_items"
                                :key="email.id"
                              >
                                <div class="item">
                                  <div
                                    class="feedback-icon item-image"
                                    v-if="
                                      !finalDebriefItem.data[n - 1].attributes
                                        .hide_assessment_label
                                    "
                                  >
                                    <img
                                      :src="
                                        email.attributes.email_assessment_item
                                          .attributes.assessment_icon_url
                                      "
                                      :alt="
                                        email.attributes.email_assessment_item
                                          .attributes.assessment_label
                                      "
                                    />
                                  </div>
                                  <div
                                    class="item-content"
                                    v-if="
                                      !finalDebriefItem.data[n - 1].attributes
                                        .hide_assessment_label
                                    "
                                  >
                                    <div class="label">
                                      {{
                                        email.attributes.email_assessment_item
                                          .attributes.assessment_label
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else class="item">
                            <div
                              class="item-image"
                              v-if="
                                finalDebriefItem.assessment && !finalDebriefItem.hide_assessment_label
                              "
                            >
                              <img
                                v-if="
                                  finalDebriefItem.assessment.attributes.assessment_icon_url
                                "
                                :src="
                                  finalDebriefItem.assessment.attributes.assessment_icon_url
                                "
                                :alt="
                                  finalDebriefItem.assessment.attributes.assessment_label
                                "
                              />
                            </div>
                            <div class="item-content" v-if="finalDebriefItem.assessment">
                              <div
                                class="label"
                                v-if="!finalDebriefItem.hide_assessment_label"
                              >
                                {{
                                  finalDebriefItem.assessment.attributes.assessment_label
                                    ? finalDebriefItem.assessment.attributes
                                        .assessment_label
                                    : "Missed"
                                }}
                              </div>
                              <p
                                v-html="
                                  finalDebriefItem.assessment.attributes.feedback
                                    ? finalDebriefItem.assessment.attributes.feedback
                                    : ''
                                "
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-else>
                        <h3>{{ finalDebriefItem.name }}</h3>
                        <div v-if="finalDebriefItem.data">
                          <div
                            v-if="
                              finalDebriefItem.data.attributes &&
                              finalDebriefItem.data.attributes.learner_debrief_items &&
                              finalDebriefItem.data.attributes.learner_debrief_items
                                .length > 0
                            "
                          >
                            <div
                              v-for="email in finalDebriefItem.data.attributes
                                .learner_debrief_items"
                              :key="email.id"
                            >
                              <div class="item">
                                <div
                                  class="item-image feedback-icon"
                                  v-if="
                                    !finalDebriefItem.data.attributes.hide_assessment_label
                                  "
                                >
                                  <img
                                    :src="
                                      email.attributes.email_assessment_item
                                        .attributes.assessment_icon_url
                                    "
                                    :alt="
                                      email.attributes.email_assessment_item
                                        .attributes.assessment_label
                                    "
                                  />
                                </div>
                                <div class="item-content">
                                  <div
                                    class="label"
                                    v-if="
                                      !finalDebriefItem.data.attributes
                                        .hide_assessment_label
                                    "
                                  >
                                    <h4>
                                      {{
                                        email.attributes.email_assessment_item
                                          .attributes.assessment_label
                                      }}
                                    </h4>
                                  </div>
                                  <h4>
                                    {{ email.attributes.global_skill.name }}
                                  </h4>
                                  <div
                                    v-html="email.attributes.debrief_content"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="
                              finalDebriefItem.data.attributes &&
                              finalDebriefItem.data.attributes
                                .user_email_assessment_items &&
                              finalDebriefItem.data.attributes.user_email_assessment_items
                                .length > 0
                            "
                          >
                            <div
                              v-for="email in finalDebriefItem.data.attributes
                                .user_email_assessment_items"
                              :key="email.id"
                            >
                              <div class="item">
                                <div
                                  class="feedback-icon item-image"
                                  v-if="
                                    !finalDebriefItem.data.attributes.hide_assessment_label
                                  "
                                >
                                  <img
                                    :src="
                                      email.attributes.email_assessment_item
                                        .attributes.assessment_icon_url
                                    "
                                    :alt="
                                      email.attributes.email_assessment_item
                                        .attributes.assessment_label
                                    "
                                  />
                                </div>
                                <div
                                  class="item-content"
                                  v-if="
                                    !finalDebriefItem.data.attributes.hide_assessment_label
                                  "
                                >
                                  <div class="label">
                                    {{
                                      email.attributes.email_assessment_item
                                        .attributes.assessment_label
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="item">
                          <div
                            class="item-image"
                            v-if="
                              finalDebriefItem.assessment && !finalDebriefItem.hide_assessment_label
                            "
                          >
                            <img
                              v-if="
                                finalDebriefItem.assessment.attributes.assessment_icon_url
                              "
                              :src="
                                finalDebriefItem.assessment.attributes.assessment_icon_url
                              "
                              :alt="finalDebriefItem.assessment.attributes.assessment_label"
                            />
                          </div>
                          <div class="item-content" v-if="finalDebriefItem.assessment">
                            <div
                              class="label"
                              v-if="!finalDebriefItem.hide_assessment_label"
                            >
                              {{
                                finalDebriefItem.assessment.attributes.assessment_label
                                  ? finalDebriefItem.assessment.attributes.assessment_label
                                  : "Missed"
                              }}
                            </div>
                            <p
                              v-html="
                                finalDebriefItem.assessment.attributes.feedback
                                  ? finalDebriefItem.assessment.attributes.feedback
                                  : ''
                              "
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-btn pb-5">
              <router-link to="/dashboard" class="btn secondary">
                Go To Dashboard
                <em class="icon-arrow-right"></em>
              </router-link>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </FocusLoop>
  </modal>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import finalFeedbackHeader from './finalFeedback/finalFeedbackHeader.vue';
import codingFinalFeedback from './finalFeedback/codingFinalFeedback.vue';
import dialogicFinalFeedback from './finalFeedback/dialogicFinalFeedback.vue';
import llmChatFinalFeedback from './finalFeedback/llmChatFinalFeedback.vue';
import llmDialogicFinalFeedback from './finalFeedback/llmDialogicFinalFeedback.vue';
import llmEmailFinalFeedback from './finalFeedback/llmEmailFinalFeedback.vue';

export default {
  name: "finalFeedback",
  props: {
    finalDebrief: Object,
    enable_grading: Boolean,
    settings: Object,
    disableReportBtn: Boolean,
    overall_grade_display_type: String,
    overall_grade_label: String,
    overall_grade_percentage: Number,
  },
  components: {
    VuePerfectScrollbar,
    finalFeedbackHeader,
    codingFinalFeedback,
    llmChatFinalFeedback,
    llmDialogicFinalFeedback,
    llmEmailFinalFeedback,
    dialogicFinalFeedback
  },
  data() {
    return {
      headerInfo: this.finalDebrief,
      pdfURL: "",
      id: this.$route.params.id,
      isOpen: false,
      updatedReport: false,
      attemptRefreshKey: 0,
      current_lo_id: "",
      active_reattempt_ids: [],
      llmDialogicFeedback: [],
      debriefData: [],
      pdfUrl: "",
    };
  },
  methods: {
    beforeOpen(event) {
      this.pdfURL = event.params.pdfURL;
      this.llmDialogicFeedback = event.params.llm_dialogic_feedback.result;
      this.debriefData = this.finalDebrief;
      this.isOpen = true;
    },
    opened() {
      let finalFeedbackModal = document.querySelector(".final-feedback-modals");
      finalFeedbackModal.focus();
    },
    closeModal() {
      this.isOpen = false;
      this.$modal.hide("finalFeedback");
    },
    changeReattemptTab(ulo_id) {
      let active_tab = document.getElementById(ulo_id);
      let active_details = document.getElementById("details-" + ulo_id);
      let loid = event.target.getAttribute("data-loid");
      let other_tabs = document.getElementsByClassName(loid);
      let other_details = document.getElementsByClassName("details-lo-" + loid);
      for (var j = 0; j < other_tabs.length; j++) {
        other_tabs[j].classList.remove("active-reattempt-tab");
      }
      active_tab.classList.add("active-reattempt-tab");

      for (var k = 0; k < other_tabs.length; k++) {
        other_details[k].classList.remove("active-details");
        other_details[k].classList.add("details-hide");
      }

      active_details.classList.add("active-details");
      active_details.classList.remove("details-hide");
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  width: 100%;
  text-align: right;
}
h1 {
  font-family: "muliblack";
  font-size: 40px;
  line-height: 100%;
  margin: 30px 0;
}
h2 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin: 50px 0 10px;
}
h3 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin-bottom: 1.4em;
}
h4 {
  font-family: "muliregular";
  font-size: 16px;
  margin: 0 0 5px;
}
h5 {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
.row {
  padding: 40px 0;
  border-bottom: #eaeaea 1px solid;
}
.row:last-child {
  border-bottom: 0;
}
.dialogic-final-feedback-item {
  margin-bottom: 3em;
  display: block;
  float: left;
  width: 100%;

  .item {
    margin: 10px 0 0;
  }
  .feedback-item-title {
    font-size: 16px;
    font-family: "muliextrabold";
    margin: 14px 0 0;
    color: #000000;
  }
}
.item {
  margin: 20px 0 0;
  float: left;
  width: 100%;
}
.item-image {
  float: left;
  width: 78px;
  height: 78px;
  margin: 0 25px 0 0;
}
.item-image img {
  max-width: 100%;
}
.item-content {
  float: left;
  margin: 3px 0 0;
}
.label {
  font-weight: bold;
  font-family: "muliblack";
  font-size: 13px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  color: black;
}
.grading {
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.grading-p {
  font-size: 32px;
  margin-bottom: 16px;
}
.grade-score-head {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.grade-score-head h3 {
  float: left;
}
.grade-score-head p {
  float: right;
  font-family: "mulibold";
}
.item-score {
  background-color: #3dbc9e;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 24px;
}
.item-score {
  h3 {
    font-family: "mulisemibold";
    font-size: 20px;
    line-height: 100%;
    color: #fff;
    margin: 12px 0 0;
  }
}
.grade-score {
  font-family: "mulibold" !important;
  float: right;
  margin-top: 5px;
}
.item-score-head {
  font-family: "mulibold" !important;
  margin-bottom: 5px;
}
.item-label {
  color: #3dbc9e;
  font-size: 14px;
  margin: 0;
}
.container {
  width: 100%;
  margin-left: 0;
}
.final-feedback-title {
  font-size: 35px;
}

.details-hide {
  display: none;
}
.active-details {
  display: block;
}

.attempts-tabs {
  display: flex;
  padding-left: 0;
  .attempt-tab {
    border: 1px solid #ccc;
    padding: 1em;
    display: inline-flex;
    margin-right: 1em;
    cursor: pointer;
  }
  .active-reattempt-tab {
    background: #3dbc9e;
    color: white;
  }
}

.coding-concept {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
</style>

