<template>
  <div id="login" class="login-container">
    <img src="../assets/images/ametros-learning-logo.png" alt="Ametros Learning Logo" class="login-logo" />
    <ValidationObserver ref="resetForm">
      <div class="reset-form mt-2">
        <div v-if="!passwordChanged && invalidToken">
          <p class="mt-4">
            It looks like you clicked on an invalid password reset link. Please try again.
          </p>
          <div class="text-end mt-3">
            <button type="button" class="btn btn-ametros" v-on:click="goToForgotPassword()">
              Forgot password?
            </button>
          </div>
        </div>
        <div v-if="!passwordChanged && !invalidToken">
          <h4>Create new password</h4>
          <div class="form-group mt-4">
            <ValidationProvider name="password" rules="required" v-slot="{ errors }">
              <input
                type="password"
                class="form-control"
                name="password"
                v-model="password"
                aria-label="Create new password"
                placeholder="Password"
                :class="[isActive && errors[0] ? 'invalid' : '']"
              />
            </ValidationProvider>
            <ValidationProvider name="Confirm password" rules="required" v-slot="{ errors }">
              <input
                type="password"
                class="mt-4 form-control"
                name="confirmPassword"
                v-model="confirmPassword"
                aria-label="Confirm Password"
                placeholder="Confirm Password"
                :class="[isActive && errors[0] ? 'invalid' : '']"
              />
               </ValidationProvider>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-ametros" v-on:click="resetPassword()">
              Save
            </button>
          </div>
        </div>
         <div v-if="passwordChanged">
        <h4>Password has been changed</h4>
          <p class="mt-4">
            Your password has been successfully changed. You can now login with your new password.
          </p>
          <div class="text-end mt-3">
            <button type="button" class="btn btn-ametros" v-on:click="returnToLogin()">
              Login
            </button>
          </div>
      </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "ResetPassword",
   components: {
    ValidationProvider,
    ValidationObserver
   },
  data() {
    return {
       invalidToken: '',
        password: '',
        confirmPassword: '',
        isActive: false,
        passwordChanged: false,
        token: ''
    };
  },
  methods: {
    resetPassword() {
     this.$refs.resetForm.validate()
        .then(success => {
          this.isActive = true;
          if (!success) {
            return;
          } else {
            if (this.password !== this.confirmPassword) {
              this.password = ""
              this.confirmPassword = ""
              utilFunctionService.showerr('Please make sure your passwords match');
              return;
            }
            this.isActive = false;
            const _fb = new FormData();
            _fb.append("reset_password[token]", this.token);
            _fb.append("reset_password[password]", this.password);
            api
              .resetPassword(_fb)
              .then(() => {
                this.passwordChanged = true
              }).catch(() => {})
        }
      });
    },
    returnToLogin() {
      this.$router.replace({ name: "login" });
    },
    goToForgotPassword() {
      this.$router.replace({ name: "forgot-password" });
    }
  },
  async created(){
    let route_arr = this.$route.path.split("/");
    this.token = route_arr[route_arr.length - 1];
      api
        .verifyToken(this.token)
        .then((res) => {
          if (res.data.is_valid)
            this.invalidToken = false
          else
           this.invalidToken = true
        });
    }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.login-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
     background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .login-logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .reset-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  h1 {
    @include font-bold;
    text-transform: uppercase;
    font-size: 32px;
    margin: 0 0 30px;
    text-align: center;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
}
</style>
