<template>
  <modal
    name="PdfModal"
    aria-label="PdfModal"
    role="dialog"
    class="pdfmodalvm"
    height="auto"
    :width="1000"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <FocusLoop :is-visible="isOpen">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-header modal-print-header mb-4">
            <div class="modal-print-row">
              <div class="modal-print-nav">
                <div class="data-results">Page {{ page }} of {{ numPages }}</div>
                <div aria-label="PDF page navigation">
                  <ul class="pagination pdf-pagination">
                    <li class="page-item" v-if="page !== 1">
                      <a class="page-link" aria-label="Previous" @click="page--">
                        &lt;
                      </a>
                    </li>

                    <li class="page-item" v-if="page < numPages">
                      <a class="page-link" aria-label="Next" @click="page++">
                        &gt;
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-print-right">
                <button
                  class="btn btn-ametros"
                  @click.prevent="downloadItem(urlLink)"
                  aria-label="Download"
                >
                  Download
                </button>
              </div>
            </div>
            <pdf
              ref="pdf"
              :page="page"
              :source="urlLink"
              @rendered="handleDocumentRender"
            ></pdf>
          </div>
          <div style="width: 100%; align-items: center">
            <div
              v-if="loadedRatio > 0 && loadedRatio < 1"
              style="background-color: green; color: white; text-align: center"
              :style="{ width: loadedRatio * 100 + '%' }"
            >
              {{ Math.floor(loadedRatio * 100) }}%
            </div>
            <pdf
              ref="pdf"
              :src="urlLink"
              :page="page"
              :rotate="rotate"
              @error="error"
              @num-pages="numPages = $event"
              @link-clicked="page = $event"
              @rendered="handleDocumentRender"
            ></pdf>
          </div>
          <div class="modal-bottom modal-print-footer mb-4">
            <div class="modal-print-row">
              <div class="modal-print-nav">
                <div class="data-results">Page {{ page }} of {{ numPages }}</div>
                <div aria-label="PDF page navigation">
                  <ul class="pagination pdf-pagination">
                    <li class="page-item" v-if="page !== 1">
                      <a class="page-link" aria-label="Previous" @click="page--">
                        &lt;
                      </a>
                    </li>

                    <li class="page-item" v-if="page < numPages">
                      <a class="page-link" aria-label="Next" @click="page++">
                        &gt;
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusLoop>
  </modal>
</template>
<script>
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Axios from "axios";
export default {
  name: "PdfModal",
  components: {
    pdf,
  },
  props: ["pdfUrl", "fileName"],
  data() {
    return {
      src: {},
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      urlLink: "",
      label: "",
      isOpen: false
    };
  },
  methods: {
    error: function (err) {
      console.log(err);
    },
    downloadItem() {
      Axios.get(this.urlLink, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    beforeOpen(event) {
      this.src = "";
      this.page = 1;
      this.numPages = 1;
      this.urlLink = "";
      this.urlLink = event.params.pdfUrl;
      this.label = event.params.fileName;
      this.src = this.urlLink;
      this.isOpen = true;
    },
    handleDocumentRender() {
      this.numPages = this.$refs.pdfRef.pageCount
    },
  },
};
</script>

<style scoped lang="scss">
.pdf-pagination {
  padding-left: 2em;
}
</style>
