<template>
  <div class="top-back">
    <router-link to="/active" class="dashboard-active-item">
      <em class="icon-back"></em>
      Back to Dashboard
    </router-link>
  </div>
</template>
<script>

export default {
  name: "backDashboard",
  data() {
    return {
    };
  }
};
</script>

<style scoped lang="scss">
  .top-back {
    padding: 2em;
    a {
      font-size: 12px;
      display: flex;
      align-items: center;

      em {
        margin: 1px 3px 0 0;
      }
    }
}

</style>