<template>
  <div id="app">
    <router-view></router-view>
    <v-dialog />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "./styles/style.scss";

.vm--block-scroll {
  overflow: hidden !important;
  width: 100% !important;
}
.ps__rail-x {
  height: 6px !important;
  background-color: transparent !important;
}
.ps__thumb-x {
  height: 5px !important;
  background-color: #ddd !important;
}
// .vm--overlay {
//   background: rgba(0, 0, 0, 0.74) !important;
// }
.ps__rail-y {
  width: 6px !important;
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:hover>.ps__thumb-y {
  width: 4px !important;
}
.vm--modal {
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  overflow: visible !important;
  @include media-breakpoint(sm) {
    width: 100% !important;
    left: 0 !important;
    margin-bottom: 0 !important;
  }
}

.vue-dialog {
    background-color: $white !important;
}

.vm--container {
    &.pdfmodalvm {
    &.scrollable {
      @include media-breakpoint(md) {
        overflow: auto !important;
      }
    }
  }
  &.scrollable {
    @include media-breakpoint(md) {
      overflow: hidden !important;
    }
  }
}

</style>
