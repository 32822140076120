<script>
import Vue from 'vue'
import videoPopup from "../components/videoPopup";
import filePopup from "../components/filePopup";

const Tokenizer = require('../utils/shortcode-tokenizer.js').default

/* map from code to component */
const codeMap = {
  'videoPopup': videoPopup,
  'filePopup': filePopup
}

function renderToken(token) {
  return wrapKeepAlive(
      renderText(
        renderSelfClosing(
         token
        )
      )
    ).output
}

function wrapKeepAlive(token) {
  if (typeof token.params['keep-alive'] !== 'undefined') {
    token.output = `<keep-alive>${token.output}</keep-alive>`
  }
  return token
}

function renderParams(token) {
  if (Object.keys(token.params)) {
    return ' ' + Object.entries(token.params)
      .map(pair => {
        if (pair[0] === 'keep-alive') {
          return null
        }
        let value = pair[1]
        let key = ':' + pair[0]
        if (typeof pair[1] === 'string') {
          value = `"${value}"`
          key = pair[0]
        }
        return `${key}=${value}`
      })
      .join(' ')
  }
  return ''
}

function renderText(token) {
  if (token.type === Tokenizer.TEXT || token.type === Tokenizer.ERROR) {
    token.output = token.body
  }
  return token
}

function ensureOneRoot( content) {
  return `<div>${content}</div>`;
}
function renderSelfClosing(token) {
  if (token.type === Tokenizer.SELF_CLOSING) {
    let name = getComponentName(token)
    let params = renderParams(token)
    token.output = `<${name}${params}></${name}>`
  }
  return token
}

function getComponentName(token) {
   console.log("getComponentName", token)
  if (typeof codeMap[token.name] === 'undefined') {
    throw new Error(`Unknown code: ${token.name}`)
  }
  return token.name
}

export default {
  name: "codeSlot",
  components: {
    videoPopup
  },
  props: ["content"],
  data() {
    return {
    }
  },
  methods: {
      renderContent() {
        try {
            let ast = this.tokenizer.ast()
            let content = ast
            .map(renderToken)
            .join('')
            return ensureOneRoot(content)
        } catch (err) {
            // TODO use error component
            console.error(err)
            return `<div class="error">${err.message}</div>`
        }
    }
  },
  created() {
    this.tokenizer = new Tokenizer(this.content)
  },
  render(h) {
    return h(Vue.component('code-wrapper', {
      template: this.renderContent(),
      components: {videoPopup, filePopup}
    }))
  }
}
</script>
