<template>
  <div>
    <h3>{{ dialogicFeedbackItem.name }}</h3>

    <div v-if="dialogicFeedbackItem.reattempts_present">
      <div class="attempts-tabs">
        <!-- Rename `n` to something more meaningful -->
        <button
          v-for="n in dialogicFeedbackItem.total_attempts_used_on_lo"
          :class="
            n === 1
              ? `attempt-tab active-reattempt-tab ${[dialogicFeedbackItem.lo_id]}`
              : `attempt-tab ${[dialogicFeedbackItem.lo_id]}`
          "
          :id="dialogicFeedbackItem.data[n - 1].id"
          :key="n"
          :data-loid="dialogicFeedbackItem.lo_id"
          @click="changeReattemptTab(dialogicFeedbackItem.data[n - 1].id)"
        >
          Attempt {{ dialogicFeedbackItem.total_attempts_used_on_lo - n + 1 }}
        </button>
      </div>

      <div
        v-for="n in dialogicFeedbackItem.total_attempts_used_on_lo"
        :key="n"
        :id="'details-' + dialogicFeedbackItem.data[n - 1].id"
        :class="
          n === 1
            ? 'active-details details-ulo' +
              [dialogicFeedbackItem.data[n - 1].id] +
              ' details-lo-' +
              [dialogicFeedbackItem.lo_id]
            : 'details-hide details-ulo-' +
              [dialogicFeedbackItem.data[n - 1].id] +
              ' details-lo-' +
              [dialogicFeedbackItem.lo_id]
        "
      >
        <div
          v-if="
            dialogicFeedbackItem.data[n - 1] &&
            dialogicFeedbackItem.data[n - 1].attributes &&
            dialogicFeedbackItem.data[n - 1].attributes.dialogic_debrief_evaluation
          "
        >
          <div
            v-for="(dialogicList, key) in dialogicFeedbackItem.data[n - 1]
              .attributes.dialogic_debrief_evaluation"
            :key="dialogicList.id"
            class="dialogic-final-feedback-item"
          >
            <div
              v-for="dialogic in dialogicFeedbackItem.data[n - 1].attributes
                .dialogic_debrief_evaluation[key]"
              :key="dialogic.id"
            >
              <p class="feedback-item-title">
                {{ dialogic.attributes.question_concept }}
              </p>
              <div class="item">
                <div
                  class="feedback-icon item-image"
                  v-if="
                    dialogic.attributes.key_topic_missed &&
                    !dialogicFeedbackItem.data[n - 1].attributes
                      .hide_assessment_label
                  "
                ></div>
                <div
                  class="feedback-icon item-image"
                  v-else-if="
                    !dialogicFeedbackItem.data[n - 1].attributes
                      .hide_assessment_label
                  "
                >
                  <img
                    v-if="dialogic.attributes.assessment_icon_url"
                    :src="dialogic.attributes.assessment_icon_url"
                    :alt="dialogic.attributes.assessment_label"
                  />
                </div>
                <div class="item-content">
                  <div v-if="dialogic.attributes.key_topic_missed">
                    <div
                      class="label"
                      v-if="
                        !dialogicFeedbackItem.data[n - 1].attributes
                          .hide_assessment_label
                      "
                    >
                      Missed
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="label"
                      v-if="
                        !dialogicFeedbackItem.data[n - 1].attributes
                          .hide_assessment_label
                      "
                    >
                      {{ dialogic.attributes.assessment_label }}
                    </div>
                  </div>
                  <h4 v-if="dialogic.attributes.topic_label">
                    {{ dialogic.attributes.topic_label }}
                  </h4>
                  <p v-html="dialogic.attributes.debrief_received"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="item">
            <div
              class="item-image feedback-icon"
              v-if="
                dialogicFeedbackItem.assessment &&
                !dialogicFeedbackItem.hide_assessment_label
              "
            >
              <img
                v-if="
                  dialogicFeedbackItem.assessment.attributes.assessment_icon_url
                "
                :src="
                  dialogicFeedbackItem.assessment.attributes.assessment_icon_url
                "
                :alt="dialogicFeedbackItem.assessment.attributes.assessment_label"
              />
            </div>
            <div class="item-content" v-if="dialogicFeedbackItem.assessment">
              <div class="label" v-if="!dialogicFeedbackItem.hide_assessment_label">
                <h4>
                  {{
                    dialogicFeedbackItem.assessment.attributes.assessment_label
                      ? dialogicFeedbackItem.assessment.attributes.assessment_label
                      : "Missed"
                  }}
                </h4>
              </div>
              <p
                v-html="
                  dialogicFeedbackItem.assessment.attributes.feedback
                    ? dialogicFeedbackItem.assessment.attributes.feedback
                    : ''
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div
        v-if="
          dialogicFeedbackItem.data &&
          dialogicFeedbackItem.data.attributes &&
          dialogicFeedbackItem.data.attributes.dialogic_debrief_evaluation
        "
      >
        <div
          v-for="(dialogicList, key) in dialogicFeedbackItem.data.attributes
            .dialogic_debrief_evaluation"
          :key="dialogicList.id"
          class="dialogic-final-feedback-item"
        >
          <div
            v-for="dialogic in dialogicFeedbackItem.data.attributes
              .dialogic_debrief_evaluation[key]"
            :key="dialogic.id"
          >
            <p class="feedback-item-title">
              {{ dialogic.attributes.question_concept }}
            </p>
            <div class="item">
              <div
                class="feedback-icon item-image"
                v-if="
                  dialogic.attributes.key_topic_missed &&
                  !dialogicFeedbackItem.data.attributes.hide_assessment_label
                "
              ></div>
              <div
                class="feedback-icon item-image"
                v-else-if="
                  !dialogicFeedbackItem.data.attributes.hide_assessment_label
                "
              >
                <img
                  v-if="dialogic.attributes.assessment_icon_url"
                  :src="dialogic.attributes.assessment_icon_url"
                  :alt="dialogic.attributes.assessment_label"
                />
              </div>
              <div class="item-content">
                <div v-if="dialogic.attributes.key_topic_missed">
                  <div
                    class="label"
                    v-if="
                      !dialogicFeedbackItem.data.attributes.hide_assessment_label
                    "
                  >
                    Missed
                  </div>
                </div>
                <div v-else>
                  <div
                    class="label"
                    v-if="
                      !dialogicFeedbackItem.data.attributes.hide_assessment_label
                    "
                  >
                    {{ dialogic.attributes.assessment_label }}
                  </div>
                </div>
                <h4 v-if="dialogic.attributes.topic_label">
                  {{ dialogic.attributes.topic_label }}
                </h4>
                <p v-html="dialogic.attributes.debrief_received"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="item">
          <div
            class="item-image feedback-icon"
            v-if="
              dialogicFeedbackItem.assessment &&
              !dialogicFeedbackItem.hide_assessment_label
            "
          >
            <img
              v-if="dialogicFeedbackItem.assessment.attributes.assessment_icon_url"
              :src="dialogicFeedbackItem.assessment.attributes.assessment_icon_url"
              :alt="dialogicFeedbackItem.assessment.attributes.assessment_label"
            />
          </div>
          <div class="item-content" v-if="dialogicFeedbackItem.assessment">
            <div class="label" v-if="!dialogicFeedbackItem.hide_assessment_label">
              <h4>
                {{
                  dialogicFeedbackItem.assessment.attributes.assessment_label
                    ? dialogicFeedbackItem.assessment.attributes.assessment_label
                    : "Missed"
                }}
              </h4>
            </div>
            <p
              v-html="
                dialogicFeedbackItem.assessment.attributes.feedback
                  ? dialogicFeedbackItem.assessment.attributes.feedback
                  : ''
              "
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialogicFinalFeedback",
  props: {
    dialogicFeedbackItem: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 100%;
  text-align: right;
}
h1 {
  font-family: "muliblack";
  font-size: 40px;
  line-height: 100%;
  margin: 30px 0;
}
h2 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin: 50px 0 10px;
}
h3 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin-bottom: 1.4em;
}
h4 {
  font-family: "muliregular";
  font-size: 16px;
  margin: 0 0 5px;
}
h5 {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
.row {
  padding: 40px 0;
  border-bottom: #eaeaea 1px solid;
}
.row:last-child {
  border-bottom: 0;
}
.dialogic-final-feedback-item {
  margin-bottom: 3em;
  display: block;
  float: left;
  width: 100%;

  .item {
    margin: 10px 0 0;
  }
  .feedback-item-title {
    font-size: 16px;
    font-family: "muliextrabold";
    margin: 14px 0 0;
    color: #000000;
  }
}
.item {
  margin: 20px 0 0;
  float: left;
  width: 100%;
}
.item-image {
  float: left;
  width: 78px;
  height: 78px;
  margin: 0 25px 0 0;
}
.item-image img {
  max-width: 100%;
}
.item-content {
  float: left;
  margin: 3px 0 0;
}
.label {
  font-weight: bold;
  font-family: "muliblack";
  font-size: 13px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  color: black;
}
.grading {
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.grading-p {
  font-size: 32px;
  margin-bottom: 16px;
}
.grade-score-head {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.grade-score-head h3 {
  float: left;
}
.grade-score-head p {
  float: right;
  font-family: "mulibold";
}
.item-score {
  background-color: #3dbc9e;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 24px;
}
.item-score {
  h3 {
    font-family: "mulisemibold";
    font-size: 20px;
    line-height: 100%;
    color: #fff;
    margin: 12px 0 0;
  }
}
.grade-score {
  font-family: "mulibold" !important;
  float: right;
  margin-top: 5px;
}
.item-score-head {
  font-family: "mulibold" !important;
  margin-bottom: 5px;
}
.item-label {
  color: #3dbc9e;
  font-size: 14px;
  margin: 0;
}
.container {
  width: 100%;
  margin-left: 0;
}
.final-feedback-title {
  font-size: 35px;
}

.details-hide {
  display: none;
}
.active-details {
  display: block;
}

.attempts-tabs {
  display: flex;
  padding-left: 0;
  .attempt-tab {
    border: 1px solid #ccc;
    padding: 1em;
    display: inline-flex;
    margin-right: 1em;
    cursor: pointer;
  }
  .active-reattempt-tab {
    background: #3dbc9e;
    color: white;
  }
}

.coding-concept {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
</style>