var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-title"},[_c('div',{staticClass:"final-feedback-title"},[_c('h1',[_vm._v("Final Feedback")]),_c('h1',[_vm._v(_vm._s(_vm.headerInfo.name))])]),_c('div',{staticClass:"grading"},[(!this.disableReportBtn)?_c('div',[(
          (_vm.enableGrading && _vm.overallGradeDisplayType === 'percentage') ||
          (_vm.enableGrading && _vm.overallGradeDisplayType === 'both')
        )?_c('h1',[_vm._v(" "+_vm._s(_vm.overallGradePercentage)+"% ")]):_vm._e(),(
          (_vm.enableGrading && _vm.overallGradeDisplayType === 'labels') ||
          (_vm.enableGrading && _vm.overallGradeDisplayType === 'both')
        )?_c('p',{staticClass:"grading-p"},[_vm._v(" "+_vm._s(_vm.overallGradeLabel)+" ")]):_vm._e()]):_vm._e(),_c('button',{staticClass:"btn btn-ametros download-report",attrs:{"disabled":this.disableReportBtn},on:{"click":function($event){return _vm.downloadFinalEvaluationPDF(_vm.pdfURL)}}},[_vm._v(" "+_vm._s(_vm.updatedReport ? "Download Updated Report" : "Download Report")+" ")]),(this.disableReportBtn)?_c('p',{staticClass:"grading-progress"},[_vm._v(" Grading in progress. Report will be available for download when grading is complete. ")]):_vm._e(),(!this.disableReportBtn)?_c('button',{staticClass:"btn regen-report",on:{"click":function($event){$event.preventDefault();return _vm.regenerateReport(_vm.pdfURL)}}},[_vm._v(" Regenerate Report ")]):_vm._e(),_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.headerInfo.final_debrief_overview)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }