<template>
  <div v-if="codingFeedbackItem.card_type === 'coding'">
    <h3>{{ codingFeedbackItem.name }}</h3>
    <div
      v-if="
        codingFeedbackItem.data &&
        codingFeedbackItem.data.attributes &&
        codingFeedbackItem.data.attributes.concept_evaluations
      "
    >
      <div
        v-for="codingConcept in codingFeedbackItem.data.attributes
          .concept_evaluations"
        :key="codingConcept.id"
      >
        <p class="coding-concept">
          {{ codingConcept.attributes.concept_name }}
        </p>
        <div class="item mb-3">
          <div class="item-image feedback-icon">
            <img
              v-if="codingConcept.attributes.assessment_icon_url"
              :src="codingConcept.attributes.assessment_icon_url"
              :alt="codingConcept.attributes.assessment_label"
            />
          </div>
          <div class="item-content">
            <div class="label">
              <h4>
                {{ codingConcept.attributes.assessment_label }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="item">
        <div
          class="item-image"
          v-if="
            codingFeedbackItem.assessment &&
            !codingFeedbackItem.hide_assessment_label
          "
        >
          <img
            v-if="codingFeedbackItem.assessment.attributes.assessment_icon_url"
            :src="codingFeedbackItem.assessment.attributes.assessment_icon_url"
            :alt="codingFeedbackItem.assessment.attributes.assessment_label"
          />
        </div>
        <div class="item-content" v-if="codingFeedbackItem.assessment">
          <div class="label" v-if="!codingFeedbackItem.hide_assessment_label">
            {{
              codingFeedbackItem.assessment.attributes.assessment_label
                ? codingFeedbackItem.assessment.attributes.assessment_label
                : "Missed"
            }}
          </div>
          <p
            v-html="
              codingFeedbackItem.assessment.attributes.feedback
                ? codingFeedbackItem.assessment.attributes.feedback
                : ''
            "
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "codingFinalFeedback",
  props: {
    codingFeedbackItem: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 100%;
  text-align: right;
}
h1 {
  font-family: "muliblack";
  font-size: 40px;
  line-height: 100%;
  margin: 30px 0;
}
h2 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin: 50px 0 10px;
}
h3 {
  font-family: "muliextrabold";
  font-size: 20px;
  margin-bottom: 1.4em;
}
h4 {
  font-family: "muliregular";
  font-size: 16px;
  margin: 0 0 5px;
}
h5 {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
.row {
  padding: 40px 0;
  border-bottom: #eaeaea 1px solid;
}
.row:last-child {
  border-bottom: 0;
}
.dialogic-final-feedback-item {
  margin-bottom: 3em;
  display: block;
  float: left;
  width: 100%;

  .item {
    margin: 10px 0 0;
  }
  .feedback-item-title {
    font-size: 16px;
    font-family: "muliextrabold";
    margin: 14px 0 0;
    color: #000000;
  }
}
.item {
  margin: 20px 0 0;
  float: left;
  width: 100%;
}
.item-image {
  float: left;
  width: 78px;
  height: 78px;
  margin: 0 25px 0 0;
}
.item-image img {
  max-width: 100%;
}
.item-content {
  float: left;
  margin: 3px 0 0;
}
.label {
  font-weight: bold;
  font-family: "muliblack";
  font-size: 13px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  color: black;
}
.grading {
  width: 30%;
  text-align: right;
  justify-content: flex-end;
}
.grading-p {
  font-size: 32px;
  margin-bottom: 16px;
}
.grade-score-head {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.grade-score-head h3 {
  float: left;
}
.grade-score-head p {
  float: right;
  font-family: "mulibold";
}
.item-score {
  background-color: #3dbc9e;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
  padding-top: 24px;
}
.item-score {
  h3 {
    font-family: "mulisemibold";
    font-size: 20px;
    line-height: 100%;
    color: #fff;
    margin: 12px 0 0;
  }
}
.grade-score {
  font-family: "mulibold" !important;
  float: right;
  margin-top: 5px;
}
.item-score-head {
  font-family: "mulibold" !important;
  margin-bottom: 5px;
}
.item-label {
  color: #3dbc9e;
  font-size: 14px;
  margin: 0;
}
.container {
  width: 100%;
  margin-left: 0;
}
.final-feedback-title {
  font-size: 35px;
}

.details-hide {
  display: none;
}
.active-details {
  display: block;
}

.attempts-tabs {
  display: flex;
  padding-left: 0;
  .attempt-tab {
    border: 1px solid #ccc;
    padding: 1em;
    display: inline-flex;
    margin-right: 1em;
    cursor: pointer;
  }
  .active-reattempt-tab {
    background: #3dbc9e;
    color: white;
  }
}

.coding-concept {
  font-size: 16px;
  font-family: "muliextrabold";
  margin: 14px 0 0;
}
</style>