<template>
  <div id="contact" class="contact-container">
    <sidebar></sidebar>
    <main class="contact-form">
      <h1>Support</h1>
      <p class="mb-3">Please note that our support hours are from Monday to Friday 8 am - 6 pm EST and Sundays from 8 am - 2 pm EST.</p>
      <ValidationObserver ref="form">
        <form>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="name"
                    disabled
                    class="form-control"
                    v-model="input.name"
                    placeholder="Enter Name"
                    aria-label="Enter Name"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <ValidationProvider
                  name="Email"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="email"
                    disabled
                    class="form-control"
                    v-model="input.email"
                    placeholder="Enter email"
                    aria-label="Enter email"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="form-group">
            <ValidationProvider
              name="Module Name"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                class="form-control"
                type="text"
                tabindex="0"
                placeholder="Message subject"
                aria-label="Message subject"
                v-model="input.supportSubject"
                :class="[isActive && errors[0] ? 'invalid' : '']"
              />
              <span
                :class="[isActive ? 'invalid' : '']"
                :style="[isActive ? { display: 'block' } : { display: 'none' }]"
                v-if="errors[0]"
                >{{ errors[0].replace("The ", "") }}</span
              >
            </ValidationProvider>
          </div>
          <div class="form-group textarea-group">
            <ValidationProvider
              name="Message"
              rules="required"
              v-slot="{ errors }"
            >
              <vue-editor
                name="description"
                tabindex="0"
                placeholder="Write Your Message"
                :editorToolbar="simpleToolbar"
                v-model="input.description"
              ></vue-editor>
              <span
                :class="[isActive ? 'invalid' : '']"
                :style="[isActive ? { display: 'block' } : { display: 'none' }]"
                v-if="errors[0]"
                >{{ errors[0].replace("The ", "") }}</span
              >
            </ValidationProvider>
          </div>
          <div class="submit-button">
            <div class="text-right">
              <button
                type="button"
                tabindex="0"
                class="btn btn-ametros"
                @click="contactUs()"
                @keyup.enter="contactUs()"
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import sidebar from "@/components/sidebar.vue";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";

export default {
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
  name: "ContactUs",
  components: {
    sidebar,
    VueEditor,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  data() {
    return {
      input: {
        name: "",
        email: "",
        supportSubject: "",
        description: "",
      },
      title: "Request Support",
      url: process.env.VUE_APP_URL,
      isActive: "",
      showError: false,
      fullTool: [],
      simpleToolbar: [
        []
      ],
    };
  },
  created() {
    if (this.userDetails) {
      this.input.email = this.userDetails.attributes.email;
      if (this.userDetails.attributes.last_name != null) {
        this.input.name = `${this.userDetails.attributes.first_name} ${this.userDetails.attributes.last_name}`;
      } else {
        this.input.name = `${this.userDetails.attributes.first_name}`;
      }
    }
  },
  methods: {
    contactUs() {
      this.$refs.form.validate().then((success) => {
        this.isActive = true;
        if (success) {
          const contact_data = {
            module_name: this.input.supportSubject,
            message: this.input.description,
            user_type: "learner",
          };
          api
            .postContact(contact_data)
            .then((res) => {
              if (res && res.data) {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.contactSuccess
                );
                setTimeout(() => {
                  this.$router.replace({ name: "dashboard" });
                }, 2000);
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                this.showError = true;
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#logo-box {
  width: 500px;
  display: flex;
}
#user-choice {
  display: block;
  width: 500px;
  margin-bottom: 1em;
  margin-top: 2em;

  .account-choice {
    display: block;
    width: 80%;
    margin: 0 auto;
    span {
      margin-bottom: 2em;
    }
  }
}

.credit-card-header {
  margin-bottom: 1em;
  margin-top: 1em;
}

.credit-card-form {
  margin-top: 2em;
}

.payment-problem {
  padding: 1em;
  background-color: rgb(244, 245, 208);
}

.contact-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  .contact-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .contact-form {
    border: 0;
    background-color: $white;
    border-radius: 4px;
    padding: 138px 50px 40px 50px;
    max-width: 650px;
    @include media-breakpoint(md) {
      background-color: $white;
      padding: 138px 50px 40px 50px;
    }
    @include media-breakpoint(sm) {
      width: 100%;
      padding: 110px 20px 90px 20px;
    }
    form {
      width: 100%;
    }
  }
  .form-group {
    input {
      height: 60px;
      border: 0;
      border-radius: 2px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-black;
    font-size: 39px;
    line-height: 1.36;
    margin: 0 0 30px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .textarea-group {
    margin-bottom: 40px;
  }
  .submit-button {
    display: flex;
    justify-content: flex-end;
    .text-right {
      .btn {
        @include font-bold;
        height: 50px;
        font-size: 14px;
        line-height: normal;
        padding: 10px;
        min-width: 130px;
      }
      .outline-primary {
        color: #848484;
      }
    }
  }
  .modal {
    max-width: 497px;
    margin: 0 auto;
    max-height: 750px;
    overflow-y: auto;
    padding: 40px;
    @include media-breakpoint(sm) {
      padding: 10px;
    }
    .modal-header {
      @include font-bold;
      line-header: 0.95;
      font-size: 21px;
      margin-bottom: 24px;
      text-align: center;
      h5 {
        @include font-black;
        font-size: 18px;
        line-height: 1.4;
      }
    }
    .modal-footer {
      padding: 20px 0 6px 10px;
      border: 0;
      padding: 0;
      .data-delete-dropdown {
        margin: 0;
        .data-popper-footer {
          display: flex;
          align-items: center;
          .btn {
            @include font-bold;
            font-size: 14px;
            height: 45px;
            padding: 10px 8px;
          }
          .default {
            min-width: 80px;
            margin-right: 20px;
            border: 0;
            color: #848484;
          }
          .primary {
            min-width: 100px;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
  .modal-content {
    max-width: 290px;
    padding: 30px;
  }
}
</style>
