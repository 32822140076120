<template>
  <modal
    name="pdfModal"
    role="dialog"
    aria-label="pdfModal"
    class="pdfmodalvm"
    height="auto"
    :width="1000"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <FocusLoop :is-visible="isOpen">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-header modal-print-header mb-4">
            <div class="modal-print-row">
              <div class="modal-print-nav">
                <div class="data-results mr-2">
                  Page {{ page }} of {{ numPages }}
                </div>
                <div aria-label="PDF page navigation">
                  <ul class="pagination pdf-pagination">
                    <li class="page-item" v-if="page !== 1">
                      <a class="page-link" aria-label="Previous" @click="page--">
                        &lt;
                      </a>
                    </li>

                    <li class="page-item" v-if="page < numPages">
                      <a class="page-link" aria-label="Next" @click="page++">
                        &gt;
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="modal-print-right">
                <button
                  class="btn-focus loader"
                  @click="rotate += 90"
                  aria-label="Rotate clockwise"
                >
                  &#x27F3;
                  <span class="visually-hidden">Rotate clockwise</span>
                </button>
                <button
                  class="btn-focus rotate"
                  @click="rotate -= 90"
                  aria-label="Rotate counter-clockwise"
                >
                  Rotate
                  <span class="visually-hidden">Rotate counter-clockwise</span>
                </button>
                <button
                  class="btn btn-ametros"
                  @click.prevent="downloadItem(urlLink)"
                  aria-label="Download"
                >
                  Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="close"
              @click="hide('pdfModal')"
              aria-label="Close Modal"
            >
              <em class="icon-close"></em>
            </button>
          </div>
          <div style="width: 100%; align-items: center">
            <div
              v-if="loadedRatio > 0 && loadedRatio < 1"
              style="background-color: green; color: white; text-align: center"
              :style="{ width: loadedRatio * 100 + '%' }"
            >
              {{ Math.floor(loadedRatio * 100) }}%
            </div>
            <pdf
              ref="pdf"
              :page="page"
              :source="src"
              @rendered="handleDocumentRender"
            ></pdf>
          </div>
        </div>
      </div>
    </FocusLoop>
  </modal>
</template>
<script>
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Axios from "axios";
export default {
  name: "PdfViewer",
  components: {
    pdf,
  },
  data() {
    return {
      src: {},
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      urlLink: "",
      label: "",
      isOpen: false
    };
  },
  methods: {
    error: function (err) {
      console.log(err);
    },
    beforeOpen(event) {
      this.urlLink = event.params.pdfUrl;
      this.label = event.params.label;
      this.src = this.urlLink;
      this.isOpen = true;
      // this.src.promise.then((pdf) => {
      //   this.page = 1;
      //   this.numPages = pdf.numPages;
      // });
    },
    hide(modal) {
      this.$modal.hide("pdfModal");
      this.$parent.hide(modal);
    },
    downloadItem() {
      Axios.get(this.urlLink, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = this.label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    handleDocumentRender() {
      this.numPages = this.$refs.pdfRef.pageCount
    },
  },
};
</script>
<style scoped lang="scss">
  .pdf-pagination {
    padding-left: 1em;
  }
</style>
