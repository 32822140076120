<template>
  <div>
    <div class="learn-steps">
      <h1 class="learn-title">{{ card_data.name }}</h1>
      <div v-if="sticky_note.length">
        <div v-for="(notes, index) in sticky_note" :key="index">
          <div class="learn-notes my-3" v-if="notes && notes.length">
            <p class="note-title">
              <img
                src="../assets/images/icon-yellownote.svg"
                alt=""
              />Note
            </p>
            <p v-html="notes">{{ notes }}</p>
          </div>
        </div>
      </div>
      <p
        style="display: none"
        v-if="card_data.description"
        class="learn-info"
        v-html="card_data.description"
      ></p>
      <div class="learn-object learn-chat">
        <div class="learn-object-head">
          <div class="learn-object-col">
            <div class="learn-object-user">
              <div class="learn-object-user" v-if="chat_character">
                <img
                  :src="chat_character.attributes.char_photo_url.thumbnail"
                  v-if="chat_character.attributes.char_photo_url.thumbnail"
                  alt=""
                />
              </div>
            </div>
            <div class="learn-object-titles" v-if="chat_character">
              <h2>{{ chat_character.attributes.char_full_name }}</h2>
              <p>
                {{ chat_character.attributes.world_role }}({{
                  chat_character.attributes.org_name
                }})
              </p>
            </div>
          </div>
        </div>
        <div class="learn-reply-head" if="userData && userData.created_at">
          <span class="learn-reply-date">{{ userData.created_at }}</span>
        </div>
        <VuePerfectScrollbar
          class="chat-scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHandle"
        >
          <div class="learn-chat-container" v-if="chatMess.length > 0">
            <!-- learn chat me  -->
            <div
              v-for="msg in chatMess"
              :key="msg.id"
              :class="
                !msg.attributes.assistant_response
                  ? 'learn-chat-me'
                  : 'learn-chat-user'
              "
            >
              <div class="learn-chat-box">
                <div
                  class="learn-chat-image"
                  v-if="msg.attributes.assistant_response"
                >
                  <img
                    :src="chat_character.attributes.char_photo_url.thumbnail"
                    v-if="chat_character.attributes.char_photo_url.thumbnail" alt=""
                  />
                </div>
              </div>
              <div class="learn-object-titles" v-if="chat_character">
                <h2>{{chat_character.attributes.char_full_name}}</h2>
                <p>{{chat_character.attributes.world_role}}({{chat_character.attributes.org_name}})</p>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
          <div class="learn-reply-head" if="userData && userData.created_at">
            <span class="learn-reply-date">{{userData.created_at}}</span>
          </div>
          <VuePerfectScrollbar
            class="chat-scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHandle"
          >
            <div class="learn-chat-container" v-if="chatMess.length > 0">
              <!-- learn chat me  -->
              <div v-for="msg in chatMess" :key="msg.id"
                :class="!msg.attributes.assistant_response ? 'learn-chat-me' : 'learn-chat-user'">
                <div class="learn-chat-box">
                  <div class="learn-chat-image" v-if="msg.attributes.assistant_response">
                     <img
                        :src="chat_character.attributes.char_photo_url.thumbnail"
                        v-if="chat_character && chat_character.attributes.char_photo_url
                            && chat_character.attributes.char_photo_url.thumbnail"
                        alt=""
                     />
                  </div>
                  <div
                    class="learn-chat-msg"
                  >{{msg.attributes.message}}</div>
                  <div class="learn-chat-image" v-if="!msg.attributes.assistant_response">
                      <img
                        :src="userDetail.attributes.char_photo_url.thumbnail"
                        v-if="userDetail.attributes.char_photo_url && userDetail.attributes.char_photo_url.thumbnail"
                        alt=""
                      />
                  </div>
                </div>
              </div>
              <div class="learn-chat-time">{{ msg.attributes.time_ago }}</div>
            </div>
        </VuePerfectScrollbar>
        <div class="learn-chat-footer" v-if="!active_data.attributes.complete">
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder="Type your Message here"
              aria-label="Type your message here"
              :readonly="isChatEnd"
              v-on:keyup.enter="sendChatMessages"
              v-model="msg"
              @input="Message()"
            ></textarea>
          </div>
          <div class="form-chat-btn">
            <button
              class="btn btn-ametros ml-auto"
              :disabled="disableNextBtn"
              @click="sendChatMessages()"
              aria-label="Send"
            >
              Send
            </button>
          </div>
        </div>
        <div class="text-center mt-4" v-if="!isChatEnd && chatMess.length > 0">
          <button
            class="danger-text-btn"
            @click="alertShow"
            aria-label="End chat"
          >
            End this Chat
          </button>
        </div>
      </div>
      <div class="learn-btn" v-if="chatMess.length > 0">
        <button
          v-if="!isChatEnd"
          class="btn secondary"
          @click="alertShow"
          aria-label="See Feedback"
        >
          {{ "See Feedback" }}
          <em class="icon-arrow-right"></em>
        </button>
        <button
          class="btn secondary"
          v-else
          @click="next('debrif')"
          aria-label="See Feedback"
        >
          {{ "See Feedback" }}
          <em class="icon-arrow-right"></em>
        </button>
      </div>
      <div class="learn-btn" v-else>
        <button class="btn secondary" aria-label="See Feedback">
          {{ "See Feedback" }}
          <em class="icon-arrow-right"></em>
        </button>
      </div>
    </div>
    <modal
      class="modal-delete"
      name="alertShow"
      height="auto"
      :width="380"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-delete-content">
          <div class="modal-body modal-delete-body">
            Are you sure you want to end your chat and process to next?
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              @click="$modal.hide('alertShow')"
              class="btn medium default"
              aria-label="Cancel"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="
                $modal.hide('alertShow');
                next('evaluateChat');
              "
              class="btn medium primary"
              aria-label="OK"
            >
              OK
            </button>
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="feedbackTask"
      :adaptive="true"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-feedback-content modal-score-content">
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('feedbackTask')"
            aria-label="Close Modal"
          >
            <em class="icon-cross"></em>
          </button>
          <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
            <div class="modal-body">
              <div class="modal-title modal-flex-title">
                <h1>Feedback</h1>
              </div>
              <div
                class="modal-score-strip"
                v-if="overall_assmnt_item && overall_assmnt_item.display_feedback"
              >
                <span class="modal-score-icon">
                  <img
                    :src="url + overall_assmnt_item.assessment_icon_url"
                    :alt="overall_assmnt_item.assessment_label"
                  />
                  {{ overall_assmnt_item.assessment_label }}
                </span>
                <p v-html="overall_assmnt_item.feedback"></p>
              </div>
              <!-- feedback items start -->
              <div v-if="evaluateChatData && evaluateChatData.attributes">
                <div
                  class="feedback-items missed-items"
                  v-for="chatDebrief in evaluateChatData.attributes
                    .chat_debrief_evaluations"
                  :key="chatDebrief.id"
                >
                  <div class="feedback-icon"></div>
                  <div class="feedback-content">
                    <div class="feedback-head">
                      {{
                        chatDebrief.attributes.chat_skill_assmnt_missed
                          ? "MISSED"
                          : chatDebrief.attributes.assessment_label
                      }}
                    </div>
                    <h2>
                      {{ chatDebrief.attributes.chat_skill_name }}
                    </h2>
                    <p>{{ chatDebrief.attributes.debrief_received }}</p>
                    <div v-if="evaluateChatData.attributes.suggested_contents">
                      <div
                        class="col-12"
                        v-for="item in evaluateChatData.attributes
                          .suggested_contents"
                        :key="item.id"
                      >
                        <!-- suggestion container slide  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'slide_learn_obj'
                          "
                        >
                          <h5>Suggested Content</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-slide">
                              <button
                                @click="
                                  $modal.show('adaptiveChatModal', {
                                    data: item.attributes.content_details
                                      .attributes.slider_images,
                                    type: 'slide',
                                  })
                                "
                                aria-label="Show suggestion"
                              >
                                <img
                                  src="item.attributes.content_details.attributes.slider_images[0].attributes.resource_url.thumbnail"
                                  alt=""
                                />
                                <span class="visually-hidden">Show suggestion</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'text_learn_obj'
                          "
                        >
                          <h5>Suggested Content</h5>
                          <div class="suggestion-row">
                            <div
                              class="suggestion-slide"
                              v-html="
                                item.attributes.content_details.attributes.title
                              "
                            ></div>
                          </div>
                        </div>
                        <!-- suggestion container pdf  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'file_learn_obj'
                          "
                        >
                          <h5>Suggested Content(file)</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-pdf">
                              <button
                                @click="
                                  $modal.show('pdfModal', {
                                    pdfUrl:
                                      item.attributes.content_details.attributes
                                        .resource_url,
                                    label:
                                      item.attributes.content_details.attributes
                                        .resource_file_name,
                                  })
                                "
                                aria-label="Show PDF"
                              >
                                <span class="truncate">{{
                                  item.attributes.content_details.attributes
                                    .resource_file_name
                                }}</span>
                                <span class="visually-hidden">Show PDF</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- suggestion container video  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'video_learn_obj'
                          "
                        >
                          <h5>Suggested Content(video)</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-video">
                              <button
                                @click="
                                  $modal.show('adaptiveChatModal', {
                                    data: item.attributes.content_details
                                      .attributes.global_video.attributes
                                      .wistia_code,
                                    type: 'video',
                                  })
                                "
                                aria-label="Show Suggestion"
                              >
                                <img
                                  :src="
                                    item.attributes.content_details.attributes
                                      .global_video.attributes
                                      .wistia_thumbnail_url
                                  "
                                  alt=""
                                />
                                <span class="visually-hidden">Show Suggestion</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- feedback items end -->
              <lo-buttons
                v-if="resultStatus && i === questions.length - 1"
                :nextStatus="nextStatus"
                text="See Final Feedback"
                :nextMethod="next"
                :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
              ></lo-buttons>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
    <modal
      name="adaptiveChatModal"
      :width="815"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-gathering-content">
          <div class="modal-body">
              <div
                class="gather-content-item" v-if="type == 'video'"
              >
                <div class="wistia_responsive_wrapper">
                  <iframe
                    :src="preIframUrl+videoCode+postIframUrl"
                    title="Video.mp4"
                    class="wistia_embed"
                    name="wistia_embed"
                    allowtransparency="true"
                    scrolling="no"
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              </div>
            <div class="gather-content-item" v-if="type == 'slide'">
              <carousel
                :margin="0"
                :nav="true"
                :navText="[]"
                :autoplay="false"
                :autoHeight="false"
                :items="1"
                :dots="false"
                :loop="true"
              >
                <div v-for="img in sliderImages" :key="img.id">
                  <div class="introduction-video">
                    <img
                      v-if="img.attributes.resource_url.large_version"
                      :src="img.attributes.resource_url.large_version"
                      :alt="img.attributes.global_resource.attributes.tag_list"
                      class="intro-slide-video"
                    />
                    <div class="introduction-intro-top">
                      <div class="introduction-intro-title">
                        {{ img.attributes.caption }}
                      </div>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </FocusLoop>
    </modal>
  </div>
  <!-- step-10 end here -->
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "../services/api";
export default {
  name: "chat-component",
  components: {
    VuePerfectScrollbar,
  },
  props: ["active_data", "nextStatus"],
  data() {
    return {
      settings: {
        maxScrollbarLength: 30,
      },
      loId: "",
      card_type: "",
      card_data: {},
      url: utilFunctionService.getImageUrl(),
      character: {},
      evaluation_id: "",
      fullToolbar: [],
      disableNextBtn: true,
      msg: "",
      chatMess: [],
      userDetail: {},
      chat_character: {},
      mentor_character: {},
      evaluateChatData: {},
      overall_assmnt_item: {},
      userData: {},
      user_chat_id: "",
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      videoCode: "",
      type: "",
      sliderImages: [],
      isChatEnd: false,
      sticky_note: [],
      learning_id: "",
      isOpen: false
    };
  },
  async created() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
    this.character = this.card_data.card_detail.attributes.character;
    this.loId = this.active_data.attributes.learning_object.id;
    this.evaluation_id = this.active_data.attributes.evaluation_id;
    this.userData = this.active_data.attributes.user_chat_data
      ? this.active_data.attributes.user_chat_data
      : {};
    if (this.userData && this.userData.id) {
      this.user_chat_id = this.userData.id ? this.userData.id : "";
    }
    if (this.active_data.attributes.complete && this.userData.complete) {
      this.isChatEnd = true;
      this.disableNextBtn = true;
    }
    this.userDetail = this.active_data.attributes.user_section.attributes.user;
    this.chat_character =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.chat_character[0];
    this.mentor_character =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.mentor_character[0];
    let attr = this.active_data.attributes;
    let loSec = attr.user_section.attributes.learning_object_sections;
    this.sticky_note = attr.sticky_note ? [attr.sticky_note] : [];
    this.learning_id = attr.learning_object_id;
    for (let i = 0; i < loSec.length; i++) {
      if (loSec[i].attributes.learning_object_id === this.learning_id) {
        this.sticky_note.push(loSec[i].attributes.sticky_note);
      }
    }
    if (!this.user_chat_id) {
      const _fb = new FormData();
      _fb.append("user_chat[user_learn_obj_id]", this.active_data.id);
      api
        .createUserChats(_fb)
        .then((res) => {
          this.user_chat_id = res.data.id;
          this.getUserChatMessages();
        })
        .catch(() => {});
    }
    if (this.user_chat_id) {
      this.getUserChatMessages();
    }
  },
  methods: {
    beforeOpen(event) {
      this.type = event.params.type;
      this.isOpen = true;
      if (event.params.type === "video") {
        this.videoCode = event.params.data;
      } else {
        this.sliderImages = event.params.data;
      }
    },
    alertShow() {
      this.$modal.show("alertShow");
    },
    getUserChatMessages() {
      api.getUserChatMessages(this.user_chat_id).then((res) => {
        this.chatMess = [];
        this.chatMess = res.data.data;
      });
    },
    next(type) {
      if (type === "evaluateChat") {
        this.isChatEnd = true;
        this.ChatEvaluations();
        this.next("debrif");
      } else {
        if (type === "debrif") {
          this.ChatEvaluations();
          this.$modal.show("feedbackTask");
        } else {
          this.$modal.hide("feedbackTask");
          this.$parent.next(type);
        }
      }
    },
    getEvaluateChat(id) {
      api.getEvaluateChat(id).then((res) => {
        if (res) {
          this.evaluateChatData = res.data.data;
        }
      });
    },
    createChatEvaluations() {
      api.evaluateChat(this.user_chat_id).then(() => {
        api.getEvaluateChat(this.user_chat_id).then((res) => {
          this.evaluateChatData = res.data.data;
        });
      });
    },
    ChatEvaluations() {
      api.getEvaluateChat(this.user_chat_id).then((res) => {
        let complete =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.complete
            ? res.data.data.attributes.complete
            : false;
        if (complete) {
          this.evaluateChatData = res.data.data;
        } else this.createChatEvaluations();
      });
    },
    scrollHandle(evt) {
      console.log(evt);
    },
    Message() {
      if (this.msg.length === 0) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }
    },
    sendChatMessages() {
      const _fb = new FormData();
      _fb.append("user_chat_message[user_chat_id]", this.user_chat_id);
      _fb.append("user_chat_message[message]", this.msg);
      api
        .sendChatMessages(_fb)
        .then(() => {
          this.getUserChatMessages();
          this.msg = "";
        })
        .catch(() => {
          this.getUserChatMessages();
          this.msg = "";
        });
    },
  },
};
</script>
