<template>
  <div>
    <h1 class="learn-title">{{ card_data.name }}</h1>
    <p class="learn-info" v-html="card_data.description"></p>
    <div class="introduction">
      <div id="example-table"></div>
			<button @click="downloadCSV()">Download CSV</button>
      <lo-buttons
        :nextMethod="next"
        :nextStatus="nextStatus"
        text="See Final Feedback"
        :showCertificateOfCompletion="
          active_data.attributes.enable_certificate_of_completion
        "
      ></lo-buttons>
    </div>
  </div>
</template>
<script>
import loButtons from "../components/loButtons.vue";
import { TabulatorFull as Tabulator } from "tabulator-tables";
export default {
  name: "csv-component",
  components: {
    loButtons,
  },
  props: ["active_data", "nextStatus"],
  data() {
    return {
      card_data: {},
      tabulator: null,
      tableData: [],
			csv_url: "",
			csv_file_name: "",
    };
  },
  mounted() {
    this.card_data = this.active_data.attributes.learning_object.attributes;
		this.csv_url = this.card_data.card_detail.attributes.resource_url;
		this.csv_file_name = this.card_data.card_detail.attributes.resource_name;
    let self = this;
    let csvData = new Promise((resolve) => {
      resolve(this.convertCSVToJson(this.csv_url));
    });
    csvData.then((data) => {
      self.tabulator = new Tabulator("#example-table", {
        height: 205, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
        data: data,
        layout: "fitDataFill", //fit columns to width of table (optional)
        autoColumns: true,
      });
    });
  },
  methods: {
    next() {
      this.$parent.next();
    },
    async convertCSVToJson(url) {
      const res = await fetch(url);
      if (res.ok) {
        const data = await res.text();
        var lines = data.split("\n");
        var result = [];
        var headers = lines[0].split(",");
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(",");
          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
          }
          result.push(obj);
        }
        return result;
      }
    },
		downloadCSV() {
			const link = document.createElement("a");
			link.href = this.csv_url;
			link.download = this.csv_file_name;
			link.click();
		},
  },
};
</script>
