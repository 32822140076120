var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{staticClass:"modal-fullScreen",attrs:{"name":"emailNoPlotPointFeedback","aria-label":"emailNoPlotPointFeedback","role":"dialog","width":"100%","height":"auto","scrollable":true},on:{"before-open":_vm.beforeOpen}},[_c('FocusLoop',{attrs:{"is-visible":_vm.isOpen}},[(_vm.emailFeedbackResponse)?_c('div',{staticClass:"modal-content modal-feedback-content modal-score-content"},[_c('button',{staticClass:"modal-close",attrs:{"type":"button","aria-label":"Close Modal"},on:{"click":function($event){return _vm.$modal.hide('emailNoPlotPointFeedback')}}},[_c('em',{staticClass:"icon-cross"})]),_c('VuePerfectScrollbar',{staticClass:"modal-slide-content",attrs:{"settings":_vm.settings}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"d-flex flex-wrap w-100 justify-content-between"},[_c('h1',[_vm._v("Feedback")])]),(
                _vm.overallAssmntItem &&
                _vm.overallAssmntItem.display_feedback &&
                _vm.emailFeedbackResponse.attributes.display_overall_assessment
              )?_c('div',{staticClass:"modal-score-strip mb-4"},[(!_vm.hideAssessmentLabel)?_c('span',{staticClass:"modal-score-icon"},[(_vm.overallAssmntItem.assessment_icon_url)?_c('img',{attrs:{"src":_vm.overallAssmntItem.assessment_icon_url,"alt":_vm.overallAssmntItem.assessment_label}}):_vm._e(),_vm._v(" "+_vm._s(_vm.overallAssmntItem.assessment_label)+" ")]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.overallAssmntItem.feedback)}})]):_vm._e(),_vm._l((_vm.emailFeedbackResponse.attributes
                .learner_debrief_items),function(values){return _c('div',{key:values.id,staticClass:"feedback-items mastery"},[(!_vm.emailFeedbackResponse.attributes.hide_assessment_label)?_c('div',{staticClass:"feedback-icon"},[_c('img',{attrs:{"src":values.attributes.email_assessment_item.attributes
                      .assessment_icon_url,"alt":""}})]):_vm._e(),_c('div',{staticClass:"feedback-content"},[(!_vm.emailFeedbackResponse.attributes.hide_assessment_label)?_c('div',{staticClass:"feedback-head"},[_vm._v(" "+_vm._s(values.attributes.email_assessment_item.attributes .assessment_label)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v(" "+_vm._s(values.attributes.global_skill.name)+" ")]),_c('button',{staticClass:"feedback-info",on:{"click":function($event){return _vm.$modal.show('emailFeedbackInfo', {
                        skillName: values.attributes.global_skill.name,
                        infoModalValues: values
                      })}}},[_vm._v(" i "),_c('span',{staticClass:"visually-hidden"},[_vm._v("Show email feedback info modal")])])]),_c('div',{staticClass:"emailBody",domProps:{"innerHTML":_vm._s(values.attributes.debrief_content)}}),_vm._l((values.attributes.email_assessment_item
                    .attributes.adaptive_contents),function(item){return _c('div',{key:item.id,staticClass:"col-12 px-0"},[(
                      item.attributes.content_details.type == 'slide_learn_obj'
                    )?_c('div',{staticClass:"suggestion-container"},[_c('h3',[_vm._v("Suggested Content")]),_c('div',{staticClass:"suggestion-row"},[_c('div',{staticClass:"suggestion-slide"},[_c('a',{on:{"click":function($event){return _vm.$modal.show('suggestedContentModal', {
                              data: item.attributes.content_details.attributes
                                .slider_images,
                              type: 'slide',
                              title: item.attributes,
                            })}}},[_c('img',{attrs:{"src":item.attributes.content_details.attributes
                                .slider_images[0].attributes.resource_url
                                .thumbnail,"alt":""}})])])])]):_vm._e(),(
                      item.attributes.content_details.type == 'text_learn_obj'
                    )?_c('div',{staticClass:"suggestion-container"},[_c('h3',[_vm._v("Suggested Content")]),_c('div',{staticClass:"suggestion-row"},[_c('div',{staticClass:"suggestion-slide",domProps:{"innerHTML":_vm._s(
                          item.attributes.content_details.attributes.title
                        )}})])]):_vm._e(),(
                      item.attributes.content_details.type == 'file_learn_obj'
                    )?_c('div',{staticClass:"suggestion-container"},[_c('h3',[_vm._v("Suggested Content(file)")]),_c('div',{staticClass:"suggestion-row"},[_c('div',{staticClass:"suggestion-pdf"},[_c('a',{staticClass:"btn pdf-download",attrs:{"href":item.attributes.content_details.attributes
                              .resource_url},on:{"click":function($event){$event.preventDefault();return _vm.downloadPdf(
                              item.attributes.content_details.attributes
                                .resource_url,
                              'Suggested_Content'
                            )}}},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(item.attributes.content_details.attributes .resource_file_name))])])])])]):_vm._e(),(
                      item.attributes.content_details.type == 'video_learn_obj'
                    )?_c('div',{staticClass:"suggestion-container"},[_c('h3',[_vm._v("Suggested Content(video)")]),_c('div',{staticClass:"suggestion-row"},[_c('div',{staticClass:"suggestion-video"},[_c('a',{on:{"click":function($event){return _vm.$modal.show('suggestedContentModal', {
                              data: item.attributes.content_details.attributes
                                .global_video.attributes.wistia_code,
                              type: 'video',
                              title: item.attributes,
                            })}}},[_c('img',{attrs:{"src":item.attributes.content_details.attributes
                                .global_video.attributes.wistia_thumbnail_url,"alt":""}})])])])]):_vm._e()])})],2)])}),_c('div',{staticClass:"reattempts-wrapper"},[(!_vm.course_lo_reattempts_disabled && _vm.lo_reattempts_enabled && (_vm.lo_reattempts_max - _vm.lo_reattempt_number > 0) && _vm.user_section_data.complete_percentage < 100)?_c('div',{staticClass:"reattempts-info"},[_c('p',[_vm._v("Do you want to try this interaction again? You can reset this interaction "),_c('strong',[_vm._v(_vm._s(_vm.lo_reattempts_max - _vm.lo_reattempt_number))]),_vm._v(" more time"),(_vm.lo_reattempts_max - _vm.lo_reattempt_number > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(".")]),_c('p',[_vm._v("Click \"Try again\" now if you'd like to apply the feedback you received or see what happens when you submit a different message.")]),_c('div',{staticClass:"two-buttons"},[_c('button',{staticClass:"btn secondary",on:{"click":function($event){return _vm.retryInteraction()}}},[_vm._v("Try again")]),_c('button',{staticClass:"btn secondary",on:{"click":_vm.next}},[_vm._v(" "+_vm._s(!_vm.nextStatus ? "Next" : "Go To Dashboard")+" "),_c('em',{staticClass:"icon-arrow-right"})])])]):_vm._e()]),((!_vm.course_lo_reattempts_disabled && _vm.lo_reattempts_enabled && (_vm.lo_reattempts_max - _vm.lo_reattempt_number == 0)) || !_vm.lo_reattempts_enabled || _vm.user_section_data.complete_percentage >= 100)?_c('div',{staticClass:"modal-btn"},[_c('div',{staticClass:"learn-btn"},[_c('button',{staticClass:"btn secondary",on:{"click":_vm.next}},[_vm._v(" "+_vm._s(!_vm.nextStatus ? "Next" : "Go To Dashboard")+" "),_c('em',{staticClass:"icon-arrow-right"})])])]):_vm._e()],2)])],1):_vm._e()])],1),_c('email-iteration-info-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }