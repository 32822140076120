import { render, staticRenderFns } from "./forgotPassword.vue?vue&type=template&id=147dbda7&scoped=true&"
import script from "./forgotPassword.vue?vue&type=script&lang=js&"
export * from "./forgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./forgotPassword.vue?vue&type=style&index=0&id=147dbda7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147dbda7",
  null
  
)

export default component.exports